import React, { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/auth/GuestGuard';
import AuthGuard from './components/auth/AuthGuard';
import OrganizationTypeGuard from './components/auth/OrganizationTypeGuard';
const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);


//Why we are loading it lazy?
const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const Register = Loadable(lazy(() => import('./pages/auth/Register')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/auth/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/auth/PasswordReset')));
const EmailVerification = Loadable(lazy(() => import('./pages/auth/EmailVerification')));

const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));

//Listing
const ListingTable = Loadable(lazy(() => import('./pages/listing/ListingTable')));
const EditListing = Loadable(lazy(() => import('./pages/listing/EditListing')));

//Matches
const MatchTable = Loadable(lazy(() => import('./pages/match/MatchTable')));
const OneClickSubmit = Loadable(lazy(() => import('./pages/match/OneClickSubmit')));

//Chat
//const Chat = Loadable(lazy(() => import('./pages/chat/Chat')));

//Organizations
const EditOrganization = Loadable(lazy(() => import('./pages/organization/EditOrganization')));

//Questionnaire
const Questionnaire = Loadable(lazy(() => import('./pages/questionnaire/Questionnaire')));
const QuestionnaireTable = Loadable(lazy(() => import('./pages/questionnaire/QuestionnaireTable')));

//Admin & Manager
const UserTable = Loadable(lazy(() => import('./pages/admin/UserTable')));
const LenderTable = Loadable(lazy(() => import('./pages/admin/LenderTable')));
const SyndicationTable = Loadable(lazy(() => import('./pages/admin/SyndicationTable')));
const SyndicationDetails = Loadable(lazy(() => import('./pages/admin/SyndicationDetails')));
const DocumentTable = Loadable(lazy(() => import('./pages/admin/DocumentTable')));
const FAQTable = Loadable(lazy(() => import('./pages/admin/FAQTable')));
const EmailTemplateTable = Loadable(lazy(() => import('./pages/admin/EmailTemplateTable')));
const AdminCallbackTable = Loadable(lazy(() => import('./pages/admin/AdminCallbackTable')));
const Statistic = Loadable(lazy(() => import('./pages/admin/Statistic')));
const BecomeUser = Loadable(lazy(() => import('./pages/admin/BecomeUser')));


const SimpleTable = Loadable(lazy(() => import('./pages/table/SimpleTable')));
//const CustomTable = Loadable(lazy(() => import('./pages/custom-table/CustomTable')));
//const EditItem = Loadable(lazy(() => import('./pages/table/EditItem')));
//const ViewItem = Loadable(lazy(() => import('./pages/table/ViewItem')));


const NotFound = Loadable(lazy(() => import('./pages/errors/NotFound')));

const routes = [
    {
        path: '/',
        element: (
            <GuestGuard>
                <Login />
            </GuestGuard>
        )
    },
    {
        path: '/account',
        element: (
            <AuthGuard>
                <DashboardLayout >
                    <EditOrganization current={true} />
                </DashboardLayout>
            </AuthGuard>
        )
    },
    {
        path: '/account/:tab',
        element: (
            <AuthGuard>
                <DashboardLayout >
                    <EditOrganization current={true} />
                </DashboardLayout>
            </AuthGuard>
        )
    },
    {
        path: '/payoff/:code',
        element: (
            <Questionnaire />
        )
    },
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: 'login/token/:token',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register />
                    </GuestGuard>
                ),
            },
            {
                path: 'register/:invitationId',
                element: (
                    <Register />
                ),
            },
            {
                path: 'register/seller/:listingId',
                element: (
                    <Register />
                ),
            },
            {
                path: 'register/match/:code',
                element: (
                    <OneClickSubmit />
                ),
            },
            {
                path: 'register/mbi/:questionnaireId',
                element: (
                    <Register />
                ),
            },
            {
                path: 'password-recovery',
                element: (
                    <GuestGuard>
                        <PasswordRecovery />
                    </GuestGuard>
                ),
            },
            {
                path: 'password-recovery/:email',
                element: (
                    <GuestGuard>
                        <PasswordRecovery />
                    </GuestGuard>
                ),
            },
            {
                path: 'password-reset/:token/:username',
                element: (
                    <PasswordReset />
                ),
            },
            {
                path: 'email-verification/:token/:username',
                element: (
                    <EmailVerification />
                ),
            },
        ]
    }, {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Dashboard />
            },
            {
                path: 'listing',
                element: <OrganizationTypeGuard type={['SELLER', 'BROKER', 'BUYER', 'SYNPARTNER', 'MANAGER']} >
                    <ListingTable />
                </OrganizationTypeGuard>,
            },
            {
                path: 'listing/edit', //new
                element: <OrganizationTypeGuard type={['SELLER', 'BROKER', 'BUYER', 'SYNPARTNER', 'MANAGER']} >
                    <EditListing />
                </OrganizationTypeGuard>,
            },
            {
                path: 'listing/:objectId/edit/',
                element: <OrganizationTypeGuard type={['SELLER', 'BROKER', 'BUYER', 'SYNPARTNER', 'MANAGER']} >
                    <EditListing />
                </OrganizationTypeGuard>,
            },
            {
                path: 'match',
                element: <OrganizationTypeGuard type={['SELLER', 'BROKER', 'BUYER', 'MANAGER','SYNPARTNER']} >
                    <MatchTable />
                </OrganizationTypeGuard>,
            },
/*            {
                path: 'chat',
                element: <OrganizationTypeGuard type={['SELLER', 'BROKER', 'BUYER']} >
                    <Chat />
                </OrganizationTypeGuard>,
            }, 
            {
                path: 'chat/:selectedId',
                element: <OrganizationTypeGuard type={['SELLER', 'BROKER', 'BUYER']} >
                    <Chat />
                </OrganizationTypeGuard>,
            }, */
            {
                path: 'questionnaire',
                element: <OrganizationTypeGuard type={['LENDER', 'MANAGER']} >
                    <QuestionnaireTable />
                </OrganizationTypeGuard>,
            },
            {
                path: 'admin', //TODO add admin_guard
                children: [
                    {
                        path: 'user',
                        element: (
                            <OrganizationTypeGuard type="MANAGER" >
                                <UserTable />
                            </OrganizationTypeGuard>
                        ),
                    },
                    {
                        path: 'lender',
                        element: (
                            <OrganizationTypeGuard type="MANAGER" >
                                <LenderTable />
                            </OrganizationTypeGuard>

                        ),
                    },
                    {
                        path: 'organization/:objectId/edit/',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <EditOrganization />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'lender/:objectId/edit/',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <EditOrganization type={'Lenders'} />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'syndication',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <SyndicationTable />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'syndication/:objectId/details',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <SyndicationDetails />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'statistic',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <Statistic />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'document',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <DocumentTable />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'faq',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <FAQTable />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'email_template',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <EmailTemplateTable />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'callback',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <AdminCallbackTable />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'table/:tablename',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <SimpleTable />
                        </OrganizationTypeGuard>,
                    },
                    {
                        path: 'function/:functionname',
                        element: <OrganizationTypeGuard type="MANAGER" >
                            <SimpleTable/>
                        </OrganizationTypeGuard>,
                    },
                ]
            }
        ]
    },  {
        path: 'loginAs/:userId',
        element: (
            <OrganizationTypeGuard type="MANAGER" >
                <BecomeUser />
            </OrganizationTypeGuard>
        ),
    },{
        path: '*',
        element: <NotFound />,
    }
];

export default routes;