import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField, CircularProgress, InputAdornment, IconButton, Alert } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { authAPI } from '../../services/api/AuthAPI';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SetupAccountLinkDialog from './SetupAccountLinkDialog';

const LoginJWT = (props) => {
  const { email, hide_registration_btn = false, onLogin, token } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [setupAccountDialogOpen, setSetupAccountDialogOpen] = useState(false);
  const { login, loginWithToken } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    if (token) loginWithToken(token).catch(err => { setError("Something went wrong") });
  }, []);

  return (
    <>
      {error &&
        <Alert severity='error'>{error}</Alert>
      }
      {!error && token &&
        <>Redirecting...</>
      }
      {(!token || error) &&
        <Formik
          initialValues={{
            email: email || '',
            password: '',
            submit: null
          }}
          validationSchema={Yup
            .object()
            .shape({
              email: Yup
                .string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup
                .string()
                .max(255)
                .required('Password is required')
            })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await login(values);
              setStatus({ success: true });
              setSubmitting(false);
              if (onLogin) onLogin();
            } catch (err) {
              setStatus({ success: false });
              setSubmitting(false);
              if (err.code == 101) {
                let need_reset = await authAPI.checkIfNeedResetPwd(values.email);
                console.log("need", need_reset)
                if (need_reset) {
                  toast.error("You are required to reset your password");
                  navigate("/auth/password-recovery/" + values.email);
                }
                let need_setup = await authAPI.checkIfUserLimited(values.email);
                if (need_setup) {
                  setSetupAccountDialogOpen(true);
                }
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <>
              <form
                noValidate
                onSubmit={handleSubmit}
                {...props}
              >
                <TextField
                  autoFocus
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="standard"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ outline: "none" }}>
                        <IconButton onClick={() => setShowPassword(!showPassword)} >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                )}
                <Box sx={{ mt: 2 }} />
                <div style={{ position: 'relative' }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    id="login_btn"
                  >
                    Log In
                  </Button>
                  {isSubmitting &&
                    <CircularProgress
                      size={24}
                      style={{
                        position: 'absolute',
                        top: '20%',
                        left: '90%'
                      }}
                    />}
                </div>
                <Box sx={{ mt: 2 }} />
                {!hide_registration_btn &&
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="outlined"
                    component={Link}
                    to="/auth/register"
                    id="signup_btn"
                    type="button"
                  >
                    Create new account
                  </Button>
                }
              </form>
              <SetupAccountLinkDialog open={setupAccountDialogOpen} setOpen={setSetupAccountDialogOpen} email={values.email} />
            </>
          )}
        </Formik>
      }
    </>
  );
};

export default LoginJWT;
