import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        fontSize: 11,
        fontFamily: 'Times-Roman',
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4'
    },
    section: {
        marginLeft: 30,
        marginRight: 20,
        marginBottom: 10,
        padding: 10,
        //flexGrow: 1
    },
    logo: {
        width: 140,
        alignSelf: 'flex-end'
    },
    mb: {
        marginBottom: 5,
    },
    italic: {
        fontFamily: 'Times-Italic'
    },
    bold: {
        fontFamily: 'Times-Bold'
    },
    signature: {
        position: "absolute",
        left: 20,
        bottom: 0,
        width: 100
    }
});

/** TO BE USED if REACT_APP_USE_REACT_PDF == true */
const LetterOfIntentPDFtemplate = (props) => {
    const { data } = props;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    {data?.purchaser_logo &&
                        <Image src={data.purchaser_logo} style={styles.logo} />
                    }
                    <Text style={styles.mb}>
                        Dear <Text style={styles.italic}>{data?.seller_name}</Text>,
                    </Text>
                    <Text style={styles.mb}>
                        It is with pleasure, <Text style={styles.italic}>{data?.purchaser_name}</Text> can submit the Terms of our Offer on your property, <Text style={styles.bold}>{data?.listing_name} </Text> located at <Text style={styles.italic}>{data?.listing_address}</Text>.
                    </Text>
                    <Text >The terms of our Offer are set forth below: </Text>
                </View>
                <View style={styles.section}>
                    <Text>Purchase Price: <Text style={styles.bold}>{data?.purchase_price}</Text> </Text>
                    <Text>Earnest Money Deposit: <Text style={styles.bold}>{data?.earnest_money_deposit}</Text></Text>
                    <Text>Due Diligence Period (Days): <Text style={styles.bold}>{data?.dd_period}</Text></Text>
                    <Text style={styles.mb}>Closing Date: <Text style={styles.bold}>{data?.closing_date}</Text></Text>
                    {data?.other_terms &&
                        <>
                            <Text style={styles.bold}>Other terms:</Text>
                            <Text style={styles.mb}>{data.other_terms}</Text>
                        </>
                    }
                    {data?.requested_dd_items &&
                        <>
                            <Text style={styles.bold}>Requested Due Diligence Items:</Text>
                            <Text>{data.requested_dd_items}</Text>
                        </>
                    }
                </View>
                <View style={styles.section}>
                    <Text>To accept the terms of this Offer and proceed to the Purchase & Sale Agreement, please click Accept. We look forward to your response and comments. </Text>
                    <Text>THIS IS A NON-BINDING LETTER OF INTENT.  ANY AND ALL NEGOTIATIONS SHALL BE GOVERNED SOLELY BY THE PURCHASE AND SALE AGREEMENT.  </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.bold}>{data?.purchaser_name}</Text>
                </View>
                <View style={{ marginLeft: 40 }}>
                    <Text style={styles.mb}></Text>
                    <Text>
                        By ____________________
                    </Text>
                    {data?.signature &&
                        <Image src={data?.signature} style={styles.signature} />
                    }
                </View>
                <View style={styles.section}>
                    <Text style={styles.mb}>Its {data?.signee_title}</Text>
                    <Text>{data?.today_date}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default LetterOfIntentPDFtemplate;