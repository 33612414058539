
import { Link as RouterLink,useParams } from 'react-router-dom';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LoginJWT from '../../components/auth/LoginJWT';
import Logo from '../../components/logos/Logo';
import { SHOW_LOGO } from '../../config';
import { useStyles } from './style';

const Login = (props) => {
    const { token } = useParams();
    const classes = useStyles();



    return (
        <>
            <Helmet>
                <title>Login | nxtCRE</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{ py: '20px' }}
                >
                    {SHOW_LOGO &&
                        <Box
                            sx={{
                                mb: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Logo
                                height={75}
                                width={200}
                            />
                        </Box>
                    }
                    <Card className={classes.customCard}>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3
                                }}
                            >
                                <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                    >
                                        Log in
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        Enter your email and password below to access your account
                                    </Typography>
                                </div>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3
                                }}
                            >
                                <LoginJWT token={token}/>
                            </Box>
                            <Divider sx={{ my: 3 }} />
                            <Box
                                sx={{
                                    mt: 1
                                }}
                            />
                            <Link
                                color="textSecondary"
                                component={RouterLink}
                                to="/auth/password-recovery"
                                variant="body2"
                            >
                                Forgot your password?
                            </Link>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    )
}

export default Login;