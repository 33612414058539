
import { handleStatusCode } from './API';
const Parse = require('parse');

class AuthAPI {
    async login(requestData) {
        try {
            let user = await Parse.User.logIn(requestData.username?.toLowerCase(), requestData.password);
            user.organizations = await user.get("organizations").query().find();
            user.roles = await this.getRoles(user);
            return user;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async loginWithToken(token) {
        try {
            let session_token = await Parse.Cloud.run("login_with_token", {token});
            const user = await Parse.User.become(session_token);
            user.organizations = await user.get("organizations").query().find();
            user.roles = await this.getRoles(user);
            return user;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }
    async logout() {
        await Parse.User.logOut();
    }

    async signup(requestData) {
        let user = new Parse.User();
        Object.keys(requestData).forEach(key => {
            user.set(key, requestData[key]);
        });
        //console.log(user);
        try {
            let response = await user.signUp();
            user.organizations = await user.relation("organizations").query().find();
            user.roles = await this.getRoles(user);
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async finishSignUp(requestData) { /** finishing set up for limited users */
        try {
            const user = await Parse.Cloud.run("finish_sign_up", requestData);
            return user;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async getUser() {
        try {
            let currentUser = await Parse.User.currentAsync();
            if (currentUser) {
                await currentUser.fetchWithInclude("organizations");
                currentUser.organizations = await currentUser?.relation("organizations").query().find();
                currentUser.roles = await this.getRoles(currentUser);
                //console.log("roles",currentUser.roles);
            }
            return currentUser;
        } catch (error) {
            console.error('[Auth Api]: ', error);
            return handleStatusCode(error);
        }
    }

    async getRoles(user) {
        try {
            var query = new Parse.Query(Parse.Role);
            query.equalTo('users', user)
            let roles = await query.find();
            return roles;
        } catch (error) {
            console.error('[Auth Api]: ', error);
            return handleStatusCode(error);
        }
    }

    async requestPasswordReset(requestData) {
        try {
            const response = await Parse.User.requestPasswordReset(requestData.email.toLowerCase());
            return response
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async checkPasswordResetToken(token, username) {
        try {
            const response = await Parse.Cloud.run("check_password_reset_token", { token, username });
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async verifyEmail(token, username) {
        try {
            const response = await Parse.Cloud.run("verify_email", { token, username });
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async resendVericationEmail() {
        try {
            const response = await Parse.Cloud.run("resend_verification_email");
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async sendAccountSetupEmail(email) {
        try {
            const response = await Parse.Cloud.run("send_account_setup_email",{email});
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async resetPassword(token, username, password, password_confirmation) {
        try {
            const response = await Parse.Cloud.run("reset_password_with_token", { token, username, password, password_confirmation });
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async changePassword(values) {
        try {
            const response = await Parse.Cloud.run("changepwd", values);
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async checkIfNeedResetPwd(email) {
        try {
            const response = await Parse.Cloud.run("check_if_forced_to_reset_password", { email });
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async checkIfUserLimited(email) {
        try {
            const response = await Parse.Cloud.run("check_if_user_limited", { email });
            return response;
        } catch (error) {
            //console.error('[Auth Api]: ', err.response);
            return handleStatusCode(error);
        }
    }

    async becomeUser(user_id) {
        try {
            const token = await Parse.Cloud.run("get_user_token", { user_id });
            const user = await Parse.User.become(token);
            return user;
        } catch (error) {
            return handleStatusCode(error);
        }
    }
    async logoutImpersonatedUser(token) {
        try {
            const user = await Parse.User.become(token);
            return user;
        } catch (error) {
            return handleStatusCode(error);
        }
    }
}
export const authAPI = new AuthAPI();