import React from 'react';
import { Box,  Typography,Link } from '@mui/material';

const SidebarNeedHelp = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography
        color="textPrimary"
        variant="subtitle2"
      >
        Need Help?
      </Typography>
      <Typography
        color="textSecondary"
        variant="body2"
      >
        Contact us at <Link href="mailto:info@nxt-cre.com">info@nxt-cre.com</Link>
      </Typography>
    </Box>
  );
};

export default SidebarNeedHelp;