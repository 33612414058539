import { Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DealInfo from './DealInfo';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';
import { changeDealStatus } from '../../services/api/ListingAPI';
import { useState } from 'react';
import ConfirmDialog from '../ConfirmDialog';


const DealInfoDialog = (props) => {
    const { open, setOpen, deal, match } = props;
    const { currentOrganization } = useAuth();
    const [isConfirmDialogOpen,setConfirmDialogOpen]=useState(false);
    const [dialogData,setDialogData]=useState(null);

    const handleChangeStatus=(status)=>{
        let confirmContent;
        let inputs;
        switch (status) {
            case 'LC':
                confirmContent='Are you sure you want to revoke this Letter Of Intent?';
                break;
            case 'LA':
                confirmContent='Are you sure you want to accept this Letter Of Intent?';
                break;
            case 'LR':
                confirmContent='Are you sure you want to reject this Letter Of Intent?';
                inputs=[{
                    label: 'Provide a note (optional)',
                    type: 'text',
                    name: 'note',
                    value: '',
                    grid_props: { xs: 12, },
                    rows: 8,
                }]
                break;
        }
        setDialogData({confirmContent,status,inputs});
        setConfirmDialogOpen(true);
    }

    const _changeDealStatus= async (status,note)=>{
        setConfirmDialogOpen(false);
        var result= await changeDealStatus(deal,status,note);
        toast.success("Success");
        setOpen(false);
    }

    const actionBtns = () => {
        if (deal?.get("status") === 'LS') {
            if (match?.get("listing_organization")?.id === currentOrganization.id)
                return (
                    <>
                        <Button autoFocus onClick={() => { setOpen(false); }} >
                            Cancel
                        </Button>
                        <Button onClick={()=>handleChangeStatus("LR")} color="error" variant="outlined">
                            Reject
                        </Button>
                        <Button onClick={()=>handleChangeStatus("LA")} color="success" variant="contained">
                            Accept
                        </Button>
                    </>
                )
            if (match?.get("preference_organization")?.id === currentOrganization.id)
                return (
                    <>
                        <Button autoFocus onClick={() => { setOpen(false); }} >
                            Cancel
                        </Button>
                        <Button onClick={()=>handleChangeStatus("LC")} color="error" variant="contained">
                            Revoke
                        </Button>
                    </>
                )
        }

        return (
            <Button autoFocus onClick={() => { setOpen(false); }} >
                Close
            </Button>
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { setOpen(false); }}
                PaperProps={{
                    style: {
                        minWidth: '50%',
                        minHeight: '80%',
                        maxWidth: '1000px',
                        padding: '20px'
                    },
                }}
            >
                <DialogTitle sx={{ p: 0 }}>
                    Deal #{deal?.id}
                    <IconButton
                        aria-label="close"
                        onClick={() => { setOpen(false); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    ><CloseIcon /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DealInfo deal={deal} />
                </DialogContent>
                <DialogActions>
                        {actionBtns()}
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                setOpen={setConfirmDialogOpen}
                content={dialogData?.confirmContent}
                inputs={dialogData?.inputs}
                onConfirm={(values)=>_changeDealStatus(dialogData?.status,values?.note)}
            />
        </>
    )
}

export default DealInfoDialog;