import { Component,createRef } from 'react';
import { getItems } from "../../services/api/ModelAPI";
import ChatMessage from './ChatMessage';
import { Box, Skeleton } from '@mui/material';
import { markThreadAsReaded } from '../../services/api/ChatAPI';


//We can't use functional component here since we need to bind this to subscription event listener
class ChatMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            page: 1,
            total: 0,
            messages: [],
            subscription:null,
            extra_skip:0
        }
        this.scrollRef = createRef();
    }

    componentDidMount(){
        this.loadMessages();
    }
    componentWillUnmount(){
        if (this.state.subscription) {
            this.state.subscription.unsubscribe();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.addedMessages?.length !== this.props.addedMessages?.length) {//message added
            if (this.scrollRef) this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight;
        }
    }

    loadMessages() {

        let currentHeight = this.scrollRef?.current?.scrollHeight;
        this.setState({ isLoading: true });
        getItems("message",
                    10,
                    this.state.page,
                    'createdAt',
                    { match: { "__type": "Pointer", "className": "match", "objectId": this.props.dialog?.match.id || this.props.dialog?.match.objectId } },
                    '',
                    [],
                    null,
                    this.state.extra_skip
        ).then(data=>{
            const { count, results, subscription } =data;
            if (results.length > 0) {
                this.setState({ total: count, page: this.state.page + 1, messages: [...results.reverse(), ...this.state.messages] });
                this.scrollRef.current.scrollTop += this.scrollRef.current.scrollHeight - currentHeight;
            }
            if (subscription) {
                if (this.state.subscription) {
                    this.state.subscription.unsubscribe();
                }
                this.setState({subscription});
                subscription.on('create', this.onNewMessageEvent.bind(this));
            }
            this.setState({ isLoading: false });
        }).catch (err => {
            console.error(err);
            this.setState({ isLoading: false });
        });

    }

    onNewMessageEvent(message) {
        //console.log(this.state.messages, this);
        this.setState({messages:[...this.state.messages,message ],extra_skip:this.state.extra_skip+1});
        if (this.scrollRef)
            this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight;
        if (this.props.onNewMessageRecieved && (message.get("sender").id!=this.props.currentOrganization.id)) this.props.onNewMessageRecieved(this.props.dialog,message);
    }

    loadMoreIfNeed(event) {
        //console.log("scroll " + ( event.target.scrollTop) + " - " + (event.target.scrollHeight - 1) + ", total:" + total)
        if ((event.target.scrollTop < 5) && (this.state.messages.length < this.state.total)) {
            this.loadMessages();
        }
    }

    render() {
        return (
            <Box id={this.props.id} key={this.props.id} sx={{ overflowY: 'scroll', p: 2, flexGrow: 1 }} onScroll={this.loadMoreIfNeed.bind(this)} ref={this.scrollRef}>
                {this.state.isLoading &&
                    <Box sx={{ pb: 2 }}>
                        <Skeleton />
                        <Skeleton />
                    </Box>
                }
                {[...this.state.messages, ...(this.props.addedMessages||[])]?.map((message, index) => {

                    let senderAvatar;
                    let senderName;
                    let senderType;

                    if (message.get("sender")?.id == this.props.currentOrganization?.id) {
                        senderName = message.get("created_by")?.id == this.props.user.id ? "Me" : message.get("sender_name") || 'Private person';
                        senderType = 'user';
                    } else if (this.props.adminView && message.get("created_by")?.id == this.state.messages[0]?.get("created_by")?.id) {
                        senderName = message.get("sender_name") || 'Private person';
                        senderType = 'user';
                    } else {
                        senderName = message.get("sender_name") || 'Private person';
                        senderType = 'contact';
                    }

                    return (
                        <ChatMessage
                            body={message.get("message")}
                            attachments={message.get("attachments")}
                            showDealInfoDialog={this.props.showDealInfoDialog}
                            contentType='text'
                            sent_at={message.createdAt}
                            read_at={message.get("readAt")}
                            key={"message_" + index}
                            senderName={senderName}
                            senderType={senderType}
                            match={this.props.dialog?.match}
                            size="small"
                            adminView={this.props.adminView}
                        />
                    );
                })}
            </Box>
        )
    };
};

//ChatMessages.whyDidYouRender = true

export default ChatMessages;
