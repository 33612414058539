import { handleStatusCode,isLiveQuery } from './API';

const Parse = require('parse');

export const getChatThreads= async (organization,page,limit,extra_skip=0)=>{
    try {
        const q1 = new Parse.Query("match");
        q1.equalTo("listing_organization",organization);
        const q2 = new Parse.Query("match");
        q2.equalTo("preference_organization",organization);
        const query = Parse.Query.or(q1,q2);
        query.exists("last_message");
        query.include(["last_message","deal"]);
        query.descending("updatedAt");
        query.limit(limit);
        query.skip((page-1)*limit+extra_skip);
        query.withCount();
        const response=await query.find();
        if (isLiveQuery("match") && page==1) {
            response.subscription = await query.subscribe();
            response.subscription.on('open', () => {
                //console.log('subscription opened for threads');
              });
            response.subscription.on('close', () => {
                //console.log('subscription closed for threads');
              });
            response.subscription.on('create', (object) => {
                response.results.unshift(object);
            });
            /*subscription.on('update', (object) => {
                console.log('object updated');
            });
            subscription.on('delete', (object) => {
                console.log('object deleted');
              });*/
            //console.log("Using live query");
        }
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

//old version of getting threads
export const getChatThreadsCloudFunction = async (organization_id,page,limit)=>{
    try {
        const response=await Parse.Cloud.run("get_chat_threads",{organization_id,skip:(page-1)*limit,limit});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const markThreadAsReaded = async (match_id,organization_id)=>{
    try {
        const response=await Parse.Cloud.run("mark_thread_as_readed",{match_id,organization_id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}