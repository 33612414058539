import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import logo from '../../logos/click_to_upload.jpg';
const UploadImageButton = (props) => {
  const { isDisabled,  upload,  image, ...rest } = props;

  const [selectedFile, setSelectedFile] = useState(undefined);
  const [isUploading, setIsUploading] = useState(false);

  const onFileChange = event => {
    const selected = event.target.files[0];
    setSelectedFile(selected);
  };

  useEffect(() => {
    if (selectedFile?.name) {
      uploadFile();
    }
  }, [selectedFile]);

  const uploadFile = async () => {
    try {
      const formData = new FormData();
      setIsUploading(true);
      
      await upload(selectedFile);
      toast.success('Upload Complete');
      setIsUploading(false);
    } catch (err) {
      setIsUploading(false);
      console.log(err);
      toast.error('Error! File not upload.');
    }
  };

  return (
    <Box>
      <label style={{cursor:"pointer",position:'relative'}} title="Click to upload new image">
        <img src={image?.url() || logo} alt="Click to upload new image" {...rest}/>
        {isUploading &&
          <CircularProgress sx={{position:'absolute',left:40,top:-30}} />
        }
        <input
          disabled={isDisabled}
          type="file"
          accept={"image/png, image/jpeg, image/jpg"}
          hidden
          onChange={onFileChange}
        />
      </label>
    </Box>
  );

};

export default UploadImageButton;