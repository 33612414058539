import { Alert, CircularProgress, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import PdfUrlViewer from '../pdf/PdfUrlViewer';



const DealInfo = (props) => {
    const { deal } = props;
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const refetchDeal = () => {
        setLoading(true);
        deal.fetchWithInclude("loi").then(d => {
            setLoading(false);
        }).catch(err => {
            setError(err.message || 'Error fetching document');
            setLoading(false);
        });
    }

    useEffect(() => {
        setError(null);
        if (!deal.get("loi")?.get("pdf")) refetchDeal();
    }, []);



    return (
        <>
            {error &&
                <Alert severity='error'>{error}</Alert>
            }
            {loading &&
                <Box sx={{ p: 2, textAlign: "center" }}> <CircularProgress /></Box>
            }
            {deal.get("status") == 'LC' &&
                <Alert severity='warning'>This letter of intent was revoked by author</Alert>
            }
            {deal.get("status") == 'LR' && deal.get("note") &&
                <>
                    <Alert severity='error'>
                        <b>Note:</b><br />
                        {deal.get("note")}
                    </Alert>
                </>
            }
            {!error && !loading && deal?.get("loi")?.get("pdf")?.url() &&
                <Box sx={{ position: 'relative' }}>
                    <PdfUrlViewer url={deal.get("loi").get("pdf")?.url()} />
                    {deal.get("status") == 'LR' &&
                        <Box sx={{ position: 'absolute', top: 40, left: 10, fontSize: 40, fontWeight: 'bold', color: '#ff0000aa', border: '4px solid #ff0000aa', p: 1, transform: "rotate(-10deg)" }}>DECLINED</Box>
                    }
                    {deal.get("status") == 'LA' &&
                        <Box sx={{ position: 'absolute', top: 40, left: 10, fontSize: 40, fontWeight: 'bold', color: "#478c47ab", border: '4px solid #478c47ab', p: 1, transform: "rotate(-10deg)" }}>ACCEPTED</Box>
                    }
                </Box>
            }
        </>
    )
}

export default DealInfo;