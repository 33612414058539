import { handleStatusCode } from './API';

const Parse = require('parse');


export const resendQuestionnaireEmail = async (questionnaire,ignore_unsubscribed=false)=>{
    try {
        const response=await Parse.Cloud.run("resend_questionnaire_email",{id:questionnaire.id,ignore_unsubscribed});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getQuestionnaire = async (code)=>{
    try {
        const response=await Parse.Cloud.run("get_questionnaire",{code:code});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getQuestionnaireOptions = async ()=>{
    try {
        const response=await Parse.Cloud.run("get_questionnaire_options",{include_counties:true});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const submitQuestionnaire = async (values)=>{
    try {
        const response=await Parse.Cloud.run("submit_questionnaire",values);
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}


//
export const getQuestionnaireEmailPreview = async (organization,type,template,_values)=>{
    let values={..._values};
    Object.keys(values)?.forEach(key=>{
        if (values[key] instanceof Parse.Object) values[key]=values[key].id;
    });
    try {
        const response=await Parse.Cloud.run("get_questionnaire_email_preview",{organization:organization.id,type,template,...values});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

//old one. to be replaced with getQuestionnaireEmailPreview
export const previewQuestionnaireEmail = async (organization,type,template,values)=>{
    try {
        const response=await Parse.Cloud.run("preview_questionnaire_email",{organization:organization.id,type,template,...values});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getCountyByCoords = async (lat,lng) => {
    try {
      const query =new Parse.Query("county");
      query.polygonContains('area', new Parse.GeoPoint(lat, lng));
      const response = await query.find();
      return response;
    } catch (error) {
      return handleStatusCode(error);
    }
  }
  
export const getPredictions = async (search) =>{
    try {
      const response=await Parse.Cloud.run("fetch_geopredictions",{search});
      return response ||{places:[],states:[]}
    } catch (error) {
      return handleStatusCode(error);
    }
}