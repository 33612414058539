import React from 'react';

import { Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const AccountSidebarBox = () => {
  const { user, currentOrganization } = useAuth();
  return (
    <Box
      sx={{
        alignItems: 'center',
        //backgroundColor: 'offWhite.main',
        background: "linear-gradient(176deg, rgba(68,239,255,1) 0%, rgba(29,184,221,1) 23%, rgba(11,157,205,1) 36%, rgba(0,151,201,1) 86%)",
        borderRadius: 1,
        display: 'flex',
        overflow: 'hidden',
        p: 2,
      }}
      style={{background:"linear-gradient(176deg, rgba(68,239,255,1) 0%, rgba(29,184,221,1) 23%, rgba(11,157,205,1) 36%, rgba(0,151,201,1) 86%)"}}
    >
      <Box>
        <RouterLink style={{ textDecoration: 'none' }} to={'/account'}>
          <Typography
            color="#fff"
            //color="textPrimary"
            variant="subtitle2"
            noWrap
          >
            {user.get("username")}
          </Typography>
          <Typography
            //color="textSecondary"
            color="#fff"
            variant="body2"
          >
            {currentOrganization?.get("name")||''}
          </Typography>
        </RouterLink>
      </Box>
    </Box >
  );
};

export default AccountSidebarBox;