import { Component } from "react";
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { useFormikContext, Field } from 'formik';

class JSONEditorReactComponent extends Component {

  componentDidMount() {
    const modes = ['tree', 'form', 'view', 'code', 'text'];//TODO do we need all modes?
    const options = { modes: modes, onChangeText: this.props.onChange, name: this.props.name };// see all options here: https://github.com/josdejong/jsoneditor/blob/master/docs/api.md#configuration-options
    this.jsoneditor = new JSONEditor(this.container, options);
    if (this.props.value) this.jsoneditor.set(this.props.value);
  }

  componentDidUpdate() {
    if (this.props.value) this.jsoneditor.set(this.props.value);
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }
  render() {
    return (
      <div className="jsoneditor-react-container" ref={elem => this.container = elem} />
    );
  }
}

const JSONEditorReact = (props) => {
  const { label, value, name: fieldName } = props;
  const { setFieldValue } = useFormikContext();
  const onChange = (json) => {
    console.log("changed"+fieldName, JSON.parse(json));
    try {
      setFieldValue(fieldName, JSON.parse(json));
    } catch (err) {
      //Error will be displayed by JSON editor
    } 
  }
  return <Field name={fieldName}>
    {({
      field, // { name, value, onChange, onBlur }
    }) => (
      <JSONEditorReactComponent onChange={onChange} name={label} value={value} />
    )}
  </Field>
}

export default JSONEditorReact;