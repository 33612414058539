import { Alert, AlertTitle, Button } from '@mui/material';
import { useEffect,useState } from 'react';
import toast from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import { authAPI } from '../../services/api/AuthAPI';

const EmailNotVerified = (props) => {
    const { user,getUserData } = useAuth();
    const [tryCount,setTryCount] = useState(0);
    const [hidden,setHidden]=useState(false);

    const resendEmail= async ()=>{
       try {
            await authAPI.resendVericationEmail();
            toast.success("Verification email successfully sent");
       } catch (err) {
           console.log(err);
       }
    }

    const recheckUser=(timeout=5000)=>{
        setTimeout(async ()=>{
            try {
                const _user = await authAPI.getUser();    
                setTryCount(tryCount+1);            
                if (!_user.get("emailVerified") && !hidden) tryCount<5? recheckUser():recheckUser(20000);
                else getUserData()
            } catch (err) {
                console.log(err);
            }
        },timeout);
    }

    useEffect(() => {
        if (!user.get("emailVerified") && !user.get("limited") ) {
            recheckUser(2000); //email verification status updated asyncroniously, so we are waiting few seconds before showing 'not verified' message
        }
    }, []);



    return (
        <>
            {!user.get("emailVerified") && !user.get("limited") && (tryCount>0) && !hidden &&
                <Alert severity="info" sx={{ m: 3 }} onClose={() => {setHidden(true)}}>
                    <AlertTitle>
                        Didn't receive a verification email? {user.get("emailVerified")}
                    </AlertTitle>
                    <Button color="primary" size="small" variant="contained" onClick={resendEmail}>Resend</Button>
                </Alert>
            }
        </>
    )
}

export default EmailNotVerified;