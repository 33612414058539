import React from 'react';
import { at } from 'lodash';
import { useFormikContext, useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@mui/material';

export default function CheckboxField(props) {
  const { name: fieldName, label, id, label_placement="end", ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setFieldValue } = useFormikContext();
  //const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    let value=e.target.checked;
    if (value==='true') value=true;
    if (value==='false') value=false;
    //console.log("changing to "+value)
    //setValue(value);
    setFieldValue(
      fieldName, value
    );
  }

  return (
    <FormControl {...rest} >
      <FormControlLabel
        control={<Checkbox {...field} id={id||("__"+fieldName)} name={fieldName} value={field.value} checked={['true','checked',true].includes(field.value)} onChange={_onChange} />}
        label={label}
        labelPlacement={label_placement}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
