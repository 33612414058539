import React, { useState } from 'react';
import { at } from 'lodash';
import { useField , useFormikContext} from 'formik';
import {
    InputLabel,
    FormControl,
    FormHelperText,
    Button, Dialog, DialogTitle,DialogContent,IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogForm from '../DialogForm'
const Parse = require('parse');

function PointerField(props) {
    const { label, variant, attribute, columns, color,placeholder='.....', readOnly, maxWidth="sm", organization, addl_actions, ...rest } = props;
    const [open, setOpen] = useState(false);
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const [touched, error] = at(meta, 'touched', 'error');

    const isError = touched && error && true;
    function _renderHelperText() {
        if (isError) {
            return <FormHelperText error>{error}</FormHelperText>;
        }
    }

    const onSubmit = (values,actions) =>{
        const Item = Parse.Object.extend(field.name);
        const item = new Item();
        Object.keys(values).forEach(key=>{
            item.set(key,values[key]);
        });
        if (organization) item.set("organization",organization);
        setFieldValue(field.name,item);
        console.log(label + "value:",item);
        actions.setSubmitting(false);
        setOpen(false);
    }

    return (
        <>
            <FormControl {...rest} error={isError}>

                <InputLabel shrink>{label}</InputLabel>
                <Button component="label" 
                    fullWidth 
                    variant={variant}
                    color={color}
                    sx={{ pt: '12px', pb: '12px', overflow: 'hidden',justifyContent: field.value?"flex-start":""}}
                    
                    onClick={() => setOpen(true)}>
                    {field.value?field.value.get(attribute):placeholder}
                </Button>
                {_renderHelperText()}
            </FormControl>
            <DialogForm title={(field.value?'Edit ':'New ') + label} fields={columns} data={field.value} onSubmit={onSubmit} open={open} setOpen={setOpen} addl_actions={addl_actions} />
        </>
    );
}


export default PointerField;
