import {
    FormControl,
    FormHelperText,
    FormLabel,
} from '@mui/material';
import { at } from 'lodash';
import JoditEditor from "jodit-react";
import { useState, useRef } from 'react';
import { useField, useFormikContext } from 'formik';


export default function JoditTextEditorField(props) {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const { type, ...rest } = props

    const editor = useRef(null)
    const [content, setContent] = useState(field.value || '');

    const config = {
        ...type == 'html' ? {
            iframe: true,
            iframeTitle: 'Hello world!',
            iframeDoctype: '<!DOCTYPE html >',
            editHTMLDocumentMode: true,
            iframeStyle: '',
        }:{},
        placeholder: field.label || ''
    };

    const _renderHelperText = () => {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return <FormHelperText error>{error}</FormHelperText>;
        }
    }

    const onUpdate = (body) => {
        setContent(body);
        setFieldValue(field.name, body);
    }

    return (
        <FormControl {...rest} >
            <FormLabel component="legend" sx={{ mb: 2, fontSize: '0.75rem' }}>
                {field.label}
            </FormLabel>
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                //  tabIndex={1} // tabIndex of textarea
                onBlur={onUpdate} // preferred to use only this option to update the content for performance reasons
            />
            {_renderHelperText()}
        </FormControl>
    );
}
