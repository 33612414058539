import Checkbox from '@mui/material/Checkbox';
import { CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useFormikContext, Field } from 'formik';
import { useEffect, useState } from 'react';
import { getItems } from '../../../services/api/ModelAPI';

const RelationField = (props) => {
    const { label, value, label_field, model, id, name: fieldName,onChange } = props;
    const [loading, setLoading] = useState(false);
    const [choices, setChoices] = useState([]);
    const [selected, setSelected] = useState([]);
    const { setFieldValue } = useFormikContext();

    const loadModel = async () => {
        setLoading(true);
        let s=await value?.query().find();
        //console.log("Current value",s);
        setSelected(s);
        let _choices = await getItems(model, 100, 1, null, { disabled: { 'notEqualTo': true } });
        setChoices(_choices.results);
        setLoading(false);
    }


    const handleToggle = (value) => {
        const currentIndex = selected.findIndex(s => s.id === value.id);
        let s = [...selected];
        if (currentIndex === -1) {
            s.push(value);
        } else {
            s.splice(currentIndex, 1);
        }
        setSelected(s);
        setFieldValue(fieldName,s);
        if (onChange) onChange();
    }

    useEffect(() => {
        //console.log("loading",value);
        if (model) {
            loadModel();
        } else {
            console.error("Model is required for relation field");
        }
    }, [model]);

    return <Field name={fieldName}>
        {({
            field, // { name, value, onChange, onBlur }
        }) => (
            loading ?
                <CircularProgress />
                :
                choices?.length ?
                    <List id={id}>
                        {choices.map((choice, key) => {
                            return (
                                <ListItem key={key} disablePadding >
                                    <ListItemButton role={undefined} onClick={() => handleToggle(choice)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selected.some(s => s.id === choice.id)}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={choice.id} primary={label_field ? (choice.get(label_field) || (model + ' : ' + choice.id)) : (model + '-- : ' + choice.id)} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                    : 'No options'
        )}
    </Field>
}

export default RelationField;