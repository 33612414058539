import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';

function SelectField(props) {
  const { label, choices, isDisabled, readOnly, ...rest } = props;
  const [field, meta] = useField(props);
  const selectedValue = field.value ? field.value : '';
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        displayEmpty
        disabled={isDisabled}
        label={label}
        {...field}
        value={selectedValue}
        inputProps={{
          readOnly: readOnly,
        }}
        input={<OutlinedInput variant='filled' notched label={label} />}
      >
        <MenuItem value="">
          <em>Please Choose a value</em>
        </MenuItem>
        {choices.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
