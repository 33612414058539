import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogContent,
    Link,
    Divider,
    Typography,
    Box,
    Grid,
    FormHelperText,
    TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SelectField from '../EnchantedForm/form_fields/SelectField';
import { states } from '../../config';
import ExternalListingInfo from './ExternalListingInfo';



const SellerTypeDialog = (props) => {
    const { onConfirm, isOpen, setOpen, listing_user_info = null, hide_sign_in = false } = props;
    const [step, setStep] = useState({});

    
    useEffect(() => {
        setStep({});
        //console.log("ST");
    }, [isOpen]); 

    const submitSellerType = (event, response) => {
        if (!response) response = { 'type': 'SELLER', 'data': {} };
        onConfirm(response);
    }

    return (
        <Dialog
            open={isOpen}
            PaperProps={listing_user_info?.listing ? {
                style: {
                    minWidth: '70%',
                    maxWidth: '1000px',
                    padding: '20px'
                },
            } : {}}

        >
            {/* <DialogTitle>{dialogTitle}</DialogTitle> */}
            <DialogContent sx={{ pt: 0 }}>
                {!step.type &&
                    <Box>
                        {listing_user_info?.listing ?
                            <ExternalListingInfo listing_user_info={listing_user_info} onSubmit={(seller_role) => {
                                if (seller_role === 'ISEEKER') submitSellerType(null, { 'type': 'SELLER', 'data': { 'equity_seeker': true } });
                                else setStep({ type: seller_role });
                            }} />
                            :
                            <>
                                <br /><b>Welcome to NxtCRE.</b> <br /> <br />
                                To begin, please tell us your interest:

                                <Button
                                    style={{ justifyContent: "flex-start" }}
                                    sx={{ mt: 2 }}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    onClick={() => { setStep({ type: 'SELLER' }) }}
                                >
                                    I am the owner of a property {!listing_user_info ? ' to list' : ''}
                                </Button>
                                <Button
                                    style={{ justifyContent: "flex-start" }}
                                    sx={{ mt: 2 }}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    onClick={() => { setStep({ type: 'BROKER' }) }}
                                >I am a broker {!listing_user_info ? ' with exclusive listings' : ''}</Button>
                                <Button
                                    style={{ justifyContent: "flex-start" }}
                                    sx={{ mt: 2, mb: 2 }}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    onClick={() => submitSellerType(null, { 'type': 'SELLER', 'data': { 'equity_seeker': true } })}
                                >I am an equity seeker soliciting investors</Button>
                            </>
                        }
                    </Box>
                }
                {step.type == 'SELLER' &&
                    <>
                        <Typography sx={{ pt: 2, mb: 2 }}>
                            <b>Important Notice to CRE owners/sellers:</b><br /><br />
                            You are about to join the first and only platform that connects lender-verified Must Buy Investors and CRE sellers.<br /><br />
                            If you are not represented by a professional broker, we urge you to consider the valuable service they provide in  proper pricing, early negotiation and due diligence.<br /><br />
                            If do not have contact with a professional broker, some of the best in the business are already registered with nxtCRE and we can help with a connection.<br /><br />
                        </Typography>
                        <Box sx={{ mx: 2 }} style={{ justifyContent: "flex-end", textAlign: "right" }}>
                            <Button
                                sx={{ mx: 2 }}
                                color="secondary"
                                href={`mailto:info@nxt-cre.com`}>
                                Contact Us
                            </Button>
                            <Button
                                sx={{ mx: 2 }}
                                color="primary"
                                variant="contained"
                                onClick={submitSellerType}
                            >Continue</Button>
                        </Box>
                    </>
                }
                {step.type == 'BROKER' && step.hasExclusive === undefined && !listing_user_info &&
                    <>
                        <h3>Do you have an exclusive listing?</h3>
                        <Grid
                            container
                            direction='row'
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                        >
                            <Grid item xs={6} sx={{ p: 1 }}>
                                <Button
                                    color="info"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    onClick={() => { setStep({ type: 'BROKER', hasExclusive: false }) }}
                                >No</Button>
                            </Grid>
                            <Grid item xs={6} sx={{ p: 1 }}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    onClick={() => { setStep({ type: 'BROKER', hasExclusive: true }) }}
                                >Yes</Button>
                            </Grid>
                        </Grid>
                    </>
                }
                {step.type == 'BROKER' && step.hasExclusive == false &&
                    <Typography sx={{ mt: 2 }}>
                        At this time, access to nxtCRE curated Must Buy Investors (MBI) is limited to brokers with an exclusive listing. Please <a href="mailto:info@nxtcre.com">Contact Us</a> to see how you can use the nxtCRE platform to help obtain your own exclusive listing.
                    </Typography>
                }
                {step.type == 'BROKER' && (step.hasExclusive === true || listing_user_info !== null) &&
                    <Box sx={{ pt: 2 }}>
                        Please provide your broker’s license and state of registration:
                        <Formik
                            initialValues={{
                                license: '',
                                state: ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    license: Yup.string()
                                        .max(255)
                                        .required('License is required'),
                                    state: Yup.string()
                                        .max(255)
                                        .required('State is required'),
                                })
                            }
                            onSubmit={(values) => {
                                submitSellerType(null, { 'data': values, type: 'BROKER' })
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                                isValid,
                                dirty,
                            }) => (
                                <form noValidate onSubmit={handleSubmit} {...props}>
                                    <TextField
                                        error={Boolean(touched.license && errors.license)}
                                        fullWidth
                                        helperText={touched.license && errors.license}
                                        label="License"
                                        margin="normal"
                                        name="license"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.license}
                                        variant="outlined"
                                    />
                                    <SelectField
                                        error={Boolean(touched.state && errors.state)}
                                        // helperText={touched.state && errors.state}
                                        fullWidth
                                        label='State'
                                        margin="normal"
                                        name='state'
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        choices={states}
                                    />
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <Box sx={{ mt: 2 }}>
                                        <div style={{ position: 'relative' }}>
                                            <Button
                                                color="primary"
                                                disabled={!(dirty && isValid)}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                }
                {!hide_sign_in &&
                    <>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Typography
                            style={{ textAlign: "center" }}>
                            <Link
                                color="textSecondary"
                                component={RouterLink}
                                to="/auth/login"
                                variant="body2"
                            >
                                I have an account
                            </Link>
                        </Typography></>
                }
            </DialogContent>
        </Dialog>
    );
}

export default SellerTypeDialog;

