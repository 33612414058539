import { Box } from '@mui/material';
import logo from './nxtCRE_logo_rgb.png'; 

const Logo = (props) => (
  <Box
    {...props}
  ><img
      src={logo}
      alt="Logo"
      {...props}
    /></Box>
);
export default Logo;
