import { Chip, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Box, FormHelperText, Typography, Alert } from "@mui/material";
import { useState } from 'react';
import { deleteListingsByCode } from "../../services/api/ListingAPI";
import ConfirmDialog from "../ConfirmDialog";
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";



const ExternalListingInfo = (props) => {
    const { listing_user_info, onSubmit } = props;
    const listing = listing_user_info?.listing
    const [seller_role, setSellerRole] = useState();
    const [error, setError] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const navigate = useNavigate();

    const currency_formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });


    const handleDeleteListing = async () => {
        try {
            await deleteListingsByCode(listing_user_info.code);
            toast.success("Information about your listing was succesfully removed");
            setDeleteDialogOpen(false);
            navigate("/auth/login");
        } catch (e) {
            setDeleteDialogOpen(false);
        }
    }

    return (
        <>
            <b>Import your listing</b> <br /> <br />

            {(listing_user_info.matches > 0) &&
                <Alert sx={{ mb: 2 }}>
                    We have identified your listing which is matched with one or more of our 1031 exchange buyers. To see your matched buyers, click 'Continue' to sign up and submit your listing.
                </Alert>
            }

            <Box>
                {listing?.get("images") && listing.get("images")[0] &&
                    <img src={listing.get("images")[0].url()} style={{ width: "25%", marginRight: '1rem', marginBottom: '1rem', float: 'left' }} />
                }
                <Typography variant="h5">{listing?.get("title")}</Typography>
                <Typography variant="subtitle2">{listing?.get("address_line1")}, {listing?.get("city")}, {listing?.get("state")}, {listing?.get("post_code")}</Typography>
                <Chip sx={{ mt: 2 }} label={currency_formatter.format(listing?.get("price"))} color="primary" />
            </Box>
            <Box sx={{ clear: 'both' }} />
            <FormControl
                error={error}
                sx={{ ml: 4 }}
            >
                <FormLabel id="seller_role_label">Before continue, please, choose your role:</FormLabel>
                <RadioGroup
                    aria-labelledby="seller_role_label"
                    //defaultValue="female"
                    name="roles_group"
                    onChange={(e) => { setSellerRole(e.target.value); setError(false) }}
                    sx={{ pt: 1 }}
                >
                    <FormControlLabel value="BROKER" sx={{ mb: 0.5 }} control={<Radio size="small" sx={{ pt: 0, pb: 0 }} />} label="I am the listing broker" />
                    <FormControlLabel value="SELLER" sx={{ mb: 0.5 }} control={<Radio size="small" sx={{ pt: 0, pb: 0 }} />} label="I am the owner of a property" />
                    <FormControlLabel value="ISEEKER" sx={{ mb: 0.5 }} control={<Radio size="small" sx={{ pt: 0, pb: 0 }} />} label="I am an equity seeker soliciting investors" />
                </RadioGroup>
                {error &&
                    <FormHelperText id="seller_role_helper">Required</FormHelperText>
                }
            </FormControl>
            <Box sx={{ mx: 2 }} style={{ justifyContent: "flex-end", textAlign: "right" }}>
                <Button
                    sx={{ mt: 2, mb: 2 }}
                    size="large"
                    variant="outlined"
                    id="remove_external_listing"
                    onClick={() => { setDeleteDialogOpen(true) }}
                >Remove listing</Button>
                <Button
                    sx={{ mt: 2, mb: 2, ml: 2 }}
                    color="primary"
                    size="large"
                    variant="contained"
                    id="confirm_external_listing"
                    onClick={seller_role ? () => { onSubmit(seller_role) } : () => { setError(true) }}
                > Continue</Button>
            </Box>
            <ConfirmDialog
                isOpen={isDeleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                content={'Are you sure you want to remove information about your current listing?'}
                onConfirm={handleDeleteListing}
            />
        </>
    );
}

export default ExternalListingInfo;

