import { saveError } from "./services/api/API";

var console=(function(oldCons){
  return {
      log: function(text,...rest){
        oldCons.log(text,...rest);
      },
      info: function (text,...rest) {
          oldCons.info(text,...rest);
          // Your code
      },
      warn: function (text,...rest) {
          oldCons.warn(text,...rest);
          // Your code
      },
      error: function (text,...rest) {
          //oldCons.log("rest:",rest);
          oldCons.error(text,...rest);
          saveError(text,rest);
      }
  };
}(window.console));

window.console = console;