
import { api, handleStatusCode,isLiveQuery } from './API';
import { API_URL, USE_INDEXED_SEARCH} from '../../config';

const Parse = require('parse');


export const getItems = async (modelname,limit=10,page=1,order=null,filters=null,search_criteria='',search_in=[],include,extra_skip=0) => {
    
    try {
        let query = new Parse.Query(modelname);
        if (search_criteria.trim()!=='' && search_in && search_in.length>0) {
            if (search_in.length===1) {
                if (search_in[0]=='id') search_in[0]='objectId';
                if (USE_INDEXED_SEARCH)
                    query.fullText(search_in[0], search_criteria.trim());
                else
                    query.matches(search_in[0],search_criteria.trim(),'i');
            } else {
                let subqueries=[];
                search_in.forEach(field=>{
                    if (field=='id') field='objectId';
                    const q = new Parse.Query(modelname);
                    if (USE_INDEXED_SEARCH) 
                        q.fullText(field, search_criteria.trim());
                    else
                        q.matches(field,search_criteria.trim(),'i');
                    subqueries.push(q);
                });
                query= Parse.Query.or(...subqueries);
            }
        }

        if (filters) {
            Object.keys(filters).forEach(key=>{
                let keyparts=key.split(".");
                if (typeof filters[key]=='object' && !(filters[key] instanceof Parse.Object) && !(filters[key].__type)) {
                    let op=Object.keys(filters[key])[0];
                    if (typeof query[op] === 'function') {
                        
                        //check if we need subquery - additional fields can be added later
                        if (keyparts.length>1 && ["match"].includes(modelname) && ["listing","preference"].includes(keyparts[0])) {
                            let squery=new Parse.Query(keyparts[0]);
                            squery[op](keyparts[1],filters[key][op]);
                            query.matchesQuery(keyparts[0],squery);
                        } else
                            query[op](key,filters[key][op]);
                    } else
                        console.error("Unknow operator in filters:"+op);
                } else {
                    //check if we need subquery - additional fields can be added later
                    if (keyparts.length>1 && ["match"].includes(modelname) && ["listing","preference"].includes(keyparts[0])) {
                        let squery=new Parse.Query(keyparts[0]);
                        squery.equalTo(keyparts[1],filters[key]);
                        query.matchesQuery(keyparts[0],squery);
                    } else
                        query.equalTo(key,filters[key]);
                }
            });
        }
        if (include) query.include(include);
        if (order){
            if (order.substr(0,1)==='-') query.ascending(order.substr(1));
            else query.descending(order);
        } 
        query.limit(limit);
        query.skip(limit*(page-1)+extra_skip);
        query.withCount();
        const response = await query.find();
        if (isLiveQuery(modelname) && page==1) {
            response.subscription = await query.subscribe();
            response.subscription.on('open', () => {
                //console.log('subscription opened for '+modelname);
              });
            response.subscription.on('close', () => {
                //console.log('subscription closed for '+modelname);
              });
            response.subscription.on('create', (object) => {
                response.results.unshift(object);
            });
            /*subscription.on('update', (object) => {
                console.log('object updated');
            });
            subscription.on('delete', (object) => {
                console.log('object deleted');
              });*/
            //console.log("Using live query");
        }
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
};

export const getItem = async (modelname, item_id,include) =>{
    try {
        const query = new Parse.Query(modelname);
        if (include) query.include(include);
        const response = await query.get(item_id);
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
};

export const deleteItem = async (modelname, item) =>{
    try {
        const response = await item.destroy();
        return response;
    } catch (error) {
        if (error.code===405) return {}; //to allow 'fake' deletions
        return handleStatusCode(error);
    }
};

export const deleteItems = async (modelname, ids) =>{
    //Parse.SDK doesn't have any method for bulk delete, so, we'll use rest-api
    let data={"requests":ids.map(id=>{return {"method": "DELETE","path": "/api/classes/"+modelname+"/"+id}})};
    try {
        const response = await api().post(API_URL + '/batch',data);
        const { status } = response;
        handleStatusCode({ status_code: status });
        return response.data || {};
    } catch (error) {
        if (error.code===405)  return {};
        return handleStatusCode(error);
    }
};

export const updateItem = async (modelname, item, values) =>{
    //console.log("updating",values);
    for(let key of Object.keys(values)) {
        if (item.get(key) instanceof Parse.Relation) { 
            const relation = item.relation(key);
            let current_objs=await relation.query().find();
            //adding if not related
            values[key].forEach(v=>{
                if (!current_objs.some(c=>c.id===v.id)) relation.add(v);
            });
            //removing unrelated
            current_objs.forEach(c=>{
                if (!values[key].some(v=>v.id===c.id)) relation.remove(c);
            });
        } else
            item.set(key,values[key]);
    }
    
    try {
        const response = await item.save();
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const createItem = async (modelname, values) =>{
    try {
        const Item = Parse.Object.extend(modelname);
        const item = new Item();
        Object.keys(values).forEach(key=>{
            item.set(key,values[key]);
        });
        const response = await item.save();
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const createItems=async(modelname,items,batch_size=20)=>{
    //Parse.SDK doesn't have any method for bulk creation, so, we'll use rest-api
    let requests=[];
    items.forEach(body=>{
        requests.push({method:"POST",path: "/api/classes/"+modelname,body});
    });
    let succeed=[];
    let errors=[];
    for (var i=0;i<requests.length;i+=batch_size) {
        let data=requests.slice(i,i+batch_size);
        try {
            const response = await api().post(API_URL + '/batch',{"requests":data});
            const { status } = response;
            handleStatusCode({ status_code: status });
            response.data?.forEach(res=>{
                if (res.success) succeed.push(res.success);
                if (res.error) errors.push(res.error);
            });
        } catch(error) {
            errors.push(error);
        }
    }
    return {succeed,errors};
}

export const runCloudFunction = async (name,data) =>{
    try {
        const response=await Parse.Cloud.run(name,data);
        //console.log("response",response);
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}