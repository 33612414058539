import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountPopover from './AccountPopover';
import Logo from '../logos/Logo';
import { styled } from '@mui/material/styles';
//import useAuth from '../../hooks/useAuth';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.offWhite.main,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.white.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  //const { user } = useAuth();

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{ minHeight: 64 }}
      >
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box display='flex' flexGrow={1}>
          <a href="https://nxt-cre.com">
          <Logo
            height="37"
          />
          </a>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            ml: 0
          }}
        />
        <Box >
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot >
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
