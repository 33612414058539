//import React from 'react';
import { Box, CircularProgress, Button, IconButton, Link, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { getItems } from '../../services/api/ModelAPI';

const DocumentModalLink = (props) => {
  const { children, name, type = "link", onClose = null, ...other } = props;
  const [isOpen, setOpen] = useState(false);
  const [doc, setDoc] = useState({});
  const [isLoading, setIsLoading] = useState(false);



  const loadDocument = async (e) => {
    e?.stopPropagation(); 
    e?.nativeEvent?.stopImmediatePropagation();
    e.preventDefault();
    setOpen(true);
    setIsLoading(true);
    if (!name) {
      setIsLoading(false);
      return;
    }
    try {
      let res = await getItems("document", 1, 1, null, { name: name });
      if (res.results.length == 1)
        setDoc({title:res.results[0].get("title"),content:res.results[0].get("content")});
      else
        setDoc({ title: "Error", content: "Document not found" });
      setIsLoading(false);
    } catch (err) {
      setDoc({ title: "Error", content: "Document not found" });
      setIsLoading(false);
    }
    return false;
  }


  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  }

  return (
    <>
      <Link
        onClick={loadDocument}
        {...other}
      >{children}</Link>
      <Dialog open={isOpen} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >
              {doc?.title}
            </Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers >
          {isLoading &&
            <CircularProgress />
          }
          {!isLoading && doc &&
            <div dangerouslySetInnerHTML={{ __html: doc?.content }}></div>
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )

};
export default DocumentModalLink;