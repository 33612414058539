import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import PaginatedTable from "../PaginatedTable/PaginatedTable";
import useAuth from "../../hooks/useAuth";
import { states } from '../../config';
import * as Yup from 'yup';
import EnchantedForm from "../EnchantedForm/EnchantedForm";
import toast from "react-hot-toast";
import { runCloudFunction } from "../../services/api/ModelAPI";

const MotivationDialog = (props) => {
    const {
        open,
        setOpen,
        balance = 0,
        activeTab = 'info'
    } = props;
    const minWithdraw = Number(process.env.REACT_APP_PRODUCER_MIN_WITHDRAW) || 500;

    const [tabValue, setTabValue] = useState(activeTab);
    const { user } = useAuth();

    const fields = [
        {
            label: 'Address_line1',
            name: 'address_line1',
            type: 'string',
            grid_props: { xs: 12, md: 12, pr: 1 },
            validation: Yup.string()
                .required('Address is required')
                .max(255)
                .nullable(),
        },
        {
            label: 'City',
            name: 'city',
            type: 'string',
            grid_props: { xs: 12, md: 4, pr: 1 },
            validation: Yup.string()
                .required('City is required')
                .max(255)
                .nullable(),
        },
        {
            label: 'State',
            name: 'state',
            type: 'choice',
            choices: states,
            grid_props: { xs: 12, md: 4, pr: 1 },
            validation: Yup.string()
                .required('State is required')
                .max(255)
                .nullable(),
        },
        {
            label: 'Zip',
            name: 'zip',
            type: 'number',
            grid_props: { xs: 12, md: 4, pr: 1 },
            validation: Yup.string()
                .required()
                .matches(/^[0-9]+$/, "Must be only digits")
                .min(5, 'Must be exactly 5 digits')
                .max(5, 'Must be exactly 5 digits')
        },
    ];

    const requestWithdrawal=async (values,{ setErrors, resetForm, setSubmitting })=>{
        console.log("withdrawa",values);
        try {
            await runCloudFunction("request_motivation_balance_withdraw",{details:values});
            toast.success("Witdrawal request succesfully submitted");
            setSubmitting(false);
            setOpen(false);
        } catch (err) {
            setSubmitting(false);
            toast.error(err?.error || err?.message || ('Error:'+JSON.stringify(err)));
        }
    }
    const currency_formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
    });

    useEffect(() => {
        console.log('useeff')
        setTabValue(activeTab);
    }, [activeTab]);


    const TabPanel = (props) => {
        const { children, value, index } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
            >
                {value === index &&
                    <>{children}</>
                }
            </div>
        );
    }

    return <Dialog open={open} maxWidth='md' onClose={() => { setOpen(false); }}>
        <DialogTitle>
            <Tabs value={tabValue} onChange={(e, v) => { setTabValue(v) }} sx={{ px: 0, mx: 0 }}>
                <Tab id="mtab-info" value={'info'} label="Info" />
                <Tab id="mtab-transactions" value={'transactions'} label="Transactions" />
                {/*<Tab id="mtab-withdraw" value={'withdraw'} label="Withdraw" />*/}
            </Tabs>
            <IconButton
                aria-label="close"
                onClick={() => { setOpen(false); }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 16,
                }}
            ><CloseIcon /></IconButton></DialogTitle>
        <DialogContent>
            <TabPanel value={tabValue} index={'info'}>
                For each MBI that completes the nxtCRE questionnaire, which is confirmed and accepted by nxtCRE, you will earn $100. This amount will be deposited into your account. Once your balance reaches $500, nxtCRE will send a Visa gift card in your name to the head of your production office. For every $100 you earn thereafter, nxtCRE will directly add that amount to your gift card. You will receive an email from nxtCRE when the gift card is sent and when each additional $100 is added.
            </TabPanel>
            <TabPanel value={tabValue} index={'transactions'}>
                <PaginatedTable
                    model={'motivation_transaction'}
                    filters={{ user }}
                    columns={[{ name: "createdAt", label: "Date", type: "date" }, { name: "description", label: "Description" }, { name: "amount", label: "Amount", type: 'currency' }, { name: "status", label: "Status", type: 'chip' }]}
                    allowView={false}
                    allowSelection={false}
                    allowSearch={false}
                    allowAdd={false}
                    allowEdit={false}
                    allowDelete={false}
                />
            </TabPanel>
            {/*<TabPanel value={tabValue} index={'withdraw'}>
                {balance < minWithdraw ?
                    <Alert severity="warning">The minimum amount to withdraw is ${minWithdraw}</Alert>
                    :
                    <>
                        <Alert severity="info">
                            Balance can be withdrawn using Gift card. Please enter your postal address to get your gift card.
                        </Alert>
                        <EnchantedForm
                            fields={fields}
                            okBtnText={'Request gift card'}
                            onSubmit={requestWithdrawal}
                        />
                    </>
                }
            </TabPanel>*/}
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={() => { setOpen(false); }}>
                Close
            </Button>
        </DialogActions>
    </Dialog>
}
export default MotivationDialog;