import { handleStatusCode } from './API';

const Parse = require('parse');

export const removeUser = async (organization,user)=>{
    try {
        const response=await Parse.Cloud.run("remove_user_from_organization",{organization_id:organization.id,user_id:user.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}
export const resendInvitationEmail = async (invitation)=>{
    try {
        const response=await Parse.Cloud.run("resend_invitation_email",{id:invitation.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getInvitation = async (code)=>{
    try {
        const response=await Parse.Cloud.run("get_invitation",{code:code});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const acceptInvitation = async (invitation,accept=true)=>{
    try {
        const response=await Parse.Cloud.run("accept_invitation_by_existed_user",{invitation:invitation.id,accept});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getManagerStatistics = async ()=>{
    try {
        const response=await Parse.Cloud.run("get_manager_statistics");
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}
export const getLenderStatistics = async (lender)=>{
    try {
        const response=await Parse.Cloud.run("get_lender_statistics",{organization_id:lender.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}
export const getManagerChartData = async (modelname,organization,bymonth=false)=>{
    try {
        const response=await Parse.Cloud.run("get_manager_chart_data",{modelname,organization_id:organization?.id,bymonth});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}