import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Input,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Checkbox,
  ListItemText,
  Divider,
  TextField,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';


const FilterDialog = (props) => {
  const {
    isOpen,
    setOpen,
    customFilters,
    setCustomFilters,
    available_filters
  } = props;

  const [conditions, setConditions] = useState({});
  const [values, setValues] = useState({});
  const [newFilters, setNewFilters] = useState({});
  const [filtersToDelete, setFiltersToDelete] = useState([]);
  const { currentOrganization } = useAuth();

  useEffect(() => {
    setNewFilters({});
    setFiltersToDelete([])
  }, [isOpen]);

  const setCondition = (name, value) => {
    let c = { ...conditions };
    c[name] = value;
    setConditions(c);
  }

  const setValue = (filter, value) => {
    let v = { ...values };
    switch(value){
      case "true":
          value=true;
          break;
      case "false":
            value=false;
            break;
      case "null":
          value=null;
          break;
      case "undefined":
          value=undefined;
          break;
      default:
          if (filter.type === 'number') value=parseInt(value);
    }
    v[filter.name] = value;
    setValues(v);
  }

  const addFilter = (filter) => {
    if ((!values[filter.name] || (filter.type == 'choice' && !values[filter.name].length)) && (!['exists', 'doesNotExist'].includes(conditions[filter.name]))) {
      toast.error("Please enter value");
      return;
    }
    let f = { ...newFilters }
    if (!conditions[filter.name]) conditions[filter.name] = 'equalTo';
    f[filter.name] = {};
    if (['exists', 'doesNotExist'].includes(conditions[filter.name])) f[filter.name][conditions[filter.name]] = '';
    else if (filter.type == 'choice') f[filter.name]['containedIn'] = values[filter.name];
    else if (conditions[filter.name] == 'equalTo') f[filter.name] = values[filter.name];
    else f[filter.name][conditions[filter.name]] = values[filter.name];
    setNewFilters(f);
  }

  const deleteFilter = (key) => {
    if (newFilters[key]) {
      let f = { ...newFilters }
      delete f[key];
      setNewFilters(f);
    } else {
      setFiltersToDelete([...filtersToDelete, key]);
    }

  }

  const handleOk = async () => {
    let currentFilters = { ...customFilters };
    filtersToDelete.forEach(key => {
      delete currentFilters[key];
    });
    setCustomFilters({ ...currentFilters, ...newFilters });
    setOpen(false);
  };

  return (
    <Dialog open={isOpen} style={{ minWidth: '520px' }} maxWidth={'xl'}>
      <DialogTitle>
        Filters
        <IconButton
          aria-label="close"
          onClick={() => { setOpen(false); }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <>
          {customFilters &&
            <>
              {Object.keys(customFilters).map(key => {
                if (filtersToDelete?.length && filtersToDelete.includes(key)) return '';
                let conds = { "equalTo": "==", "containsIn": "==", "lessThan": "<", "greaterThan": ">" };
                let cnd = (typeof customFilters[key] !== 'object') ? '==' : Object.keys(customFilters[key])[0];
                let label = key + " " + (conds[cnd] || cnd) + " " + (typeof customFilters[key] !== 'object' ? customFilters[key] : ((typeof customFilters[key][cnd] !== 'object') ? customFilters[key][cnd] : customFilters[key][cnd].join(", ")));

                return <Chip sx={{ m: 1 }} onDelete={() => deleteFilter(key)} color="primary" key={key} label={label} />
              })}
            </>
          }
          {newFilters &&
            <>
              {Object.keys(newFilters).map(key => {
                let conds = { "equalTo": "==", "containedIn": "==", "lessThan": "<", "greaterThan": ">" };
                let cnd = (typeof newFilters[key] !== 'object') ? '==' : Object.keys(newFilters[key])[0];
                let label = key + " " + (conds[cnd] || cnd) + " " + (typeof newFilters[key] !== 'object' ? newFilters[key] : ((typeof newFilters[key][cnd] !== 'object') ? newFilters[key][cnd] : newFilters[key][cnd].join(", ")));

                return <Chip sx={{ m: 1 }} onDelete={() => deleteFilter(key)} color="primary" key={key} label={label} />
              })}
            </>
          }
          {(customFilters || newFilters) &&
            <Divider sx={{ pt: 2 }} />
          }
          <Table>
            <TableBody>
              {available_filters?.map((filter, key) => {
                let disabled = false;
                if (Object.keys(newFilters).includes(filter.name) ||
                  (Object.keys(customFilters).includes(filter.name) && !filtersToDelete.includes(filter.name))) disabled = true;
                return (
                  <TableRow key={key}>
                    <TableCell><Chip label={filter.label || filter.name} sx={{ fontSize: '18px' }} /></TableCell>
                    <TableCell sx={{ minWidth: "110px", p: 0 }}>
                      <FormControl fullWidth size="small">
                        <Select
                          disabled={disabled}
                          value={conditions[filter.name] || 'equalTo'}
                          autoWidth
                          onChange={(e) => setCondition(filter.name, e.target.value)}>
                          {filter.options?.map((option, i) => (
                            <MenuItem key={'opt' + i} value={option}>{option}</MenuItem>
                          )) ||
                            <MenuItem key={'opt0'} value={'equalTo'}>{'equalTo'}</MenuItem>
                          }
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell >
                      {filter.type == 'choice' &&
                        <FormControl fullWidth size="small">
                          <Select multiple
                            value={values[filter.name] || []}
                            renderValue={(selected) => selected.join(', ')}
                            onChange={(e) => setValue(filter, e.target.value)}
                            autoWidth
                            disabled={disabled}
                          >
                            {filter.choices?.map((choice, i) => (
                              <MenuItem key={'choice' + i} value={choice.value}>
                                <Checkbox checked={values[filter.name]?.indexOf(choice.value) > -1} />
                                <ListItemText primary={choice.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      }
                      {filter.type != 'choice' && !['exists', 'doesNotExist'].includes(conditions[filter.name]) &&
                        <FormControl fullWidth size="small" variant={"standard"}>
                          <OutlinedInput disabled={disabled} type={filter.type} name="value" onChange={(e) => setValue(filter, e.target.value)} />
                        </FormControl>
                      }
                    </TableCell>
                    <TableCell sx={{ p: 0 }}>
                      <IconButton size="small" sx={((filter.type != 'choice' && values[filter.name]) || (filter.type == 'choice' && values[filter.name]?.length)) ? { background: "#00b0d3", color: "#fff" } : {}} onClick={() => addFilter(filter)} disabled={disabled}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {currentOrganization?.get("type") == 'MANAGER' &&
            <Formik
              initialValues={{
                custom_key: '',
                custom_condition: 'equalTo',
                custom_value: ''
              }}
              validationSchema={Yup
                .object()
                .shape({
                  custom_key: Yup.string().required('Required'),
                  custom_condition: Yup.string().required('Required'),
                  custom_value: Yup.string().required('Required'),
                })}
              //validateForm
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                let val=values.custom_value;
                if (val==='null') val=null;
                else if (val==='true') val=true;
                else if (val==='false') val=false;
                let f = { ...newFilters }
                f[values.custom_key] = {};
                if (['exists', 'doesNotExist'].includes(values.custom_condition)) f[values.custom_key][values.custom_condition] = '';
                else if (values.custom_condition == 'equalTo') f[values.custom_key] = val;
                else f[values.custom_key][values.custom_condition] = val;
                setNewFilters(f);
              }}
            >
              {({ isSubmitting, values, errors, touched, handleBlur, handleChange, }) => (
                <Form >
                  <Table>
                    <TableBody>
                      <TableRow key={'custom_filter'}>
                        <TableCell>
                          <TextField
                            name="custom_key"
                            fullWidth
                            size="small"
                            variant={"outlined"}
                            error={Boolean(touched.custom_key && errors.custom_key)}
                            helperText={touched.custom_key && errors.custom_key}
                            label="Key"
                            value={values.custom_key}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl
                            error={Boolean(touched.custom_condition && errors.custom_condition)}
                          >
                            <Select
                              fullWidth
                              size="small"
                              variant={"outlined"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.custom_condition}
                              name="custom_condition"
                            >
                              {['equalTo', 'notEqualTo','exists', 'doesNotExist'].map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>{touched.custom_condition && errors.custom_condition}</FormHelperText>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          {!['exists', 'doesNotExist'].includes(values.custom_condition) &&
                            <TextField
                              name="custom_value"
                              fullWidth
                              size="small"
                              variant={"outlined"}
                              error={Boolean(touched.custom_value && errors.custom_value)}
                              helperText={touched.custom_value && errors.custom_value}
                              label="Value"
                              value={values.custom_value}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                        </TableCell>
                        <TableCell sx={{ p: 0 }}>
                          <IconButton size="small" type="submit" disabled={isSubmitting} >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Form>
              )}

            </Formik>
          }
        </>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => { setOpen(false); }}>
          Cancel
        </Button>
        <Button onClick={handleOk} >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;

