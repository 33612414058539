import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { at } from 'lodash';

import { TextField } from '@mui/material';
import {DatePicker} from  '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function DatePickerField(props) {
  const { label, isDisabled, minDate, maxDate, readOnly, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        // YYYY-MM-dd
        setValue(date.toISOString().slice(0, 10));
      } catch (error) {
        console.log(error);
        setValue(date);
      }
    } else {
      setValue(date);
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={isDisabled}
          readOnly={readOnly}
          renderInput={(props) => <TextField
            {...rest}
            error={meta.touched && meta.error}
            helperText={_renderHelperText()}
            FormHelperTextProps={{ style: { color: 'red' } }}
            {...field}
            {...props}
          />}

          value={selectedDate}
          label={label}
          //invalidDateMessage={isError && error}
          onChange={_onChange}
          maxDate={maxDate}
          minDate={minDate}
        />
      </LocalizationProvider>
    </ >
  );
}
