import { Button,  Alert, AlertTitle } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { getItems} from '../../services/api/ModelAPI';
import {acceptInvitation} from '../../services/api/OrganizationAPI';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';

const InvitationForExistedUser = (props) => {
    const { showNoInvites = true } = props
    const { user,currentOrganization,changeOrganization,getUserData } = useAuth();
    const [invitations, setInvitations] = useState();
    const [isLoading, setLoading] = useState(true);

    const getInvitations = async () => {
        setLoading(true);
        try {
            const data = await getItems("invitation",10,1,null,{username:user.get('username'),used:false},'',[],["organization"]);
            setInvitations(data.results);
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        //console.log("Checking for invitations");
        getInvitations();
    }, []);


    const _acceptInvitation = async (invitation,accept=true) => {
        try {
            await acceptInvitation(invitation,accept);
            let is=[...invitations];
            is.splice(invitations.indexOf(invitation),1);
            setInvitations(is);
            toast.success("Successfully "+(accept?'accepted':'declined'));
            if (accept) {
                await getUserData();
                let org_index=user.organizations.findIndex(org=>org.id===invitation.get("organization").id);
                changeOrganization(org_index);
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            {invitations && invitations.length > 0 &&
                invitations.map(invitation => {
                    return (
                        <Alert severity="info" sx={{ m: 3 }} key={invitation.id}>
                            <AlertTitle>
                                You have been invited to join {invitation.get("organization")?.get("name") || invitation.get("organization_name")}
                            </AlertTitle>
                            <Button color="primary" variant="contained" onClick={()=>{_acceptInvitation(invitation);}}>Accept</Button>
                            <Button color="primary" variant="outlined"  onClick={()=>{_acceptInvitation(invitation,false);}} sx={{ml:2}}>Reject</Button>
                        </Alert>
                    )
                })
            }
            {!currentOrganization && showNoInvites && (!invitations || !invitations.length) && !isLoading &&
                <Alert severity="info" sx={{ m: 3 }}>
                    <AlertTitle>
                        Your account doesn't belong to any organization. Please contact your system administrator
                    </AlertTitle>
                </Alert>
            }
        </>
    );
};

export default InvitationForExistedUser;
