import React, { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { Dialog, DialogContent, DialogTitle, IconButton, Box, CircularProgress, DialogActions, Button, Checkbox, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import { read, utils } from 'xlsx';
import { createItems } from '../../services/api/ModelAPI';

const baseStyle = {
    flex: 1,
    display: 'flex',
    height: '10rem',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem 1rem',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const BulkImportDialog = (props) => {
    const { open, setOpen, onClose, model, cols, addl_form_values, ...rest } = props;
    const [file,setFile]=useState();
    const [data, setData] = useState();
    const [headers, setHeaders] = useState();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [isFirstRowHeader,setFirstRowHeader] =useState(true);

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length >= 1) {
            setFile(acceptedFiles[0]);
            processFile(acceptedFiles[0]);
        } else
            toast.error("Incorrect file format. Only XLS and CSV files are acceptable");
    }, [])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive
    } = useDropzone({
        onDrop, maxFiles: 1, accept: {
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.apple.numbers': ['.numbers'],
        }
    })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const processFile = (_file,_isFirstRowHeader=true) => {
        //console.log("cols",cols);
        setLoading(true);
        const reader = new FileReader();
        reader.onerror = () => {
            toast.error('file reading has failed');
            setLoading(false);
        }
        reader.onload = async () => {
            try {
                const wb = read(reader.result);
                const _data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { range: _isFirstRowHeader?0:-1 });
                console.log("RD",JSON.stringify(_data));
                let _headers = {};
                _data.forEach(row => {
                    Object.keys(row).forEach(i => {
                        if (!_headers[i]) _headers[i] = { col: guessColumn(i, row[i]) };
                    });
                });
                _data.forEach((row, key) => {
                    if (isRowValid(key, _data, _headers)) row.checked = true;
                });
                setHeaders(_headers);
                setData(_data);
            } catch (err) {
                toast.error(err.message || err.error || JSON.stringify(err));
                console.log(err);
            }
            setLoading(false);
        }
        reader.readAsArrayBuffer(_file);
    }

    const processImport = async () => {
        setSubmitting(true);
        let items = [];
        data.forEach(row => {
            if (row.checked) {
                let item = {...addl_form_values} || {};
                //check if addl_form_values has pointers
                Object.keys(item).forEach(i=>{
                    if (typeof item[i].get === 'function' && item[i].className) item[i]={"__type":"Pointer","className":item[i].className,"objectId":item[i].id}
                });

                Object.keys(row).forEach(key => {
                    if (headers[key]?.col?.name) //don't need to validate value since row can't be selected if invalid
                        item[headers[key].col.name] = normalize(row[key], headers[key].col.type);
                });
                items.push(item);
            }
        });
        const { succeed, errors } = await createItems(model, items);
        let message = <>
            {succeed.length}  records created. <br />
            {errors.length>0 &&
                <>
                    {errors.length} errors occured: <br />
                    {errors.map(error=>(
                        <>{error.message || error.error || JSON.stringify(error)} <br /> </>
                    ))}
                </>
            }
        </>
        setSubmitting(false);
        //console.log(message);
        toast.success(message);
        if (errors.length == 0) close();
        //TODO - check if first row is a header
    }

    const normalize = (value, type) => {
        switch (type) {
            case 'date':
            case 'datetime':
            case 'date_item':
            case 'date_obj':
                return new Date(value);
            case 'currency':
            case 'number':
                if (value === "") return null;
                return Number(value);
            //TODO other types?
            default:
                return value;
        }
    }

    const guessColumn = (colname, value) => {
        let col = cols.find(c => ((c.edit!==false) && [c.name?.toLowerCase(), c.label?.toLowerCase(),c.form_label?.toLowerCase()].includes(colname.toLowerCase())));
        if (!col) {
            col = cols.find(c => (c.edit!==false && !c.used && isCellValid(value, c.type,c.required)));
        }
        if (col) col.used = true;//not sure if it works
        return col;
    }

    const changeColumn = (key, value) => {
        let _headers = { ...headers };
        let current_header_index = Object.keys(_headers).find(i => i === key);
        if (value === 'unused') {
            let col = cols.find(c => c.name === _headers[current_header_index].col.name);
            _headers[current_header_index].col = null;
            col.used = false;
        } else {
            let previous_header_index = Object.keys(_headers).find(i => _headers[i].col?.name === value);
            if (previous_header_index) _headers[previous_header_index].col = null;
            let col = cols.find(c => c.name === value);
            if (current_header_index) {
                _headers[current_header_index].col = col;
                col.used = true;
            }
        }
        setHeaders(_headers);
    }

    const selectHeaderView = (key) => {
        let header = headers[key];
        let dv = header?.col?.name || 'unused';
        return <TextField
            select
            value={dv}
            size="small"
            fullWidth
            hiddenLabel
            variant="standard"
            InputProps={{ sx: { fontSize: '11px', fontWeight: 'bold', textAlign: 'left' } }}
            onChange={(e) => changeColumn(key, e.target.value)}
        >
            {cols?.filter(c => (c.edit!==false || c.add))?.map((col, i) => (
                <MenuItem key={i} value={col.name} sx={{ fontSize: '11px' }}>{col.form_label || col.label || col.name}</MenuItem>
            ))}
            <MenuItem key={'unused'} value="unused" sx={{ fontSize: '11px' }}>Unused</MenuItem>
        </TextField>
    }

    const isCellValid = (value, type,required) => {
        if ((value==='' || value === null || value===undefined) && (!required)) return true;
        switch (type) {
            case 'email':
                return value.toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            case 'date':
            case 'datetime':
            case 'date_item':
            case 'date_obj':
                return (new Date(value) !== "Invalid Date") && !isNaN(new Date(value));
            case 'boolean':
            case 'checkbox':
                return [true, false, 'true', 'false'].includes(value);
            case 'currency':
            case 'number':
                return !isNaN(value);
            case 'file':
            case 'File':
            case 'imagefile':
            case 'image':
                return false;//TODO later add ability to import file by URLS?
            case 'array':
                return false;//TODO later add ability to import file by URLS?
            case 'object':
                return false;//TODO later add ability to import file by URLS?
            case 'Pointer':
            case 'PointerChoice':
            case 'Relation':
            case 'GeoPoint':
            case 'Polygon':
            case 'component':
            case 'group':
            case 'radio':
            case 'preview':
                return false;//can't read this kind of data from excel
            case 'choice':
            case 'autocomplete':
            case 'editor':
            case 'html':
                return true;//meaning simple string?
            default:
                return true;//meaning simple string?
        }
    }

    const isRowValid = (index, _data = data, _headers = headers) => {
        let valid = true;
        Object.keys(_headers).every(key => {
            if ((_headers[key]?.col?.required && !_data[index][key]) || (_headers[key]?.col?.type && !isCellValid(_data[index][key], _headers[key]?.col?.type, _headers[key]?.col?.required))) {
                valid = false;
                return false;
            }
            return true;
        });
        if (valid) cols.filter(c => (c.required && c.edit!=='false' && !c.used))?.every(col => {
            if (col.required && !_data[index][col.name]) {
                valid = false;
                return false;
            }
            return true;
        });
        return valid;
    }

    const close = () => {
        if (submitting) {
            toast.error("Please wait while data proceeding...");
        } else {
            setOpen(false);
            setData(null);
            if (onClose) onClose();
        }
    }

    const handleSelectOne = (key) => {
        let _data = [...data];
        _data[key].checked = !_data[key].checked;
        setData(_data);
    }

    const handleSelectAll = (e) => {
        let _data = [...data];
        if (!e.target.checked) _data.forEach(row => row.checked = false);
        else _data.forEach((row, i) => { if (isRowValid(i)) row.checked = true; });
        setData(_data);
    }

    const handleFirstRowChange=(e)=>{
        setFirstRowHeader(e.target.checked);
        cols.forEach(col=>col.used=false);
        processFile(file,e.target.checked);
    }

    const selected = data?.filter(c => c.checked);
    const suitable = data?.filter((c, i) => isRowValid(i));

    return (
        <Dialog
            open={open}
            onClose={close}
            PaperProps={{
                style: {
                    minWidth: '50%',
                    maxWidth: '90%',
                    minHeight: '300px',
                },
            }}
        >
            <DialogTitle>
                Bulk import (XLS, CSV)
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
            <DialogContent>
                {loading &&
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                }
                {data && !loading &&
                    <>
                        <Box sx={{textAlign:'right'}}>
                            First row contains headers <Checkbox label="First row contains headers" size="small" checked={isFirstRowHeader} onChange={handleFirstRowChange}/>
                        </Box>
                        <table style={{ border: '1px solid #ccc', borderCollapse: 'collapse' }} >
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #ccc', padding: '5px' }} key="s_all">
                                        <Checkbox
                                            checked={selected?.length === suitable?.length && selected?.length > 0}
                                            indeterminate={selected?.length > 0 && selected?.length < suitable?.length}
                                            onChange={handleSelectAll}
                                            size="small" />
                                    </th>
                                    {Object.keys(headers).map(key => (
                                        <th key={"s_" + key} style={{ border: '1px solid #ccc', padding: '5px' }}>
                                            {selectHeaderView(key)}
                                        </th>
                                    ))}
                                    {cols.filter(c => (c.required && c.edit!==false && !c.used))?.map((col, key) => (
                                        <th key={"s_unused_" + key} style={{ fontSize: '12px', border: '1px solid #ccc', padding: '5px' }}>{col.label || col.name}*</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, key) => {
                                    let rowValid = isRowValid(key);
                                    return <tr key={"row_" + key} {...!rowValid ? { style: { color: '#ccc' } } : {}}>
                                        <td key="col_ch" style={{ border: '1px solid #ccc', padding: '0 5px' }}>
                                            <Checkbox size="small"
                                                checked={row.checked && rowValid}
                                                disabled={!rowValid}
                                                onChange={() => handleSelectOne(key)}
                                                {...!rowValid ? { label: "Invalid data" } : {}} />
                                        </td>
                                        {Object.keys(headers).map(i => (
                                            <td key={"col_" + i}
                                                style={{
                                                    border: '1px solid #ccc',
                                                    padding: '5px',
                                                    ...!isCellValid(row[i], headers[i].col?.type, headers[i]?.col?.required) ? { backgroundColor: '#ff0000ab' } : {},
                                                    ...!headers[i].col ? { color: '#ccc' } : {}
                                                }}
                                            >
                                                {row[i]?.toString()}
                                            </td>
                                        ))}
                                        {cols.filter(c => (c.required && c.edit!==false && !c.used))?.map((col, key) => (
                                            <td key={"col_ch_unused_" + key} style={{ border: '1px solid #ccc', padding: '5px', backgroundColor: '#ff0000ab' }} title="Required column">&nbsp;</td>
                                        ))}
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                    </>
                }
                {!data && !loading &&
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the file here ...</p> :
                                <p>Drag 'n' drop file here, or click to select file</p>
                        }
                    </div>
                }
            </DialogContent>
            <DialogActions sx={{ px: 2.5 }}>
                {data?.length &&
                    <Box sx={{ pr: 5 }}>
                        {selected?.length || 0} records selected
                    </Box>
                }
                <Button color="primary" variant='outlined' onClick={close} disabled={submitting}>Cancel</Button>
                {data && !loading &&
                    <Button color="primary" variant="contained" disabled={selected.length == 0 || submitting} onClick={processImport}>
                        Import selected
                        {submitting &&
                            <CircularProgress size={14} />
                        }
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}
export default BulkImportDialog;