
import { IconButton, Avatar, Badge, Box, Divider, Card, CardHeader, CardContent, CardActions, Typography, Hidden } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useState, forwardRef, useImperativeHandle, useEffect, memo } from 'react';
import ChatThreads from './ChatThreads';
import ChatMessages from './ChatMessages';
import ChatMessageAdd from './ChatMessageAdd';
import { useTheme } from '@mui/material/styles';
import useAuth from '../../hooks/useAuth';
import { getItems} from '../../services/api/ModelAPI';
import { isLiveQuery } from '../../services/api/API';
import { markThreadAsReaded } from '../../services/api/ChatAPI';
import LetterOfIntentDialog from '../deal/LetterOfIntentDialog';
import DealInfoDialog from '../deal/DealInfoDialog';

const DLG_WIDTH = 280;

const ChatModalWindow = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(true);
  const [closed, setClosed] = useState(true);
  const [hasUnread, setHasUnread] = useState(false);
  const [openDialogs, setOpenDialogs] = useState([]);
  const [organizationDocuments, setOrganizationDocuments] = useState([]);
  const [isLoiDialogOpen, setLoiDialogOpen] = useState(false);
  const [isDealInfoDialogOpen, setDealInfoDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const { fullScreen, setFullScreen } = props;
  const { user, currentOrganization } = useAuth();
  const theme = useTheme();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const rootstyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    ...fullScreen ? {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      flexDirection: 'row-reverse',
      width: '100%',
      "& > .chat-window": { width: "70%" }
    } : {
      width: 'auto',
      maxHeight: "90%",
      position: 'fixed',
      bottom: 0,
      right: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        ...closed?{}:{height:'100%'},
        "& > .chat-window": {
          position: 'absolute',
          zIndex: 1001,
          minHeight: '100%'
        }
      },

      [theme.breakpoints.up('md')]: {
        maxHeight: "50%",
      },
    }
  };

  const chatstyle = {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    ...fullScreen ? {
      width: '30%',
      height: '100%',
      //justifyContent: 'flex-start',
    } : {
      width: DLG_WIDTH + 'px',
      maxHeight: "100%",
      marginLeft: '1em',
      width: DLG_WIDTH + 'px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: '0',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: "50%",
      },
    }
  }

  const cardstyle = {
    display: 'flex',
    flexDirection: 'column',
    pb: 0,
    ...fullScreen ? {
      borderRadius: 0,
      height: '100%',
    } : {
      borderBottomRightRadius: '0 !important', borderBottomLeftRadius: '0 !important',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1
      },
    }
  };



  useImperativeHandle(ref, () => ({
    openChat(match, go_fullscreen = false) {
      if (match) onSelect(match);
      if (!visible) setVisible(true);
      if (closed) setClosed(false);
      if (go_fullscreen && !isMobile) toggleFullscreen();
    }
  }));

  const loadOrganizationDocuments = async () => {
    try {
      const data = await getItems("organization_document", 50, 1, '', { organization: currentOrganization });
      setOrganizationDocuments(data.results);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    ///console.log("Loading documents");
    loadOrganizationDocuments();
  }, []);


  const onSelect = (match) => {
    if (!match.id) match.id = match.objectId;
    //console.log("selecting match "+match.id);
    let selected = openDialogs.find(dlg => dlg.match?.id === match?.id);
    if (!selected) {
      const max_dlgs_per_screen = window.innerWidth / DLG_WIDTH - 1;
      focusDialog();
      let _openDialogs = [...openDialogs];
      if (openDialogs.length >= max_dlgs_per_screen) {
        _openDialogs.splice(_openDialogs.length - 1, 1);//remove one
      }
      setOpenDialogs([..._openDialogs, { match, collapsed: false, addedMessages: [], focused: true }]);
      markMessagesAsReaded(match);
    } else {
      focusDialog(selected);
    }
  }

  const focusDialog = (dlg, uncollapse = false) => {
    markMessagesAsReaded(dlg?.match);
    let _openDialogs = [...openDialogs];
    _openDialogs.forEach(d => {
      if (d === dlg) {
        d.focused = true;
        dlg.hasUnread = false;
        if (uncollapse) d.collapsed = false;
      }
      else d.focused = false;
    })
    setOpenDialogs(_openDialogs);
  }

  const markMessagesAsReaded = (match) => {
    if (!match) return;
    markThreadAsReaded(match.id, currentOrganization.id);
  }

  const closeDialog = (e, dlg) => {
    let _openDialogs = [...openDialogs];
    _openDialogs.splice(_openDialogs.indexOf(dlg), 1);
    setOpenDialogs(_openDialogs);
    e?.stopPropagation();
    e?.nativeEvent?.stopImmediatePropagation();
  }

  const collapseDialog = (e, dlg) => {
    if ((!dlg.focused && !dlg.collapsed) || fullScreen) return;
    if (isMobile) return;//don't allow to collapse on mobile
    dlg.collapsed = !dlg.collapsed;
    let _openDialogs = [...openDialogs];
    _openDialogs[_openDialogs.indexOf(dlg)] = dlg;
    setOpenDialogs(_openDialogs);
  }

  const handleSendMessage = (dlg, message) => {
    if (isLiveQuery("message")) return;
    dlg.addedMessages.push(message);
    let _openDialogs = [...openDialogs];
    _openDialogs[_openDialogs.indexOf(dlg)] = dlg;
    setOpenDialogs(_openDialogs);
  }

  const handleNewMessageReceived = (dlg, message) => {
    if (dlg.focused) markMessagesAsReaded(dlg.match);
    else {
      dlg.hasUnread = true;
      let _openDialogs = [...openDialogs];
      _openDialogs[_openDialogs.indexOf(dlg)] = dlg;
      setOpenDialogs(_openDialogs);
      //TODO flash window
      //TODO add unread sign to threads component (or it should have his own event about updating event!)
    }
  }

  const onAttachLoiClick=(match)=>{
    setDialogData(match);
    setLoiDialogOpen(true);
  }

  const showDealInfoDialog=(match)=>{
    setDialogData(match);
    setDealInfoDialogOpen(true);
  }

  const toggleFullscreen = (e) => {
    focusDialog(openDialogs?.find(d => d.focused === true), true);//uncollapse focused
    setFullScreen(!fullScreen);
    e?.stopPropagation();
    e?.nativeEvent?.stopImmediatePropagation();
    setClosed(false);
  }

  const dialogTitle = (dlg) => {
    let listing_organization = dlg.match.listing_organization || dlg.match.get("listing_organization");
    return <>
                <Badge color="success" variant="dot" invisible={!dlg.hasUnread} fontSize="small" sx={{ mr:1 }} />
      {listing_organization.id === currentOrganization.id ?
        <span style={dlg.hasUnread?{fontWeight:"bold"}:{}}>{ "MBI #"+(dlg.match.preference?.id || dlg.match.get("preference")?.id || dlg.match.preference_organization?.id || dlg.match.get("preference_organization")?.id  || '') }</span>
        :
        <span style={dlg.hasUnread?{fontWeight:"bold"}:{}}>{ "Listing #" + (dlg.match.listing?.id || dlg.match.get("listing")?.id) }</span>
      }
    </>;
  }

  if (!visible && !fullScreen) return '';

  return <Box sx={rootstyle}>
    {openDialogs?.filter(dlg => dlg.focused || !fullScreen).map((dlg, key) => (
      <Box key={"msgr_" + key} sx={chatstyle} className="chat-window">
        <Card sx={cardstyle} onClick={() => { focusDialog(dlg) }}>
          <CardHeader
            avatar={
              <Avatar sx={{ width: 32, height: 32, background: dlg.avatarBg, color: '#fff' }} />
            }
            action={
              <>
                <Hidden smDown>
                  <IconButton aria-label="Full screen" onClick={toggleFullscreen}>
                    {fullScreen ? <CloseFullscreenIcon sx={{ fontSize: 18 }} /> : <OpenInFullIcon sx={{ fontSize: 18 }} />}
                  </IconButton>
                </Hidden>
                {!fullScreen &&
                  <IconButton aria-label="Close" onClick={(e) => closeDialog(e, dlg)}>
                    <CloseIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                }
              </>
            }
            onClick={(e) => { collapseDialog(e, dlg) }}
            title={dialogTitle(dlg)}
            titleTypographyProps={{ variant: 'body2' }}
            sx={{
              ...fullScreen ? { pt: 3, pb: 3 } : { pt: 1, pb: 1 },
              ...(dlg.focused && !fullScreen) ? { backgroundColor: 'primary.main' } : {}
            }}
          />
          <Divider />
          <CardContent sx={{ backgroundColor: theme.palette.background.default, display: dlg.collapsed ? 'none' : 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', p: '0 !important' }} >
            <ChatMessages id={"msgr_body_" + key}
              dialog={dlg}
              onNewMessageRecieved={handleNewMessageReceived}
              addedMessages={[...dlg.addedMessages]}
              showDealInfoDialog={showDealInfoDialog}
              user={user}
              currentOrganization={currentOrganization}

            />
          </CardContent>
          <Divider />
          {!dlg.collapsed &&
            <CardActions sx={{ p: 0 }}>
              <ChatMessageAdd
                onSend={(message) => { handleSendMessage(dlg, message) }}
                onAttachLoiClick={onAttachLoiClick}
                match={dlg.match}
                recent_docs={organizationDocuments}
                id={"msgr_send_" + key}
                size="small" />
            </CardActions>
          }
        </Card>
      </Box>
    ))}
    {fullScreen && openDialogs?.filter(dlg => dlg.focused || !fullScreen) == 0 &&
      <Box sx={chatstyle} className="chat-window">
        <Card sx={cardstyle}>
          <CardHeader action={
            <>
              <IconButton aria-label="Full screen" onClick={toggleFullscreen}>
                {fullScreen ? <CloseFullscreenIcon sx={{ fontSize: 18 }} /> : <OpenInFullIcon sx={{ fontSize: 18 }} />}
              </IconButton>
            </>
          }
            sx={{ backgroundColor: theme.palette.background.default }}
          />
          <CardContent sx={{ flexGrow: 1, backgroundColor: theme.palette.background.default }} />
        </Card>
      </Box>
    }
    <Box sx={chatstyle}>
      <Card sx={cardstyle}>
        {fullScreen ?
          <Typography
            color="textPrimary"
            variant="h4"
            sx={{ p: 2 }}
          >
            Chats
          </Typography>
          :
          <CardHeader
            avatar={
              <Badge color="success" variant="dot" invisible={!hasUnread} fontSize="small" sx={{ mt: 0.2 }}>
                <MailIcon color="primary" fontSize="small" />
              </Badge>
            }
            action={
              <>
                {!closed &&
                  <Hidden smDown>
                    <IconButton aria-label="Full screen" onClick={toggleFullscreen}>
                      {fullScreen ? <CloseFullscreenIcon sx={{ fontSize: 18 }} /> : <OpenInFullIcon sx={{ fontSize: 18 }} />}
                    </IconButton>
                  </Hidden>
                }
                <IconButton aria-label="Toggle" onClick={() => setClosed(!closed)}>
                  {closed ? <ExpandLessIcon sx={{ fontSize: 18 }} /> : <ExpandMoreIcon sx={{ fontSize: 18 }} />}
                </IconButton>
              </>
            }
            onClick={() => setClosed(!closed)}
            title="Messaging"
            titleTypographyProps={{ variant: 'body2' }}
            sx={{ pt: 1, pb: 1 }}
          />
        }
        <CardContent sx={{ display: closed ? 'none' : 'flex', flexDirection: 'column', overflow: 'hidden', p: '0 !important' }} >
          {!fullScreen &&
            <Divider />
          }
          <ChatThreads organization={currentOrganization} onSelect={onSelect} setVisible={setVisible} setHasUnread={setHasUnread} />
        </CardContent>
      </Card>
    </Box>
    <LetterOfIntentDialog 
      open={isLoiDialogOpen}
      setOpen={setLoiDialogOpen}
      match={dialogData}
    />
    <DealInfoDialog 
      open={isDealInfoDialogOpen}
      setOpen={setDealInfoDialogOpen}
      deal={dialogData?.get("deal")}
      match={dialogData}
    />
  </Box>
})
export default ChatModalWindow