
import { MenuItem, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useState } from 'react';
import toast from 'react-hot-toast';

const Export2CsvMenuItem = (props) => {
  const { buttonText = "Export as CSV", getDataForExport, ...rest } = props;
  const [isLoading, setLoading] = useState(false);


  const exportData = async () => {
    if (isLoading) {
      toast.error("Please wait while file is preparing ...");
      return;
    }
    setLoading(true);
    let data = await getDataForExport();
    try {
      var blob = new Blob([data.map(e => e.join("\t")).join("\r\n")], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      link.setAttribute("href", URL.createObjectURL(blob));
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF
      link.click(); // This will download the data file named "my_data.csv".
    } catch(err) {
      console.log("Export error",err);
      toast.error(err.message||"Error");
    }
    setLoading(false);
  }



  return (
    <MenuItem onClick={exportData} >
      <ListItemIcon>
        {isLoading ?
          <CircularProgress size={18} />
          :
          <FileDownloadIcon fontSize="small" />
        }
      </ListItemIcon>
      <ListItemText>Export as CSV</ListItemText>
    </MenuItem>
  );

}

export default Export2CsvMenuItem;