import { useState, useRef, cloneElement, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { styled } from '@mui/material/styles';
import useAuth from "../../hooks/useAuth";
import EmailNotVerified from '../auth/EmailNotVerified';
import UnassociatedQuestionnaires from '../../pages/dashboard/UnassociatedQuestionnaires';
import InvitationForExistedUser from './InvitationForExistedUser';
import { Box } from '@mui/system';
import { Button,Alert,AlertTitle } from '@mui/material';
import ChatModalWindow from '../chatModal/ChatModalWindow';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { saveError } from '../../services/api/API';
import LimitedUserPopup from '../auth/LimitedUserPopup';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '64px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '280px'
    }
  };
});

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  position: 'relative',
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = (props) => {
  const { children } = props;
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  //const [chatModalClosed,setChatModalClosed]=useState(true);
  const [chatModalFullScreen, setChatModalFullScreen] = useState(true);
  const containerRef = useRef(null);
  const chatRef = useRef();
  const { currentOrganization, impersonated, user, logoutImpersonatedUser } = useAuth();
  const location = useLocation();



  const _logoutImpersonatedUser = async () => {
    await logoutImpersonatedUser();
    window.close();
  }


  useEffect(() => {
    //setChatModalClosed(true);
    setChatModalFullScreen(false);
  }, [location.pathname]);

  function ErrorFallback({ error, resetErrorBoundary }) {
    if (error?.message?.includes("Loading chunk")) {
      if (!localStorage.getItem("reloaded_after_chunk_error")) {
        localStorage.setItem("reloaded_after_chunk_error",true);
        window.location.reload(true);
        return;
      } else {
        localStorage.removeItem("reloaded_after_chunk_error");
        saveError(error.message,null,'react',user);
        return (
          <Alert severity="error" sx={{ m: 3 }}>
            <AlertTitle>
              <p>The App was updated</p>
              <pre>Please reload the page</pre>
              <Button color="primary" variant="contained" onClick={()=>{window.location.reload(true);}}>Reload</Button>
            </AlertTitle>
          </Alert>
        )
      }
    }
    saveError(error.message,null,'react',user);
    return (
      <Alert severity="error" sx={{ m: 3 }}>
        <AlertTitle>
          <p>Something went wrong:</p>
          <pre>{error.message}</pre>
          <Button color="primary" variant="contained" onClick={resetErrorBoundary}>Try again</Button>
        </AlertTitle>
      </Alert>
    )
  }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)} />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        chatRef={chatRef}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent ref={containerRef}>
            {impersonated &&
              <Box fullWidth sx={{ background: "#ff0000", color: "#fff", p: 1 }} >
                Logged in as {user.get("username")}
                <Button onClick={_logoutImpersonatedUser}>Logout</Button>
              </Box>
            }
            <EmailNotVerified /> {/* TODO move to main dashboard after all type of users will have one */}
            <InvitationForExistedUser /> {/* TODO move to main dashboard after all type of users will have one */}
            {currentOrganization?.get("type") == 'BUYER' && 1 == 2 &&
              <UnassociatedQuestionnaires />
            } {/* move to main dashboard after buyer actually will have one */}

            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                // reset the state of your app so the error doesn't happen again
              }}
            >
              {children || <Outlet context={[{ containerRef, chatRef }]} />}

            </ErrorBoundary>

          </DashboardLayoutContent>
          <ChatModalWindow fullScreen={chatModalFullScreen} setFullScreen={setChatModalFullScreen} ref={chatRef} />
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
      {user.get("limited") &&
        <LimitedUserPopup />
      }
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;