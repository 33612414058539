import { handleStatusCode } from './API';

const Parse = require('parse');

export const getPropertyTypes = async ()=>{
    try {
        const response=await Parse.Cloud.run("get_property_types");
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getStatistic = async ()=>{
    try {
        const response=await Parse.Cloud.run("get_listing_statistic");
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getListingUserInfo = async (code)=>{
    try {
        const response=await Parse.Cloud.run("get_listing_user_info",{code:code});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const uploadFile = async (file,name)=>{
    try {
        const parseFile = new Parse.File(name, file);
        const response=await parseFile.save();
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const submitMatch = async (match,status)=>{
    try {
        const response=await Parse.Cloud.run("submit_match",{match:match.id,status});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const changeDealStatus = async (deal,status,note)=>{
    try {
        const response=await Parse.Cloud.run("change_deal_status",{deal:deal.id,status,note});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getImportInfo = async (listing_code) =>{
    try {
        const response=await Parse.Cloud.run("get_import_listings_info",{listing_code});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const importListingsByCode = async (listing_code,organization) =>{
    try {
        const response=await Parse.Cloud.run("attach_external_listing_to_organization",{listing_code,organization_id:organization.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const deleteListingsByCode = async (listing_code,...rest) =>{
    try {
        const response=await Parse.Cloud.run("remove_listing_by_code",{listing_code,...rest});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getSecretRegistrationLink = async (obj)=>{
    try {
        var response;
        if (obj.className=="match")
            response = await Parse.Cloud.run("get_match_registration_link",{match_id:obj.id});
        else 
            response = await Parse.Cloud.run("get_listing_registration_link",{listing_id:obj.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getLimitedSignUpLink = async (user)=>{
    try {
        var response = await Parse.Cloud.run("get_limited_signup_link",{user_id:user.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const getExternalMatchInfo = async (code) =>{
    try {
        const response=await Parse.Cloud.run("get_external_match_info",{code});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const submitMatchByCode = async (code,organization) =>{
    try {
        const response=await Parse.Cloud.run("submit_match_by_code",{code,organization_id:organization?.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const refetchSyndicatedListing = async (listing)=>{
    try {
        const response=await Parse.Cloud.run("refetch_syndicated_listing",{listing_id:listing.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}

export const reMatchMSA = async (listing)=>{
    try {
        const response=await Parse.Cloud.run("rematch_msa",{listing_id:listing.id});
        return response;
    } catch (error) {
        return handleStatusCode(error);
    }
}