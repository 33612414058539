import { Card, CardContent, Typography, Link, Skeleton } from "@mui/material"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MotivationDialog from "./MotivationDialog";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { runCloudFunction } from "../../services/api/ModelAPI";
import toast from "react-hot-toast";

const MotivationBar = (props) => {

    const [isDialogOpen, setDialogOpen] = useState(false);
    const [dialogTab, setDialogTab]=useState();
    const [loading,setLoading]=useState(true);
    const [balance,setBalance]=useState(0);
    const {user}=useAuth();

    const currency_formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
    });

    const loadBalance=async ()=>{
        setLoading(true);
        try {
            let b=await runCloudFunction("get_motivation_balance");
            setBalance(b);
        } catch (err) {
            toast.error(err.error || err.message || "Can't update balance");
        }
        setLoading(false);
    }

    useEffect(() => {
        loadBalance();
    }, []);

    useEffect(() => {
        loadBalance();
    }, [isDialogOpen]);

    const showHelp = () => {
        setDialogTab('info');
        setDialogOpen(true);
    }

    const showWithdrawDialog = () => {
        setDialogTab('withdraw');
        setDialogOpen(true);
    }

    const showTransactions = () => {
        setDialogTab('transactions');
        setDialogOpen(true);
    }

    return <><Card style={{ backgroundColor: "#d7ffeb", border: "none", boxShadow: "none" }} >
        <CardContent sx={{ textAlign: 'right' }}>
            <Typography color="primary" variant="subtitle" >Balance <HelpOutlineIcon fontSize="small" color='disabled' onClick={showHelp} sx={{ cursor: 'pointer' }} /></Typography>
            <Typography color="primary" variant="h2" sx={{ cursor: 'pointer' }} onClick={showTransactions} >
                {loading?
                    <Skeleton />
                    :
                    currency_formatter.format(balance)
                }                
            </Typography>
            {/*
            <Link
                component="button"
                variant="body2"
                onClick={showWithdrawDialog}
            >
                Withdraw
            </Link>*/}
        </CardContent>
    </Card>
        <MotivationDialog open={isDialogOpen} setOpen={setDialogOpen} activeTab={dialogTab} balance={balance} />
    </>
}
export default MotivationBar;