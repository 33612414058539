import { getSecretRegistrationLink } from "../../services/api/ListingAPI";
import { Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

export const custom_functions = {
    matchRegistrationLinkField: async (match) => {
        if (match.get("status") !== 'P' || match.get("listing_organization") || (match.get("expiration_date") <= new Date())) return null;
        try {
            let link = await getSecretRegistrationLink(match);
            return <>
                <Typography variant='caption' display='inline' sx={{"fontWeight": "bold"}}>Registration link:</Typography>
                <CopyToClipboard
                    text={link}
                    onCopy={() => toast.success('Link copied')}>
                    <ContentCopyIcon sx={{ cursor: "pointer", width: "0.8em" }} title="Click to copy" />
                </CopyToClipboard>
            </>;
        } catch (err) {
            return <Typography variant='error' display='inline' >Render error</Typography>;
        }
    }
    //PLACE OTHER CUSTON FUNCTION TO RENDER COMPLICATED DATA
}