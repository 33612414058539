import { useRef, useState } from 'react';
import { Box, IconButton, TextField, Tooltip, List, ListItem, ListItemText, ListItemAvatar, CircularProgress, ListSubheader, Popover, Menu, MenuItem, ListItemIcon } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import useAuth from '../../hooks/useAuth';
import { uploadFile } from '../../services/api/ListingAPI';
import { createItem } from '../../services/api/ModelAPI';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ChatMessageAdd = (props) => {
    const { disabled, onSend, match, size = "normal", recent_docs, onAttachLoiClick, ...other } = props;
    const fileInputRef = useRef(null);
    const { currentOrganization } = useAuth();
    const [body, setBody] = useState('');
    const [isFileLoading, setFileLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [showTemplates, setShowTemplates] = useState(false);
    const [attachmentsMenuAnchorEl, setAttachmentsMenuAnchorEl] = useState(null);
    const attachmentsMenuOpen = Boolean(attachmentsMenuAnchorEl);
    const [attachmentsSubMenuAnchorEl, setAttachmentsSubMenuAnchorEl] = useState(null);
    const attachmentsSubMenuOpen = Boolean(attachmentsSubMenuAnchorEl);
    const [attachmentType, setAttachmentType] = useState(false);



    const available_attachments = [
        ...(currentOrganization?.get("type") == 'BUYER' && (!match.get("deal") || !['LS','LA'].includes(match.get("deal").get("status")))) ? [
            { name: 'loi', label: 'Letter of Intent' } 
        ] : [],
        ...['BROKER', 'SELLER'].includes(currentOrganization?.get("type")) ? [
            { name: 'memorandum', label: 'Offering memorandum' },
            { name: 'walk_through', label: 'Virtual walk through' },
            { name: 'rent_roll', label: 'Rent roll' },
            { name: 'operating_statement', label: 'Operating statement' },
            { name: 'inspection_report', label: 'Inspection report' },
            { name: 'market_study', label: 'Market comps/study' },
        ] : [],
        { name: 'other', label: 'Other document' },
    ];

    const templates = [
        "Would like to schedule a property walkthrough, can you provide me with a few times over the next week?",
        "Would like to issue a Letter of Intent.  To whom should it be addressed?",
        "We have a number of questions that would best be handled via a call, can you please pass your  contact details?"
    ];


    const handleAttachClick = (e) => {
            setAttachmentsMenuAnchorEl(e.currentTarget);
            //fileInputRef.current.click();
    };

    const attachFile = (type) => {
        if (type.name=='loi') {
            if (onAttachLoiClick) onAttachLoiClick(match);
            setAttachmentsMenuAnchorEl(null);
        } else {
            setAttachmentType(type);
            fileInputRef.current.click();
        }
    }

    const attachRecent = (doc) =>{
        setAttachmentsSubMenuAnchorEl(null);
        setAttachmentsMenuAnchorEl(null);
        setAttachments([...attachments, { name: doc.get("name"), file: doc, url: doc.get("file").url() }]);
    }
    const handleFileChoosed = async (e) => {
        for (var i = 0; i < e.target.files.length; i++) {
            await addFile(e.target.files[i]); //Can't really use multiple files since setAttachments is async with no callback
        }
    }

    const showRecentDocs = (e, type) => {
        setAttachmentType(type);
        setAttachmentsSubMenuAnchorEl(e.currentTarget);
    }

    const showTemplatesIfNeed = () => {
        if (currentOrganization?.get("type") == "BUYER")
            setShowTemplates(true);
    }


    const addFile = async (filedata) => {
        setAttachmentsSubMenuAnchorEl(null);
        setAttachmentsMenuAnchorEl(null);
        setFileLoading(true);
        const file = await uploadFile(filedata, filedata.name);
        let attachment = await createItem("organization_document", { type: attachmentType.name, file, organization: currentOrganization, name: filedata.name });
        setAttachments([...attachments, { name: (attachmentType.name !== 'other' ? attachmentType.label : filedata.name), file: attachment, url: file.url() }]);
        setFileLoading(false);
    }

    const removeFile = (key) => {
        setAttachments(attachments.filter((_, i) => i !== key));
    }

    const handleChange = (event) => {
        setBody(event.target.value);
    };

    const handleSend = async (e) => {
        e.preventDefault();
        if (!body) return;
        let message = await createItem("message", { message: body, attachments, sender: currentOrganization, match: { "__type": "Pointer", "className": "match", "objectId": match.id || match.objectId } });
        if (onSend) onSend(message);
        setBody('');
        setAttachments([]);
    };

    const sendTemplate = (template) => {
        setBody(template);
        setAttachments([]);
        setShowTemplates(false);
    }

    const handleKeyUp = (event) => {
        if (event.target.value == "") showTemplatesIfNeed();
        else setShowTemplates(false);
        if (event.code === 'ENTER') {
            handleSend();
        }
    };

    return (
        <form style={{ width: '100%', margin: 0 }}>
            {showTemplates &&
                <Box
                    sx={{
                        alignItems: 'left',
                        backgroundColor: '#fdfff9',
                        display: 'flex',
                        flexShrink: 0,
                        opacity: 0.9,
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                        maxHeight: "10rem",
                        overflowY: "scroll"
                    }}
                >
                    <List sx={{ width: '100%' }}
                        subheader={
                            <ListSubheader sx={{ px: 2, pt: 0, pb: 0, fontSize: '0.6em' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', lineHeight: 3 }}>
                                    <>Suggestions</>
                                    <IconButton onClick={() => { setShowTemplates(false) }}>
                                        <CloseIcon sx={{ fontSize: 15 }} />
                                    </IconButton>
                                </Box>
                            </ListSubheader>
                        }
                    >

                        {templates.map((template, key) => {
                            return (
                                <ListItem button sx={{ fontSize: '0.8em', cursor: "pointer" }} onClick={() => { sendTemplate(template) }}
                                    key={key}>
                                    {template}
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            }
            {attachments && attachments.length > 0 &&
                <Box
                    sx={{
                        alignItems: 'left',
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexShrink: 0,
                        p: 0,
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    }}
                >
                    <List sx={{ width: '100%' }} >
                        {attachments.map((attachment, key) => {
                            return (
                                <ListItem
                                    key={key}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => { removeFile(key); }}>
                                            <DeleteIcon sx={{ width: 16, height: 16 }} />
                                        </IconButton>
                                    }>

                                    <FilePresentIcon sx={{ width: 16, height: 16 }} />
                                    <ListItemText primaryTypographyProps={{ sx: { fontSize: '0.8em' } }} primary={attachment.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            }
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexShrink: 0,
                    px: 0,
                    py: 1,
                    ...size == 'small' ? { p: 0 } : {}
                }}
                {...other}
            >
                <>
                    <Box
                        sx={{
                            alignItems: 'left',
                            display: {
                                xs: 'flex'
                            },
                            pr: 2
                        }}
                    >
                        <Tooltip title="Attach file">
                            <span>
                                <IconButton
                                    disabled={disabled}
                                    edge="end"
                                    onClick={handleAttachClick}
                                >
                                    {isFileLoading ?
                                        <CircularProgress size="1.2rem" />
                                        :
                                        <AttachFileIcon fontSize="small" />
                                    }
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Menu
                            open={attachmentsMenuOpen}
                            anchorEl={attachmentsMenuAnchorEl}
                            onClose={() => { setAttachmentsMenuAnchorEl(null); }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            PaperProps={{ sx: { borderRadius: 0, p: 0, m: 0 } }}
                            MenuListProps={{ sx: { pt: 0, pb: 0 } }}
                        >
                            {available_attachments.map((aat, key) => (
                                <Box key={key}>
                                    {recent_docs?.find(doc => doc.get("type") === aat.name) ?
                                        <MenuItem
                                            sx={{ fontSize: '0.8em' }}
                                            onClick={(e) => { showRecentDocs(e, aat) }}
                                        //  onMouseEnter={(e)=>{showRecentDocs(e,'memorandum')}}
                                        >
                                            {aat.label}
                                            <ChevronRightIcon fontSize="small" />
                                        </MenuItem>
                                        :
                                        <MenuItem  sx={{ fontSize: '0.8em' }} onMouseEnter={(e) => { setAttachmentsSubMenuAnchorEl(null); }} onClick={() => attachFile(aat)}>{aat.label} ...</MenuItem>
                                    }
                                </Box>

                            ))}
                        </Menu>
                        <Menu
                            open={attachmentsSubMenuOpen}
                            anchorEl={attachmentsSubMenuAnchorEl}
                            onClose={() => { setAttachmentsSubMenuAnchorEl(null); }}
                            onMouseLeave={(e) => { setAttachmentsSubMenuAnchorEl(null); }}
                            PaperProps={{ sx: { borderRadius: 0, p: 0, m: 0 } }}
                            MenuListProps={{ sx: { pt: 0, pb: 0 } }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {recent_docs.filter(doc => doc.get("type") === attachmentType.name)?.map((doc, key) => (
                                <MenuItem key={key} sx={{ fontSize: '0.8em' }} onClick={() => attachRecent(doc)}>{doc.get("name")}</MenuItem>
                            ))}
                            <MenuItem key={'new'} sx={{ fontSize: '0.8em' }} onClick={() => attachFile(attachmentType)}>Upload new...</MenuItem>
                        </Menu>
                    </Box>

                    <TextField
                        disabled={disabled}
                        fullWidth
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        onFocus={showTemplatesIfNeed}
                        placeholder="Send a message"
                        value={body}
                        multiline={size == 'small' ? true : false}
                        variant={size == 'small' ? 'standard' : 'outlined'}
                        inputProps={size == 'small' ? { style: { fontSize: '0.9em' } } : {}}
                        maxRows={5}
                        size={size}
                        id={other?.id +'_text'}
                    />
                    <Tooltip title="Send">
                        <span>
                            <IconButton
                                color="primary"
                                type='submit'
                                disabled={!body || disabled || isFileLoading}
                                onClick={handleSend}
                            >
                                <SendIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </>
                <input
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileChoosed}
                    type="file"
                />
            </Box>
        </form>
    );
};

export default ChatMessageAdd;
