/* eslint-disable no-use-before-define */
import React from 'react';
import {TextField,Autocomplete,createFilterOptions} from '@mui/material';
import { useFormikContext, useField } from 'formik';
import { useState, useCallback,useEffect } from 'react';

const filter = createFilterOptions();

export default function AutocompleteField(props) {
  const [meta, field ] = useField(props);
  const {available_options=[],label="Location Search", ...rest}=props;
  const { name: fieldName,variant="filled" } = props;
  const { setFieldValue, setTouched, errors, touched } = useFormikContext();

  //console.log(available_options);

  const handleChange = (value) => {
    setFieldValue(
      fieldName, value
    );
  };

  function _renderHelperText() {
    if (touched[props.name] && errors[props.name]) {
      return errors[props.name];
    }
  }

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      multiple
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={available_options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      value={field.value}

      fullWidth
      onBlur={() => { setTouched({ ...touched, [props.name]: true }); }}
      
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={_renderHelperText()}
          error={!!(touched[props.name] && errors[props.name])}
          variant={variant}
           />
      )
      }
      {...rest}
    />
  );
}

