import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden } from '@mui/material';
import Scrollbar from '../Scrollbar';
import NavSection from './NavSection';
import AccountSidebarBox from './AccountSidebarBox';
import SidebarNeedHelp from './SidebarNeedHelp';
import useAuth from '../../hooks/useAuth';
import MotivationBar from '../motivation/MotivationBar';



const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile, chatRef } = props;
  const location = useLocation();
  let sections = [{ title: '', items: [] }];
  const { user, currentOrganization, hasAnyRole } = useAuth();

  sections = [{
    title: '',
    items: [
      ...(['MANAGER'].includes(currentOrganization?.get("type")) ||
        (['LENDER'].includes(currentOrganization?.get("type")) && hasAnyRole(["ROLE_LENDER_ADMIN", "ROLE_LENDER_SERVICER"]))) ? [{
          title: 'Dashboard',
          path: '/dashboard',
          //icon: <UsersIcon fontSize="small" />,
        },] : [],
      ...['SELLER', 'BROKER', 'SYNPARTNER'].includes(currentOrganization?.get("type")) ? [{
        title: 'Listings',
        path: '/dashboard/listing',
        //icon: <UsersIcon fontSize="small" />,
      },] : [],
      ...['SELLER', 'BROKER', 'BUYER', 'MANAGER', 'SYNPARTNER'].includes(currentOrganization?.get("type")) ? [{
        title: 'Matches',
        path: '/dashboard/match',
        //icon: <UsersIcon fontSize="small" />,
      }
      ] : [],
      ...['BUYER', 'MANAGER'].includes(currentOrganization?.get("type")) ? [{
        title: 'Listings',
        path: '/dashboard/listing',
        //icon: <UsersIcon fontSize="small" />,
      },] : [],
      ...['SELLER', 'BROKER', 'BUYER'].includes(currentOrganization?.get("type")) ? [{
        title: 'Chat',
        onClick: (e) => {
          chatRef?.current?.openChat(null, true);
          onMobileClose();
        }
        //icon: <UsersIcon fontSize="small" />,
      }] : [],
      ...['MANAGER', 'LENDER'].includes(currentOrganization?.get("type")) ? [{
        title: 'Questionnaires',
        path: '/dashboard/questionnaire',
        //icon: <UsersIcon fontSize="small" />,
      }] : [],
      ...(currentOrganization?.get("type") == 'MANAGER' ? [{
        title: 'Lenders',
        path: '/dashboard/admin/lender',
        //icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Syndications',
        path: '/dashboard/admin/syndication',
        //icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Documents',
        path: '/dashboard/admin/document',
        //icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'FAQ',
        path: '/dashboard/admin/faq',
        //icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Users',
        path: '/dashboard/admin/user',
        //icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Notifications',
        path: '/dashboard/admin/callback',
        //icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Email templates',
        path: '/dashboard/admin/email_template',
        //icon: <UsersIcon fontSize="small" />,
      }
      ] : []),
      ...(currentOrganization?.get("type") === 'MANAGER' && hasAnyRole(["ROLE_ADMIN", "ROLE_SUPERADMIN"])) ? [{
        title: 'Admin',
        children: [
          {
            title: 'Match worker tasks',
            path: '/dashboard/admin/table/match_task',
          },
          {
            title: 'System log',
            path: '/dashboard/admin/table/syslog',
          },
          {
            title: 'Front log',
            path: '/dashboard/admin/table/frontlog',
          },
          {
            title: 'Sent emails',
            path: '/dashboard/admin/table/email',
          },
          {
            title: 'Sent sms',
            path: '/dashboard/admin/table/sms',
          },
          {
            title: 'Sent callbacks',
            path: '/dashboard/admin/table/sent_callback',
          },
          {
            title: 'Unsubscribed',
            path: '/dashboard/admin/table/unsubscribed',
          },
          ...hasAnyRole(["ROLE_SUPERADMIN"]) ? [{
            title: 'Questionnaire templates',
            path: '/dashboard/admin/table/questionnaire_template',
          }]:[]
        ]
        //icon: <UsersIcon fontSize="small" />,
      }] : [],
      ...(['MANAGER'].includes(currentOrganization?.get("type")) && process.env.REACT_APP_HIDE_NEWFEATURES !== "true") ? [] : []
    ]
  },];





  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} options={{ suppressScrollX: true }}>
        {/* Drop down padding for the logo */}
        <Box height={30} />
        {/* Drop down more padding for the logo if smaller than large */}
        <Hidden lgUp>
          <Box height={50} />
        </Hidden>
        <Box sx={{ pt: 4, px: 1 }} >
          <AccountSidebarBox />
        </Box>
        {(user?.get("motivation_program")) &&
          <Box sx={{ pt: 4, px: 1 }} >
            <MotivationBar />
          </Box>
        }
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              onClick={section.onClick}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        {/* Empty space between */}
        <Box
          sx={{
            flexGrow: 1,
            flexDirection: 'column',
          }}
        ></Box>
        <Box>
          <Divider />
          <SidebarNeedHelp />
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
