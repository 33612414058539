/** TO BE USED if REACT_APP_USE_REACT_PDF != true  */
const LOIform= '{purchaser_logo}'+
'<p>' +
'Dear <i>{seller_name}</i>, <br /><br />'+
'It is with pleasure, <i>{purchaser_name}</i> can submit the Terms of our Offer on your property, <i>{listing_name}</i>  located at <i>{listing_address}</i>. <br />'+  
'The terms of our Offer are set forth below: <br /><br />'+


'Purchase Price: <b>{purchase_price}</b><br />' +

'Earnest Money Deposit: <b>{earnest_money_deposit}</b><br />' +

'Due Diligence Period (Days): <b>{dd_period}</b><br />' +

'Closing Date: <b>{closing_date}</b><br />' +

'{other_terms}'+

'{requested_dd_items}' +

'<br />To accept the terms of this Offer and proceed to the Purchase & Sale Agreement, please click Accept. We look forward to your response and comments. <br />' +
'THIS IS A NON-BINDING LETTER OF INTENT.  ANY AND ALL NEGOTIATIONS SHALL BE GOVERNED SOLELY BY THE PURCHASE AND SALE AGREEMENT. ' +
'<br /> <br />' +
'<b>{purchaser_name}</b><br /><br />' +
'By {signature} <br /> {signee_name}<br />' +
'Its {signee_title} <br /><br />' +
'{today_date}' +
'</p>';

export default LOIform;