import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Box, Button, FormHelperText, TextField, CircularProgress, Checkbox, Typography, Tooltip, InputAdornment, IconButton } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import NumberField from '../EnchantedForm/form_fields/NumberField';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router-dom';
import DocumentModalLink from '../document/DocumentModalLink';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import SetupAccountLinkDialog from './SetupAccountLinkDialog';

const DONT_ASK_FOR_PHONE = ['LENDER', 'SYNPARTNER'];

const RegisterJWT = (props) => {
    const { invitation,
        listing_user_info,
        questionnaire,
        organization_type = 'SELLER',
        show_fee_schedule_checkbox = false,
        extra = {}
    } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [setupAccountDialogOpen, setSetupAccountDialogOpen] = useState(false);
    const { register } = useAuth();
    const navigate = useNavigate();

    const getValidationSchema = () => {
        let fields = {
            email: Yup
                .string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            organization_name: Yup.string()
                .max(255),
            first_name: Yup.string()
                .max(255)
                .required('First name is required'),
            last_name: Yup.string()
                .max(255)
                .required('Last name is required'),
            password: Yup
                .string()
                .max(255)
                .required('Password is required'),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Password is required'),
            policy: Yup.boolean().oneOf([true], 'This field must be checked'),
            ...show_fee_schedule_checkbox ? {
                fee_schedule: Yup.boolean().oneOf([true], 'This field must be checked'),
            } : {}
        }
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
        if (!(DONT_ASK_FOR_PHONE.includes(invitation?.get("organization")?.get("type") || organization_type))) fields["phone"] = Yup.string().required('Phone is required').matches(phoneRegExp, 'Phone number is not valid');
        return Yup.object().shape(fields);
    }

    const getDocumentForUserType = () => {
        switch (organization_type) {
            case 'LENDER':
                return 'lender_terms'
            case 'BUYER':
                return 'mbi_terms'
            default:
                return 'seller_terms'
        }
    }

    const getInitialValue = (name) => {
        let value = '';
        if (invitation) {
            switch (name) {
                case 'organization_name':
                    return invitation.get("organization")?.get("name");
                case 'email':
                    return invitation.get("username");
                default:
                    return invitation.get(name) || '';
            }
        } else if (questionnaire) {
            switch (name) {
                case 'organization_name':
                    return questionnaire.get("organization_name");
                /*case 'email':
                    return questionnaire.get("primary_email");*/ //we are now getting it from email field and allow registration only by using initial email from questionnaire
                default:
                    return questionnaire.get(name) || '';
            }
        } else if (listing_user_info && (listing_user_info.listing || listing_user_info.extra)) {
            let extra = listing_user_info.listing?.get("extra") || listing_user_info.extra || [];
            switch (name) {
                case 'organization_name':
                    return extra["organization"] || '';
                case 'email':
                    return extra["email"] || '';
                case 'first_name':
                    return extra["first_name"] || (extra["contact_name"] ? extra["contact_name"].trim()?.split(" ")[0] : '') || '';
                case 'last_name':
                    return extra["last_name"] || (extra["contact_name"] ? extra["contact_name"].trim()?.split(" ")[1] : '') || '';
                case 'phone':
                    return extra["phone"] || '';
            }
        }
        return '';
    }

    const getInitialValues = () => {
        let values = {
            organization_name: getInitialValue("organization_name"),
            email: getInitialValue("email"),
            password: '',
            passwordConfirmation: '',
            last_name: getInitialValue("last_name"),
            first_name: getInitialValue("first_name"),
            policy: false,
            ...show_fee_schedule_checkbox ? {
                fee_schedule: false,
            } : {},
            sms_consent: false,
            marketing_consent: false,
            submit: null
        }
        if (!(DONT_ASK_FOR_PHONE.includes(invitation?.get("organization")?.get("type") || organization_type))) values["phone"] = getInitialValue("phone");
        return values;
    }

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={getValidationSchema()}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    let addl_values = { type: organization_type, extra: extra };
                    if (invitation) {
                        addl_values.invitation = invitation;
                        addl_values.invitation_code = invitation.get("code");//code is visible only if user used email link (not a copied one)
                    }
                    if (questionnaire) {
                        addl_values.questionnaire = questionnaire;
                        addl_values.questionnaire_code = questionnaire.get("code");//code is visible only if user used email link (not a copied one)   
                    }
                    if (listing_user_info) addl_values.listing_code = listing_user_info.code;
                    try {
                        await register({ ...values, ...addl_values });
                        setStatus({ success: true });
                        setSubmitting(false);
                        navigate("/dashboard");
                    } catch (err) {
                        console.log("[REG ERR]", err);
                        console.log(err.code);
                        setStatus({ success: false });
                        setSubmitting(false);
                        if (err.code === 202) {
                            setSetupAccountDialogOpen(true);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <>
                        <Form
                            noValidate
                            onSubmit={handleSubmit}
                            {...props}
                        >
                            <TextField
                                error={Boolean(touched.organization_name && errors.organization_name)}
                                fullWidth
                                helperText={touched.organization_name && errors.organization_name}
                                label="Organization name"
                                margin="normal"
                                name="organization_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.organization_name}
                                variant="outlined"
                                disabled={invitation ? true : false}
                            />
                            <TextField
                                autoFocus={invitation ? false : true}
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                                disabled={(invitation || questionnaire) ? true : false}
                            />
                            {!(invitation && DONT_ASK_FOR_PHONE.includes(invitation?.get("organization")?.get("type"))) &&
                                <NumberField
                                    fullWidth
                                    isPhoneNumber={true}
                                    label='Phone'
                                    name='phone'
                                    margin="normal"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={touched.phone && errors.phone}
                                    error={Boolean(touched.phone && errors.phone)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{ outline: "none" }}>
                                                <Tooltip sx={{ m: 1 }} title="Emails can go astray. We need to be able to contact you in case you have not responded to a time-sensitive email about a match."><HelpIcon color="light" style={{ "cursor": "default" }} size="small" /></Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            }
                            <TextField
                                error={Boolean(touched.first_name && errors.first_name)}
                                fullWidth
                                helperText={touched.first_name && errors.first_name}
                                label="First name"
                                margin="normal"
                                name="first_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.first_name}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.last_name && errors.last_name)}
                                fullWidth
                                helperText={touched.last_name && errors.last_name}
                                label="Last name"
                                margin="normal"
                                name="last_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.last_name}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                helperText={touched.password && errors.password}
                                label="Password"
                                margin="normal"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton onClick={() => setShowPassword(!showPassword)} >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                error={Boolean(
                                    touched.passwordConfirmation && errors.passwordConfirmation
                                )}
                                fullWidth
                                helperText={
                                    touched.passwordConfirmation && errors.passwordConfirmation
                                }
                                label="Confirm Password"
                                margin="normal"
                                name="passwordConfirmation"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.passwordConfirmation}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ outline: "none" }}>
                                            <IconButton onClick={() => setShowPassword(!showPassword)} >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    ml: -1,
                                    mt: 2,
                                }}
                            >
                                <Checkbox
                                    checked={values.policy}
                                    color="primary"
                                    name="policy"
                                    onChange={handleChange}
                                />
                                <Typography color="textSecondary" variant="body2">
                                    I have read the{' '}
                                    <DocumentModalLink
                                        name={getDocumentForUserType()}
                                        color="primary"
                                        underline="none"
                                        style={{ cursor: 'pointer' }}
                                        variant="p">the terms, conditions, and disclaimers</DocumentModalLink>
                                    {' '}contained in this website
                                </Typography>
                            </Box>
                            {Boolean(touched.policy && errors.policy) && (
                                <FormHelperText error>{errors.policy}</FormHelperText>
                            )}
                            {show_fee_schedule_checkbox &&
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        ml: -1,
                                        mt: 2,
                                    }}
                                >
                                    <Checkbox
                                        checked={values.fee_schedule}
                                        color="primary"
                                        name="fee_schedule"
                                        onChange={handleChange}
                                    />
                                    <Typography color="textSecondary" variant="body2">
                                        I understand that any transaction resultant of a match made by nxtCRE will be subject to the{' '}
                                        <DocumentModalLink
                                            name={'seller_fee_schedule'}
                                            color="primary"
                                            underline="none"
                                            style={{ cursor: 'pointer' }}
                                            variant="p">
                                            fee schedule
                                        </DocumentModalLink>
                                        {'.'}
                                    </Typography>;
                                </Box>
                            }
                            {show_fee_schedule_checkbox && Boolean(touched.fee_schedule && errors.fee_schedule) && (
                                <FormHelperText error>{errors.fee_schedule}</FormHelperText>
                            )}
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    ml: -1,
                                    mt: 2,
                                }}
                            >
                                <Checkbox
                                    checked={values.sms_consent}
                                    color="primary"
                                    name="sms_consent"
                                    onChange={handleChange}
                                />
                                <Typography color="textSecondary" variant="body2">
                                    Sign up for {' '}
                                    <DocumentModalLink
                                        name={'sms_consent'}
                                        color="primary"
                                        underline="none"
                                        style={{ cursor: 'pointer' }}
                                        variant="p">SMS notifications</DocumentModalLink>
                                    {' '}- you can unsubscribe at any time.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    ml: -1,
                                    mt: 2,
                                }}
                            >
                                <Checkbox
                                    checked={values.marketing_consent}
                                    color="primary"
                                    name="marketing_consent"
                                    onChange={handleChange}
                                />
                                <Typography color="textSecondary" variant="body2">
                                    Stay up to date in CRE and receive periodic informational emails from nxtCRE (You can unsubscribe at any time)
                                </Typography>
                            </Box>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Box>
                            )}
                            <Box sx={{ mt: 2 }} />
                            <div style={{ position: 'relative' }}>
                                <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    id="register-btn"
                                >
                                    Register
                                </Button>
                                {isSubmitting &&
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            position: 'absolute',
                                            top: '20%',
                                            left: '90%'
                                        }}
                                    />}
                            </div>
                        </Form>
                        <SetupAccountLinkDialog open={setupAccountDialogOpen} setOpen={setSetupAccountDialogOpen} email={values.email} />
                    </>
                )}
            </Formik>

        </>
    );
};

export default RegisterJWT;
