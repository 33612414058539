
import { FormHelperText, Button, CircularProgress, Grid, IconButton, FormControl, InputLabel, Paper, Typography, Skeleton } from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, Field } from 'formik';
import SwitchField from './form_fields/SwitchField';
import DatePickerField from './form_fields/DatePickerField';
import CheckboxField from './form_fields/CheckboxField';
import SelectField from './form_fields/SelectField';
import InputField from './form_fields/InputField';
import UploadButton from '../EnchantedForm/form_fields/UploadButton';
import UploadImageButton from './form_fields/UploadImageButton';
import { uploadFile } from '../../services/api/ListingAPI';
import { useState, useRef } from 'react';
import PointerField from './form_fields/PointerField';
import JoditTextEditorField from './form_fields/JoditTextEditorField';
import PointerChoiceField from './form_fields/PointerChoiceField';
import AutocompleteField from './form_fields/AutocompleteField';
import NumberField from './form_fields/NumberField';
import CountySelector from './form_fields/CountySelector';
import GeoSelector2 from './form_fields/GeoSelector2';
import RadioField from './form_fields/RadioField';
import JSONEditorReact from './form_fields/JSONEditorReact';
import RelationField from './form_fields/RelationField';


const Parse = require('parse');

const EnchantedForm = (props) => {
    const { fields,
        data,
        onSubmit,
        onCancel,
        variant = "outlined",
        alignButtons = 'right',
        setData,
        hideBtns = false,
        hideSubmitBtn = false,
        okBtnText= 'Save',
        addl_values = {},
        addl_actions } = props;
    const [files, setFiles] = useState({});
    const [changeFlag, setChangeFlag] = useState(false);
    const formEl = useRef(null);



    const getValue = (data, field, type) => {
        let val = '';
        if (data instanceof Parse.Object) {
            if (type=='Relation') val=data.relation(field)
            else val = data.get(field);
        } else if (data) val = data[field];
        if (!val && type === 'array') val = [];
        return val;
    }

    const initialValues = fields?.reduce((accum, field) => {
        if (field.edit !== false || (field.add && !data)) {
            if (field.value) accum[field.name] = field.value;
            else if (field.getInitialValue && typeof field.getInitialValue === 'function') accum[field.name] = field.getInitialValue(data);
            else if (field.name) {
                let parts = field.name?.split(".");
                accum[parts[0]] = getValue(data, parts[0], field.type);
            } else if (field.type === 'group' && field.fields) {
                field.fields.forEach(gf => {
                    if (gf.value) {
                        let parts = gf.name?.split(".");
                        if (parts.length==1)
                            accum[gf.name] = gf.value; //doesn't work if subfield
                        else {
                            if (! accum[parts[0]]) accum[parts[0]]={};
                            accum[parts[0]][parts[1]]=gf.value; 
                        }
                    } else if (gf.name) {
                        let parts = gf.name?.split(".");
                        accum[parts[0]] = getValue(data, parts[0], gf.type);
                    }
                });
            }
        }
        return accum;
    }, {});

    //console.log("Load form with initial values",fields,initialValues);

    const getFieldValidationRules = (field) => {
        if (!field.validation_rules) return null;
        let type = field.validation_rules?.type || field?.format || field?.type;
        let validator;
        switch (type) {
            case 'date':
            case 'datetime':
            case 'date_item': //TODO do we use this??
            case 'date_obj':
                validator = Yup.date();
            case 'boolean':
            case 'checkbox':
                validator = Yup.boolean();
            case 'object':
                validator = Yup.object();
            case 'array':
                validator = Yup.array();
            case 'currency':
            case 'number':
                validator = Yup.number();
            default:
                validator = Yup.string();
        }
        Object.keys(field.validation_rules).forEach(key => {
            if (key !== 'type' && validator[key]) validator = validator[key](field.validation_rules[key]);
        });
        return validator;
    }

    const validationSchema = Yup.object().shape(fields?.reduce((accum, field) => {
        if ((field.edit !== false || (field.add && !data)) && field.name) {
            if (field.validation_rules)  //to store in json
                accum[field.name]=getFieldValidationRules(field);
            else if (field.validation)
                accum[field.name] = field.validation;
        } else if (field.type === 'group' && field.fields) {
            field.fields.forEach(gf => {
                if (gf.name) {
                    if (gf.validation_rules)  //to store in json
                        accum[gf.name]=getFieldValidationRules(gf); //!!sic! can't use with names with dots like extra.something
                    else if (field.validation)
                        accum[gf.name] = gf.validation;
                }
            });
        }
        return accum;
    }, {}));

    const onFormChange = (e, values) => {
        if (setData) {
            let vs = { ...values };
            if (e.target.type==='checkbox')
                vs[e.target.name] = ['true','checked',true].includes(e.target.checked);
            else 
                vs[e.target.name] = e.target.value;
            //console.log("form_changed",vs, "("+e.target.name+")");
            setData(vs);
        }
    }


    const submitForm = (values, actions) => {
        //console.log("Submitting",values);
        let types = fields.reduce((accum, field) => {
            if (field.type === 'group' && field.fields) {
                field.fields.forEach(f => {
                    accum[f.name] = f.type;
                });
            } else
                accum[field.name] = field.type;
            return accum;
        }, {});
        Object.keys(values).forEach(key => {
            switch (types[key]) {
                case 'date':
                    //values[key]=new Date(values[key]);
                    if (!values[key]?.__type && !(values[key] instanceof Date)) {
                        values[key] = { "__type": "Date", "iso": values[key] };//Parse-server format
                    } 
                    break;
                case 'number':
                    if (values[key] === "") values[key] = null;
                    else values[key] = Number(values[key]);
                    break;
                case 'Pointer':
                case 'Relation':
                    if (!values[key]) values[key] = null;
                    break;
                case 'object':
                    //console.log("checking " + key + " with ", values[key]);
                    if (!values[key]) values[key] = null;
                    else if (typeof values[key] !== 'object') values[key] = JSON.parse(values[key]);
                    break;
                case 'boolean':
                    if (values[key] === "") values[key] = null;
                    else if (values[key] === "true" || values[key] === "checked" || values[key] === true) values[key] = true;
                    else if (values[key] === "false" || values[key] === "undefined" || values[key] === false) values[key] = false;
                    break;
                case 'file':
                case 'imagefile':
                    //console.log("file", values[key]);
                    if (files[key]) values[key] = files[key];
                    else if (typeof values[key] !== 'object') values[key] = null;
                    break;
                default:
                    break;
            }
        });
        onSubmit({ ...values, ...addl_values }, actions);
    }

    const renderArray = (mainfield, values, arrayHelpers, formvalues) => {
        let subfields = mainfield.children;
        let blank = {};
        subfields.forEach(sf => { blank[sf.name] = '' });
        if (values && values.length > 0) {
            return (
                values.map((value, index) => (
                    <Grid container key={index} sx={{ pt: 2 }}>
                        {subfields.map((subfield, j) => {
                            return (<Grid item {...subfield.grid_props} sx={{ pb: 2 }} key={`${index}_${j}`}>
                                {subfield.renderer ? subfield.renderer(subfield, null, `${mainfield.name}.${index}.${subfield.name}`) : renderField(subfield, null, formvalues, `${mainfield.name}.${index}.${subfield.name}`)}
                            </Grid>)
                        })}
                        <Grid item md={2} sm={2} xs={12}>
                            <IconButton
                                type="button"
                                variant={variant}
                                onClick={() => arrayHelpers.remove(index)}
                            >
                                -
                            </IconButton>
                            <IconButton
                                type="button"
                                variant={variant}
                                onClick={() => arrayHelpers.insert(index + 1, blank)}
                            >
                                +
                            </IconButton>
                        </Grid>
                    </Grid>
                ))
            );
        } else {
            return (
                <Button type="button" sx={{ m: 1 }} size="small" onClick={() => arrayHelpers.push(blank)} variant={variant}>
                    Add
                </Button>
            )
        }
    }

    const uploadFormFile = async (filedata, field) => {
        const file = await uploadFile(filedata, filedata.name);
        let current_files = files;
        current_files[field.name] = file;
        setFiles(current_files);
        if (field.autosave) autoSubmit();
    }

    const autoSubmit = () => {
        if (formEl.current.isValid && !formEl.current.isSubmitting) { //TODO what if it's adding, not editing?
            //console.log("Autosubmitting ",formEl.current)
            formEl.current.submitForm()
        }
    }

    const onItemChanged=(field)=>{
        if (field.notifyChanges) setChangeFlag(!changeFlag);
        if (field.autosave) autoSubmit();
    }

    const renderField = (field, value, formvalues, custom_name = null) => {
        //console.log(field,value)
        if (field.condition && !field.condition(field, formvalues)) return null;
        return (
            <>
                {field.type === 'group' &&
                    <Paper {...field.paper_props}>
                        {field.label &&
                            <Typography variant='h6' sx={{ pb: 2 }}>{field.label}</Typography>
                        }
                        <Grid
                            container
                        >
                            {field?.fields?.map((gfield, fk) => (
                                <Grid item {...gfield.grid_props} sx={{ pb: 2 }} key={fk}>
                                    {gfield.renderer ? gfield.renderer(gfield, formvalues[gfield.name]) : renderField(gfield, formvalues[gfield.name], formvalues)}
                                    {gfield.after &&
                                        <>
                                            {typeof gfield.after === 'function' ? gfield.after(gfield, formvalues) : gfield.after}
                                        </>
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                }
                {field.type === 'component' &&
                    <>{field.content}</>
                }
                {field.type === 'date' &&
                    <DatePickerField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        variant={variant}
                        {...field.input_props}
                    />
                }
                {field.type === 'boolean' &&
                    <SwitchField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        variant={variant}
                        id={field.id || ('__'+field.name)}
                        {...field.input_props}
                    />
                }
                {field.type === 'choice' &&
                    <SelectField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        variant={variant}
                        choices={field.choices || []}
                        {...field.input_props}
                    />
                }
                {field.type === 'autocomplete' &&
                    <AutocompleteField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        variant={variant}
                        id={field.id || ('__'+field.name)}
                        available_options={field.choices || []}
                        {...field.input_props}
                    />
                }
                {field.type === 'checkbox' &&
                    <CheckboxField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        variant={variant}
                        {...field.input_props}
                        onChange={()=>onItemChanged(field)}
                    />
                }
                {field.type === 'PointerChoice' &&
                    <PointerChoiceField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        variant={variant}
                        model={field.model}
                        field_label={field.field}
                        value={value}
                        filters={field.filters}
                        postFilters={field.postFilters}
                        preloaded_choices={field.preloaded_choices}
                        formvalues={formvalues}
                        changeFlag={changeFlag}
                        onChange={()=>onItemChanged(field)}
                        {...field.input_props}
                    />
                }
                {field.type === 'Pointer' &&
                    <PointerField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        variant={variant}
                        color="primary"
                        attribute={field.field}
                        organization={field.organization}
                        value={value}
                        columns={field.columns}
                        addl_actions={field.addl_actions}
                    />
                }
                {field.type === 'array' &&
                    <FormControl variant={variant} sx={{ p: 1 }}>
                        <InputLabel shrink>{field.form_label || field.label}</InputLabel>
                        <FieldArray
                            name={custom_name || field.name}
                            render={arrayHelpers => (renderArray(field, value, arrayHelpers, formvalues))} />
                    </FormControl>
                }
                {field.type === 'file' &&
                    <UploadButton
                        fullWidth
                        sx={{ pt: '14px', pb: '15px' }}
                        acceptedFileTypes="image/png, image/jpeg, image/jpg"
                        color="primary"
                        variant={variant}
                        buttonText={field.form_label || field.label}
                        upload={(file) => uploadFormFile(file, field)}
                        //onChange={field.autosave? autoSubmit:null }
                        startIcon={field.icon}
                        id={field.id || ('__'+field.name)}
                    />
                }
                {field.type === 'imagefile' &&
                    <UploadImageButton
                        image={value}
                        id={field.id || ('__'+field.name)}
                        upload={(file) => uploadFormFile(file, field)}
                        style={{ width: '140px'}}
                    />
                }
                {['editor', 'html'].includes(field.type) &&
                    <JoditTextEditorField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        variant={variant}
                        value={value}
                        type={field.type}
                        id={field.id || ('__'+field.name)}
                        {...field.input_props}
                    />
                }
                {field.type === 'Number' &&
                    <NumberField
                        thousandSeparator={field.thousandSeparator}
                        decimalScale={field.decimalScale}
                        startAdornmentText={field.startAdornmentText}
                        fullWidth
                        placeholder={field.placeholder}
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        variant={variant} />

                }
                {field.type === 'county_selector' &&
                    <>
                        {/*field.choices && field.choices.length > 0 ?
                            <CountySelector
                                name={custom_name || field.name}
                                label={field.form_label || field.label}
                                preloaded_counties={field.choices || []}
                                formref={formEl}
                                map_modal={field.map_modal || false}
                                variant={variant}
                                value={field.value}
                            />
                            :
                            <Skeleton sx={{ height: '4.5em' }} />
                        */}
                        <GeoSelector2
                            name={custom_name || field.name}
                            id={field.id || ('__'+field.name)}
                            label=""
                            variant={variant}
                            value={field.value}
                        />
                    </>
                }
                {field.type === 'image' && Boolean(getValue(data, field.name, "image")?.url()) &&
                    <img
                        width={field.width || 120}
                        src={getValue(data, field.name, "image").url()}
                        alt={field.alt || ''}
                        loading="lazy"
                        id={field.id || ('__'+field.name)}
                    />
                }
                {field.type === 'radio' &&
                    <RadioField 
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        variant={variant}
                        value={field.value}
                        label={field.form_label || field.label}
                        choices={field.choices || []}
                        {...field.input_props}
                    />
                }
                {field.type === 'json_editor' && 
                    <JSONEditorReact 
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        value={value}
                    />
                }
                {field.type === 'Relation' && 
                    <RelationField 
                        name={custom_name || field.name}
                        model={field.model}
                        label_field={field.label_field}
                        id={field.id || ('__'+field.name)}
                        value={value}
                        onChange={()=>onItemChanged(field)}
                    />
                }
                {!['object', 'Pointer', 'PointerChoice', 'Number', 'Relation', 'boolean', 'date', 'array', 'choice', 'file', 'imagefile', 'hidden', 'editor', 'html', 'checkbox', 'autocomplete', 'group', 'component', 'county_selector', 'image','radio','json_editor'].includes(field.type) &&
                    <InputField
                        fullWidth
                        label={field.form_label || field.label}
                        name={custom_name || field.name}
                        id={field.id || ('__'+field.name)}
                        type={field.type}
                        variant={variant}
                        endAdornmentText={field.endAdornmentText}
                        startAdornmentText={field.startAdornmentText}
                        multiline={field.type === 'text'}
                        rows={field.rows || 10}
                        {...field.input_props}
                    />
                }
                {/*TODO allow over field types */}
            </>
        )
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitForm}
            validateForm
            validateOnMount
            innerRef={formEl}
        >
            {({ isSubmitting, setFieldValue, values, isValid, errors }) => (
                <Form onChange={(e) => onFormChange(e, values)}>
                    <Grid container sx={{ pt: 2 }}>
                        {fields?.map((field, index) => {
                            if (field.edit === false && !(field.add && !data)) return '';
                            return (
                                <Grid item {...field.grid_props} sx={{ pb: 2 }} key={index}>
                                    {field.renderer ? field.renderer(field, values[field.name]) : renderField(field, values[field.name], values)}
                                    {field.after &&
                                        <>
                                            {typeof field.after === 'function' ? field.after(field, values) : field.after}
                                        </>
                                    }
                                </Grid>
                            )
                        })}
                        <Grid item xs={12} sx={{ mt: 3 }}>
                            {errors.submit && (
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} textAlign={alignButtons}>
                            {onCancel && !hideBtns &&
                                <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                    onClick={onCancel}
                                    sx={{ mx: 1 }}
                                >
                                    Cancel
                                </Button>
                            }
                            {addl_actions ? (typeof addl_actions === 'function' ? addl_actions({...values,...addl_values}, data,isValid) : addl_actions) : ''}
                            {!hideBtns && !hideSubmitBtn &&
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting || !isValid}
                                    variant="contained"
                                    sx={{ ml: 1 }}
                                >
                                    {okBtnText}
                                    {isSubmitting &&
                                        <CircularProgress
                                            size={12}
                                            sx={{ ml: 1 }}
                                        />}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
export default EnchantedForm;