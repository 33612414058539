
import { Box, Chip, CircularProgress, Grid, Typography } from '@mui/material';
import { format as date_format, parseISO } from 'date-fns';
import ViewItemDialog from './ViewItemDialog';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import PromiseRenderer from '../../services/helpers/PromiseRenderer';
import getColumnsFromItem from '../../services/helpers/getColumnsFromItem';
import { custom_functions } from './custom_functions';
const Parse = require('parse');

const tryRequire = (path) => {
    try {
        return require(`${path}`);
    } catch (err) {
        return null;
    }
};

const ItemDetails = (props) => {
    const { fields, data, model, allowRefectch = true } = props;
    const [subDialogOpen, setSubDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [_fields,setFields]=useState(fields || []);
    const [dialogData, setDialogData] = useState({});
    const { currentOrganization } = useAuth();

    const view = tryRequire('./models/' + model + '.json') || null;

    const default_view = (<>
        {_fields?.map((field, index) => {
            return (
                <Box key={index}>
                    <b>{field.label}</b>: {JSON.stringify((data instanceof Parse.Object) ? data.get(field.name) : data[field.name])}
                </Box>
            )
        })
        }
    </>)


    //TODO doesn't work for default view for some reason...
    const fetchIfNeeded = async () => {
        if (!data.attributes || Object.keys(data.attributes).length == 0) {
            setLoading(true);
            await data.fetch();
            console.log(" data refetched ", data)
            setLoading(false);
        } 
        if (!_fields || _fields.length==0) {
            setFields(getColumnsFromItem(data));
        }
    }

    useEffect(() => {
        if (data) fetchIfNeeded(data);
        //console.log("details after effect",data);
    }, []);

    const get_item = (obj, col) => {
        if (!obj) return null;
        let res;
        let parts = col?.split(".");
        if (obj instanceof Parse.Object) {
            if (parts[0] == 'id') res = obj.id;
            else res = obj.get(parts[0]);
            if (!res && allowRefectch && (!obj.attributes || Object.keys(obj.attributes).length == 0)) {
                return new Promise(async (resolve, reject) => {
                    await obj.fetch();
                    resolve(get_item(obj, col));
                });
            }
        } else res = obj[parts[0]];
        if (parts.length == 1) return res;
        obj = parts[0];
        parts.shift();
        return get_item(res, parts.join("."));
    }

    const get_composite_item = async (obj, compose, delimiter) => {
        let result = [];
        for (const col of compose) {
            let value = await Promise.resolve(get_item(obj, col));
            if (value) result.push(value);
        }
        if (result.length) return result.join(delimiter || " ");
        return null;
    }

    const format_item = (value, format) => {
        let options;
        if (typeof format === 'object') {
            options = Object.values(format)[0];
            format = Object.keys(format)[0];
        }
        switch (format) {
            case 'currency':
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0,
                });
                if (typeof value == 'string') value = parseInt(value);
                return formatter.format(value);
            case 'datetime':
                if (options) return date_format(value, options);
                return date_format(value, 'MM/dd/yy hh:ii:ss');
            case 'date':
                return date_format(value, 'MM/dd/yy');
            case 'time':
                return date_format(value, 'hh:ii:ss');
            default:
                return value;
        }
    }

    const onPointerFieldClick = (item) => {
        setDialogData({ model: item.className, data: item })
        setSubDialogOpen(true);
    }

    const getFieldView = (field, value, name, index, ignore_substitute = false) => {
        if ((!value || (Array.isArray(value) && value.length === 0)) && field.hide_if_null) return '';
        if (field.replace) value = field.replace[value] || value;
        if (!value && field.substitutes && !ignore_substitute) {
            return getSubstituteView(field);
        }
        if (!value && field.if_null) value = field.if_null;
        if (field.format) value = format_item(value, field.format);
        try {
            switch (field.type) {
                case 'image':
                    if (value instanceof Parse.File) value = value.url();
                    if (value) return (<img src={value} key={name + '_' + index} {...field.options} />);
                    console.error("Can't render image for field " + name);
                    return null;
                case 'chip':
                    return (<Chip key={name + '_' + index} {...field.options} color={field.colors ? field.colors[value] : undefined} label={value} />);
                case 'date':
                    return (<Typography key={name + '_' + index} variant='body2' display="block" {...field.options}>{date_format(value, 'MM/dd/yy')}</Typography>);
                case 'time':
                    return (<Typography key={name + '_' + index} variant='body2' display="block" {...field.options}>{date_format(value, 'hh:ii')}</Typography>);
                case 'datetime':
                    return (<Typography key={name + '_' + index} variant='body2' display="block" {...field.options}>{date_format(value, 'MM/dd/yy hh:ii:ss')}</Typography>);
                case 'component':
                    return (<Box key={name + '_' + index} {...field.options}>{value}</Box>);
                case 'html':
                    return (<iframe
                        srcDoc={value}
                        width="660"
                        height="600"
                        frameBorder="0"
                        key={name + '_' + index}
                        allowFullScreen={true}
                    />);
                case 'pointer':
                case 'Pointer':
                    let chiplabel = value.id;
                    if (field.display_name && value.get(field.display_name)) chiplabel = value.get(field.display_name);
                    return (<Chip key={name + '_' + index} {...field.options} label={chiplabel} onClick={() => { onPointerFieldClick(value) }} />);
                case 'text':
                    return (<Typography key={name + '_' + index} variant='body2' display="block" {...field.options}>{value}</Typography>);
                default:
                    return (<Typography key={name + '_' + index} variant='body2' display="block" {...field.options}>{JSON.stringify(value)}</Typography>);
            }
        } catch (err) {
            return (<Typography key={name + '_' + index} variant='body2' display="block" {...field.options}>{JSON.stringify(value)}</Typography>);
        }
    }

    const getSubstituteView = (field, index = 0) => {
        let value;
        let name;
        if (!Array.isArray(field.substitutes[index])) { //single value
            value = Promise.resolve(get_item(data, field.substitutes[index]));
            name = field.substitutes[index];
        } else {//compsed value
            value = Promise.resolve(get_composite_item(data, field.substitutes[index], field.delimiter));
            name = field.substitutes[index][0];
        }
        return <PromiseRenderer key={'substitute_promise_' + name + '_' + index} promise={new Promise((resolve, reject) => {
            value.then(val => {
                if (!val && field.substitutes[index + 1]) resolve(getSubstituteView(field, index + 1)); //if still null and has more possible substitutions
                else resolve(getFieldView(field, val, name, "s_" + index, true));
            }).catch(err => {
                resolve('');
            })
        })} />
    }

    const renderField = (name, field) => {
        if (field.hide_to_org) {
            if (!Array.isArray(field.hide_to_org)) field.hide_to_org = [field.hide_to_org];
            if (field.hide_to_org.includes(currentOrganization?.get("type"))) return '';
        }
        if (field.show_to_org) {
            if (!Array.isArray(field.show_to_org)) field.show_to_org = [field.show_to_org];
            if (!field.show_to_org.includes(currentOrganization?.get("type"))) return '';
        }
        if (name === '_clear') return <div key={name + "_clear"} style={{ clear: "both" }} />
        let label = field?.label || name;
        let values;
        if (field.value) {
            values = field.value;
        } else if (field.function) {
            if (custom_functions[field.function]) values=custom_functions[field.function](data);
            else values='Render error';
        } else if (field.compose) {
            values = get_composite_item(data, field.compose, field.delimiter);
        } else {
            values = get_item(data, name);
        }
        let views = [];
        if (!Array.isArray(values)) { values = [values]; }
        values.forEach((value, index) => {
            let view;
            if (typeof value === 'object' && typeof value?.then === 'function') { /** if Promise */
                view = <PromiseRenderer key={'promise_' + name + '_' + index} promise={new Promise((resolve, reject) => {
                    value.then(val => {
                        resolve(getFieldView(field, val, name, index));
                    }).catch(err => {
                        resolve('');
                    })
                })} />;
            } else {
                view = getFieldView(field, value, name, index);
            }
            if (view) views.push(view);
        });
        //console.log("rendering "+name,views)
        return <Box key={name + '_view'}>
            {!field?.hide_label && views.length>0 &&
                <Typography key={name + "label"} variant='caption' display="block" {...field.label_options}>{label}</Typography>
            }
            {views.map(view => view)}
        </Box>;
    }

    const renderGrids = (grids) => {
        return <Grid container >
            {grids.map((grid, key) => (
                <Grid item key={key} {...grid.options}>
                    <Box {...grid.box_options}>
                        {Object.keys(grid?.fields || {})?.map((name) => renderField(name, grid.fields[name]))}
                        {grid?.grids && renderGrids(grid.grids)}
                    </Box>
                </Grid>
            ))}
        </Grid>
    }

    const renderView = (
        <>
            {view?.title &&
                <Typography variant='subtitle' display="block" sx={{ pb: 2 }}>{view?.title}</Typography>
            }
            {Object.keys(view?.fields || {})?.map((name) => renderField(name, view.fields[name]))}
            {view?.grids && renderGrids(view.grids)}
        </>
    )

    return (
        <Box {...view?.options}>
            {loading ?
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                :
                <>
                    {view ? renderView : default_view}
                </>
            }

            <ViewItemDialog
                open={subDialogOpen}
                setOpen={setSubDialogOpen}
                model={dialogData?.model}
                title={dialogData?.model + ' #' + dialogData?.data?.id}
                data={dialogData?.data}
            />
        </Box>
    )
}

export default ItemDetails;