/* eslint-disable indent */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import toast from 'react-hot-toast';
import { PARSE_APP_ID, API_URL } from '../../config'
const Parse = require('parse');

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

let live_query_classes = null;

let maintenance_mode=false;

export const updateLiveQueryClasses = async () => {
  if (null !== live_query_classes) return;
  try {
    live_query_classes = await Parse.Cloud.run("live_query_classes");
  } catch (error) {
    live_query_classes = [];
    console.warn("Can't use live query", error);
  }
}


export const isLiveQuery = (modelname) => {
  return live_query_classes.includes(modelname);
}

export const api = () => {
  const url = window.location.protocol;
  //console.log("Using token",Parse.User.current().getSessionToken());
  const axiosClient = axios.create({
    baseURL: url,
    headers: {
      ...Parse.User?.current() ? {
        "X-Parse-Session-Token": Parse.User.current().getSessionToken()
      } : {},
      'X-Parse-Application-Id': PARSE_APP_ID,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    timeout: 10000,
  });

  axiosRetry(axiosClient, {
    retries: 0,
    shouldResetTimeout: true,
    retryCondition: () => true // retry no matter what
  });

  axiosClient.interceptors.response.use(
    response => response,
    error => {
      console.log(error.response.data);
      return Promise.reject(error);
    }
  );
  return axiosClient;
};

export const saveError = async (text, data, type = "error", user) => {
  try {
    if (typeof text !== String) text = JSON.stringify(text);
    await api().post(process.env.REACT_APP_API_URL + '/classes/frontlog', { type, text, user, url: window.location.href, data });
  } catch (error) {
    console.log("Saving ERR", error);
  }
}


const enableMaintanceMode = (message = <div><b>We’ll be back soon!</b><br />Sorry for the inconvenience. We’re performing some maintenance at the moment and we’ll be back up shortly!</div>) => {
  const closeMaintanceModeIfHealthy = (toastId) => {
    setTimeout(async () => {
      try {
        console.log("Checking health");
        const response = await api().post(API_URL + '/health',);
        const { status } = response;
        if (status===200) {
          maintenance_mode=false;
          toast.dismiss(toastId);
          toast.success("Maintance finished");
        } else closeMaintanceModeIfHealthy(toastId);
      } catch (err) {
        closeMaintanceModeIfHealthy(toastId);
      }
    }, 3000);
  }
  if (!maintenance_mode) {
    maintenance_mode=true;
    const toastId = toast.error(message, { duration: Infinity });
    closeMaintanceModeIfHealthy(toastId);
  }
}

export const handleStatusCode = (error) => {
  //console.log("ERR",error);
  if (error.name === "AxiosError") error = error.response?.data;
  switch (error.response?.status || error.status_code || error.code) {
    case 100: //PARSE API error // server updates??
      enableMaintanceMode();
      break;
    case 200:
    case 201:
    case 204:
      break;
    case 209:
      toast.error(error?.message);
      //console.log("NEED LOGOUT");
      Parse.User.logOut();
      window.location.reload();
      throw new Error(error);
    case 409: //not showing error for 409 errors - to allow custom error handling with duplicates
    case 471: //not showing error for 471 error (requested email was unsubscribed)
      throw error;
    default:
      //console.log('ERR',JSON.stringify(error));
      toast.error((typeof error.message === 'string' ? error?.message : JSON.stringify(error.message)) || JSON.stringify(error?.error || error || "Server error"));
      throw error || new Error("Server error");
  }

};
