import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

const UploadButton = (props) => {
  const { isDisabled, buttonText, upload, acceptedFileTypes, ...rest } = props;

  const [selectedFile, setSelectedFile] = useState(undefined);
  const [isUploading, setIsUploading] = useState(false);

  const onFileChange = event => {
    const selected = event.target.files[0];
    setSelectedFile(selected);
  };

  useEffect(() => {
    if (selectedFile?.name) {
      uploadFile();
    }
  }, [selectedFile]);

  const uploadFile = async () => {
    try {
      const formData = new FormData();
      setIsUploading(true);
      
      await upload(selectedFile);
      toast.success('Upload Complete');
      setIsUploading(false);
    } catch (err) {
      setIsUploading(false);
      console.log(err);
      toast.error('Error! File not upload.');
    }
  };

  return (
    <Box>
      <Button
        disabled={isDisabled}
        component="label"
        endIcon={isUploading && <CircularProgress
          color='white'
          size={24}
        />}
        {...rest}
      >
        {buttonText}
        <input
          type="file"
          accept={acceptedFileTypes}
          hidden
          onChange={onFileChange}
        />
      </Button>
    </Box>
  );

};

export default UploadButton;