import { useEffect, useState, Fragment,Component } from 'react';
import { Skeleton, IconButton, Avatar, Badge, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { getChatThreads, getChatThreadsCloudFunction } from '../../services/api/ChatAPI';
import useAuth from '../../hooks/useAuth';




class ChatThreads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            page: 1,
            total: 0,
            threads: [],
            subscription:null,
            subscription_update:null,
            hasUnreaded:false,
            extra_skip:0
        }
    }

    componentDidMount(){
        this.loadThreads();
    }
    
    componentWillUnmount(){
        if (this.state.subscription) {
            this.state.subscription.unsubscribe();
        }
    }

    loadThreads() {
        this.setState({ isLoading: true });
        getChatThreads(this.props.organization, this.state.page, 10,this.state.extra_skip).then(data=>{
            const { count, results, subscription } =data;
            
            if (results.length > 0) {
                this.setState({ 
                        total: count, 
                        page: this.state.page + 1, 
                        threads: [...results, ...this.state.threads],
                    });
                if (results.some(th=>(th.get("last_message") && !th.get("last_message").get("readAt") && th.get("last_message").get("sender").id !== this.props.organization.id)))
                    this.props.setHasUnread(true);
            }
            if (subscription) {
                if (this.state.subscription) {
                    this.state.subscription.unsubscribe();
                }
                this.setState({subscription});
                subscription.on('create', this.onNewThreadEvent.bind(this));
                subscription.on('update', this.onNewMessageEvent.bind(this));
            }
            this.setState({ isLoading: false });
            if (this.state.page == 1 && results.length == 0) this.props.setVisible(false);
        }).catch (err => {
            console.error(err);
            this.setState({ isLoading: false });
        });
    }

    async onNewThreadEvent(thread) {
        if (!thread.get("last_message")?.get("message")) await thread.fetchWithInclude("last_message"); 
        this.setState({thread:[thread,...this.state.threads ],extra_skip:this.state.extra_skip+1});
        this.props.setVisible(true);
        this.props.setHasUnread(false);
    }

    async onNewMessageEvent(thread) { //fired every time match updated
       //console.log("new message!",thread);
        if (!thread.get("last_message")?.get("message")) await thread.fetchWithInclude(["last_message","deal"]); 
        let threads=[...this.state.threads]
        let updated_index=threads.findIndex(th=>th.id===thread.id);
        if (updated_index>=0) {
            //console.log("updating "+updated_index,thread.get("preference").id)
            threads[updated_index]=thread;
            this.setState({threads});
            if (threads.some(th=>(this.isLastMessageUnread(th))))
                this.props.setHasUnread(true);
            else 
                this.props.setHasUnread(false);
        }
    }

    _onSelect(match) {
        //console.log("clicked",match);
        if (this.isLastMessageUnread(match)) match.get("last_message").set("readAt",new Date());
        if (!this.state.threads.some(th=>(this.isLastMessageUnread(th))))
            this.props.setHasUnread(false);
        this.props.onSelect(match);
    }

    loadMoreIfNeed(event) {
        if ((event.target.clientHeight + event.target.scrollTop > (event.target.scrollHeight - 5)) && (this.state.threads.length < this.state.total)) {
            this.loadThreads();
        } 
    }

    truncate(str, n, useWordBoundary) {
        if (!str) return '';
        if (str.length <= n) { return str; }
        const subString = str.slice(0, n - 1); // the original check
        return (useWordBoundary
            ? subString.slice(0, subString.lastIndexOf(" "))
            : subString) + "...";
    }

    threadTitle (match) {
        let preference_organization = match.preference_organization || match.get("preference_organization") || match.get("preference");
        //console.log(preference_organization);
        if (preference_organization?.id === this.props.organization?.id)
            return "Listing #" + (match.listing?.id || match.get("listing")?.id)
        else
            return "MBI #" + (match.get("preference")?.id || preference_organization?.id || '')
    }

    isLastMessageUnread(match) {
        return (match.get("last_message") && !match.get("last_message").get("readAt") && match.get("last_message").get("sender")?.id!==this.props.organization.id);
    }

    render() {
        return (
            <Box sx={{ overflowY: 'scroll' }} onScroll={this.loadMoreIfNeed.bind(this)}>
            <List sx={{ pt: 0, pb: 0, width: '100%', bgcolor: 'background.paper' }} >
                {!this.state.isLoading && !this.state.threads?.length &&
                    <ListItem alignItems="flex-start" sx={{ px: 2 }} key={-1}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                            primary="No recent chat threads"
                        />
                    </ListItem>
                }
                {this.state.threads?.length > 0 && this.state.threads.map((match, key) => (
                    <ListItem alignItems="flex-start" key={key} sx={{ px: 2 }} button onClick={() => this._onSelect(match)}>
                        <ListItemAvatar sx={{ minWidth: 0, pr: 1 }}>
                            <Avatar sx={{ color: '#fff' }} ></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: 14, ...(this.isLastMessageUnread(match)) ? { fontWeight: 'bold' } : { fontWeight: 'medium' } }}
                            secondaryTypographyProps={{ fontSize: 12, fontWeight: 'medium' }}
                            primary={this.threadTitle(match)}
                            secondary={
                                <Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {this.truncate(match.get("listing")?.get("title"), 15, true)} {' - '}
                                    </Typography>
                                    <span style={(this.isLastMessageUnread(match)) ? { color: 'primary', fontWeight: 'bold' } : {}}>
                                        {this.truncate(match.get("last_message")?.get("message")?.replace(/<\/?[^>]+(>|$)/g, ""), 30, true)}
                                    </span>
                                </Fragment>
                            }
                        />
                        <Badge color="success" variant="dot" invisible={!this.isLastMessageUnread(match)} sx={{ mt: 3 }}></Badge>
                    </ListItem>
                ))
                }
                {this.state.isLoading &&
                    <ListItem key={-2}>
                        <ListItemText >
                            <Skeleton />
                            <Skeleton />
                        </ListItemText>
                    </ListItem>
                }
            </List>
        </Box>
    )}
}

export default ChatThreads;