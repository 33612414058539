import React from 'react';
import { RadioGroup, FormLabel, Radio, FormControlLabel, FormControl } from '@mui/material';
import { useFormikContext, Field } from 'formik';

export default function RadioField(props) {
  const { name: fieldName, label, choices = [], id,  ...rest } = props;
  const { setFieldValue } = useFormikContext();


  const handleChange = (e) => {
    //console.log("changing "+fieldName+" to "+e.target.value);
    setFieldValue(
      fieldName, e.target.value
    );
  };

  return (
    <FormControl {...rest}>
    <FormLabel>{label}</FormLabel>
    <RadioGroup name={fieldName}  onChange={handleChange} id={id||("__"+fieldName)}>
      {choices.map((item, index) => (
        <FormControlLabel value={item.value} key={fieldName+"_radio_"+index} control={<Radio size="small" />} label={item.label} />
      ))}
    </RadioGroup>
  </FormControl>
  );
}