import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  ListItem,
  Popover,
  Typography,
  ListItemButton,
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import PersonIcon from '@mui/icons-material/Person';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckIcon from '@mui/icons-material/Check';

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, currentOrganization, changeOrganization, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [organizationAnchorEl, setOrganizationAnchorEl] = useState(null);
  const organizationListOpen = Boolean(organizationAnchorEl);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const _changeOrganization = (index) =>{
      changeOrganization(index);
      localStorage.setItem("currentOrganizationIndex",index);
  }

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      if (typeof window !== 'undefined') {
        window.location.href = '/';
      }

      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Button
          variant="contained"
          style={{background:"linear-gradient(176deg, rgba(68,239,255,1) 0%, rgba(29,184,221,1) 23%, rgba(11,157,205,1) 36%, rgba(0,151,201,1) 86%)"}}
        >Account</Button>
        {/* <Avatar
          src={user.avatar}
          sx={{
            height: 32,
            width: 32
          }}
        /> */}
      </Box>
      {user &&
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          keepMounted
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: { width: 240 }
          }}
        >
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Box sx={{ p: 2 }}>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    noWrap
                  >
                    {(user?.get("first_name") || '') + " " + (user?.get("last_name") || '')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    {user?.get("username")}
                  </Typography>
                </Box>
              </ListSubheader>
            }
          >

            <Divider />
            <ListItemButton
              component={RouterLink}
              to={'/account'}
              onClick={handleClose}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                sx={{ ml: 2 }}
                primary={(
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Profile
                  </Typography>
                )}
              />
            </ListItemButton>
            {user.organizations.length > 1 &&
              <ListItemButton
                aria-owns={organizationAnchorEl ? 'organization-popover' : undefined}
                onClick={(e) => { setOrganizationAnchorEl(e.currentTarget); }}
              >
                <ListItemIcon>
                  <ChevronLeftIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  sx={{ ml: 2 }}
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      Organization
                    </Typography>
                  )}
                />
              </ListItemButton>
            }
            <ListItem>
              <Button
                color="primary"
                fullWidth
                onClick={handleLogout}
                variant="outlined"
                id="logout_btn"
              >
                Logout
              </Button>
            </ListItem>
          </List>
        </Popover>

      }
      {user.organizations.length > 1 &&
        <Popover
          id="organization-popover"
          open={organizationListOpen}
          anchorEl={organizationAnchorEl}
          onClose={() => { setOrganizationAnchorEl(null); }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }} >
          <List>
            {user.organizations?.map((org, index) => (
              <ListItemButton
                key={index}
                onClick={() => _changeOrganization(index)}
              >
                <ListItemText
                  sx={{ mx: 2 }}
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {org.get("name")}
                    </Typography>
                  )}
                />
                <ListItemIcon>
                  {currentOrganization.id == org.id &&
                    <CheckIcon fontSize="small" />
                  }
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        </Popover>
      }
    </>
  );
};

export default AccountPopover;
