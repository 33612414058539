import { Skeleton } from '@mui/material';
import { useEffect,useState } from 'react';


const PromiseRenderer = (props) => {
  const { promise,fallback } = props;
  const [data,setData] = useState( fallback || <Skeleton />)
  
  useEffect(() => {
    promise.then(res=>setData(res));
  }, []);

  return data;
};

export default PromiseRenderer;
