
const Parse = require('parse');

const getColumnsFromItem = (item) => {
    if (!item) return [];
    let exclude_from_search = ['objectId', 'createdAt', 'updatedAt'];
    return Object.keys((item instanceof Parse.Object)?item.attributes:item).map((res, i) => {
        let type=guessType((item instanceof Parse.Object)?item.get(res):item[res]);
        return {
            label: res,
            name: res,
            search: (type==='string')?!exclude_from_search.includes(res):false,
            edit: !exclude_from_search.includes(res),
            type: type,
            hide: (i > 3)
        }
    })
}
const guessType = (obj) => {
    let t = typeof obj;
    if (t===undefined || t==='undefined') return 'unknow';
    let timestampregex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{3}?Z?$/
    if (t === 'string' && timestampregex.test(obj)) t = 'date';
    else if (t === 'object' && obj instanceof Date) t = 'date_obj';
    else if (t === 'object' && obj && obj.hasOwnProperty("__type")) t = obj.__type;
    return t;
}

export default getColumnsFromItem;