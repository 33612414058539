import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { authAPI } from '../../services/api/AuthAPI';
import toast from "react-hot-toast";
import { useState } from "react";

const SetupAccountLinkDialog = (props) => {
    const { open, setOpen, email } = props;
    const [isSubmitting, setSubmitting] = useState(false);

    const sendLink = async () => {
        setSubmitting(true);
        try {
            await authAPI.sendAccountSetupEmail(email);
            toast.success("Registration link sent. Please, check your inbox");
            setOpen(false);
        } catch (err) {
            console.log(err);
        }
        setSubmitting(false);
    }

    return (
        <Dialog open={open} onClose={() => { setOpen(false); }}>
            <DialogTitle>
                Account exists
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    This email is already registered but the account is not fully setup.  To complete account set up for this email, please click below
                    <Link color="primary"
                        component="button"
                        variant="body2"
                        sx={{ mt: 2 }}
                        onClick={sendLink}
                        disabled={isSubmitting}
                        id="setup_link">Email me registration link</Link>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export default SetupAccountLinkDialog;