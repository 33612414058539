import { useState } from 'react';
import { format as date_format } from 'date-fns';
//import { Lightbox } from 'react-modal-image';
import { Box, Tooltip, Typography } from '@mui/material';
import * as timeago from 'timeago.js'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';


const ChatMessage = (props) => {
  const { body, size, attachments, showDealInfoDialog, contentType, sent_at,read_at, adminView, senderAvatar, senderName, senderType, match, ...other } = props;
  const [expandMedia, setExpandMedia] = useState(false);
  const now = new Date();

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
        fontSize: (size == 'small' ? '0.8em' : '1em')
      }}
      {...other}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: senderType === 'user'
            ? 'row-reverse'
            : 'row',
          maxWidth: 500,
          ml: senderType === 'user' ? 'auto' : 0
        }}
      >
        <div>
          <Box
            sx={{
              backgroundColor: senderType === 'user'
                ? 'primary.main'
                : 'background.paper',
              borderRadius: 1,
              boxShadow: 1,
              color: senderType === 'user'
                ? 'primary.contrastText'
                : 'text.primary',
              px: 2,
              py: 1,
              position: 'relative'
            }}
          >
            <Typography variant="subtitle2" sx={size == "small" ? { fontSize: '0.8em', pb: 0 } : { pb: 1 }}>{senderName}</Typography>


            <Box >
              {contentType === 'image'
                ? (
                  <Box
                    onClick={() => setExpandMedia(true)}
                    sx={{
                      mt: 2,
                      '& img': {
                        cursor: 'pointer',
                        height: 'auto',
                        maxWidth: '100%',
                        width: 380
                      }
                    }}
                  >
                    <img
                      alt="Attachment"
                      src={body}
                    />
                  </Box>
                )
                : (
                  <div
                    color="inherit"
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: body }}
                  >
                  </div>
                )}
            </Box>
            {(attachments && attachments.length > 0) &&
              <Box sx={{ fontSize: '0.75em', width: '100%', textAlign: 'right' }}>
                <b>Attachments:</b><br />
                {attachments?.map((attachment, key) => {
                  return (
                    <div key={key}>
                      {attachment.revoked ?
                        <span style={{color:'red'}}>File deleted</span>
                        :
                        <>
                          {['loi'].includes(attachment.type) && match?.get("deal")?.get("loi")?.id == attachment.id ?
                            <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => { showDealInfoDialog(match) }}>{attachment.name || attachment.name()}</span>
                            :
                            <a href={attachment.url || attachment.url()}
                              onClick={(e) => { e?.stopPropagation(); e?.nativeEvent?.stopImmediatePropagation(); }}
                              target="_blank" sx={{ display: 'block' }}>{attachment.name || attachment.name()}</a>
                          }
                        </>
                      }

                      <br />
                    </div>
                  );
                })}
              </Box>
            }
            {(senderType === 'user' || adminView) && 
              <Tooltip  sx={{ m: 1 }} title={read_at?("Read at "+date_format(read_at,"hh:ii MM/dd/yy")):"Not read yet"}>
                  {read_at ?
                      <DoneAllIcon fontSize="small" color="primary" sx={{position:"absolute",right:10,bottom:-22}} />
                      :
                      <DoneIcon fontSize="small" color="info" sx={{position:"absolute",right:10,bottom:-22}} />
                  }
              </Tooltip>
            }
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: senderType === 'user'
                ? 'flex-end'
                : 'flex-start',
              mt: 0.5,
              mr: 3,
              px: 2
            }}
          >
            <Typography
              color="textSecondary"
              noWrap
              variant="caption"
            >
              {timeago.format(sent_at)}
            </Typography>
          </Box>
        </div>
      </Box>
      {/*expandMedia && (
        <Lightbox
          large={body}
          onClose={() => setExpandMedia(true)}
        />
      )*/}
    </Box>
  );
};


export default ChatMessage;
