import { Dialog, DialogContent,  DialogTitle, DialogActions, IconButton, Button, Divider,Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactSignatureCanvas from 'react-signature-canvas';
import toast from 'react-hot-toast';
import { useRef } from 'react';

const SignatureDialog = (props) => {
    const { open, setOpen, setParentOpen,setSignature } = props;
    const ref = useRef();

    const clearCanvas=(e)=>{
        //console.log('clearing',e,ref);
        ref.current.clear();
    }

 
    const handleSign=()=>{
        if (!ref?.current || ref.current.isEmpty()) {
            toast.error("Please, create a signature");
            return;
        }
        if (setSignature) setSignature(ref?.current?.getTrimmedCanvas()?.toDataURL("image/png"));
        if (setParentOpen) setParentOpen(false);
        ref?.current?.clear();
        setOpen(false);
        //console.log('signing')
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { setOpen(false); }}
                PaperProps={{
                    style: {
                        padding: '20px'
                    },
                }}
            >
                <DialogTitle sx={{ p: 0 }}>
                    Your signature:
                    <IconButton
                        aria-label="close"
                        onClick={() => { setOpen(false); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    ><CloseIcon /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent >
                    <Box sx={{ border: 1 , width:500, height:200, borderColor:'#ccc'}}>
                    <ReactSignatureCanvas 
                        ref={ref}
                        penColor='blue'
                        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => { setOpen(false); }} >
                        Cancel
                    </Button>
                    <Button onClick={clearCanvas} >
                        Clear
                    </Button>
                    <Button 
                        onClick={handleSign} 
                        color="primary" 
                        id="sign-btn"
                        variant="contained">
                        Sign
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SignatureDialog;