import React, { useState, useEffect } from 'react';
import { getItems } from '../../../services/api/ModelAPI';
import { at } from 'lodash';
import { useField,useFormikContext } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';

function PointerChoiceField(props) {
  const { label, model, preloaded_choices, field_label, filters, postFilters, formvalues, isDisabled, readOnly, onChange, changeFlag, ...rest } = props;
  const [choices, setChoices] = useState( []);
  const [isLoading, setLoading] = useState(true);
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const selectedValue = field.value ? field.value.id : '';
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  const loadItems = async () => {
    setLoading(true);
    if (preloaded_choices) {
      let _preloaded_choices=preloaded_choices;
      if (typeof _preloaded_choices.then === 'function') _preloaded_choices = await Promise.resolve(_preloaded_choices);
      //console.log()
      setChoices(_preloaded_choices.map(item => { 
        let _label;
        if (Array.isArray(field_label)) {
            let arr=[];
            field_label.forEach(fl=>{
              arr.push(item.get(fl));
            });
            _label=arr.join(rest?.field_separator || " ");
        } else _label=item.get(field_label);
        return { value: item, label: _label?.trim() || 'Untitled' } 
      }));
      let current_index=_preloaded_choices.findIndex(item=>item.id===selectedValue);
      if (current_index==-1) setFieldValue(field.name,'');
      setLoading(false);
      return;
    }
    setChoices([]);
    try {
      let _filters;
      if (typeof filters === 'function') {
        _filters=filters(formvalues);
      } else {
        _filters=filters;
      }
      var { results } = await getItems(model, 500, 1, null, _filters);
      if (postFilters) {
        results=postFilters(results);
      }
      setChoices(results.map(item => { 
        let _label;
        if (Array.isArray(field_label)) {
            let arr=[];
            field_label.forEach(fl=>{
              arr.push(item.get(fl));
            });
            _label=arr.join(rest?.field_separator || " ");
        } else _label=item.get(field_label);
        return { value: item, label: _label?.trim() || 'Untitled' } 
      }));
      let current_index=results.findIndex(item=>item.id===selectedValue);
      if (current_index==-1) setFieldValue(field.name,'');
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadItems()
  }, [model,filters,changeFlag,preloaded_choices]);

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        displayEmpty
        disabled={isDisabled}
        label={label}
        {...field}
        value={selectedValue}
        inputProps={{
          readOnly: readOnly,
        }}
        input={<OutlinedInput variant='filled' notched label={label} />}
        onChange={(e)=>{
          let c=choices.find(el=>el.value?.id===e.target.value);
          setFieldValue(field.name,c?.value);
          if (onChange) onChange(c?.value);
        }}
      >
        <MenuItem value="">
          {isLoading ?
            <em>Loading...</em>
            : <>
                {choices.length>0 ?
                  <em>Please Choose a value</em>
                  : <em>No available options</em>
                }
              </>
          }
        </MenuItem>

        {choices.map((item, index) => (
          <MenuItem key={index} value={item.value.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

export default PointerChoiceField;
