import { useState, useEffect, useRef, Fragment } from 'react';
import { format as date_format, parseISO } from 'date-fns';
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow,
    TableFooter,
    TextField,
    InputAdornment,
    CircularProgress,
    IconButton,
    Chip,
    Link
} from '@mui/material';
import { renderToString } from 'react-dom/server'
import CheckIcon from '@mui/icons-material/Check';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../Scrollbar';
import { getItems, deleteItem, deleteItems, updateItem, createItem, runCloudFunction } from '../../services/api/ModelAPI';
import Skeleton from '@mui/material/Skeleton';
import ColumnSettingsPopup from './ColumnSettingsPopup';
import ConfirmDialog from '../ConfirmDialog';
import DialogForm from '../EnchantedForm/DialogForm';
import toast from 'react-hot-toast';
import ViewItemDialog from '../ViewItem/ViewItemDialog';
import { useNavigate, useSearchParams } from "react-router-dom";
import getColumnsFromItem from '../../services/helpers/getColumnsFromItem';
import useAuth from '../../hooks/useAuth';
import FilterDialog from './FilterDialog';
import FilterListIcon from '@mui/icons-material/FilterList';
import CachedIcon from '@mui/icons-material/Cached';
import PromiseRenderer from '../../services/helpers/PromiseRenderer';
import { isLiveQuery } from '../../services/api/API';
import NestedTable from './NestedTable';
const Parse = require('parse');

const PaginatedTable = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { allowSelection = true,
        allowAdd = true,
        allowSearch = true,
        allowView = true,
        viewInModal = true,
        handleViewItem,
        handleEditItem,
        allowEdit = true,
        editInModal = true,
        editLink,
        beforeEdit,
        handleEdit,
        allowDelete = true,
        showHeaders = true,
        allowChangeHeaders = true,
        filters,
        available_filters,
        model,
        cloud_function,
        include,
        addBtnText,
        addl_form_values = {},
        addl_actions,
        form_addl_actions,
        addl_menu_items,
        prefetched_data = null,
        afterUpdate = null,
        editDialogBtnState = null,
        dialogMaxWidth,
        modal = false,
        not_found_message = <p>No records found</p>,
        onChoose,
        onError,
        duplicateDialogText,
        nested_rows,
        reload_if_current_organization_changed=true,
        allowBulkImport,
        columns = [],
        hideFormBtns,
        hideSubmitFormBtn,
        form_fields,//if null columns will be used instead
    } = props;
    const [loadedModel, setLoadedModel] = useState(model);
    const [total, setTotal] = useState(0);
    const [data, _setData] = useState([]);
    const dataRef = useRef(data);
    const setData = sdata => {
        dataRef.current = sdata;
        _setData(sdata);
    };
    const [cols, setCols] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [limit, setLimit] = useState(parseInt(localStorage.getItem("rows_per_page")) || 10);
    const [page, setPage] = useState((searchParams.get("page") && !modal) ? searchParams.get("page") : 1);
    const [selected, setSelected] = useState([]);

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);
    const [isViewDialogOpen, setViewDialogOpen] = useState(false);
    const [isAllowDuplicateDialogOpen, setAllowDuplicateDialogOpen] = useState(false);
    const [customFilters, setCustomFilters] = useState((searchParams.get("filters") && !modal) ? JSON.parse(searchParams.get("filters")) : {});
    const [dialogData, setDialogData] = useState();
    const [duplicateHelper, setDuplicateHelper] = useState();

    const navigate = useNavigate();

    const default_sort = localStorage.getItem(model + "_sort") ? JSON.parse(localStorage.getItem(model + "_sort")) : null;
    const [orderBy, setOrderBy] = useState(default_sort?.key);
    const [order, setOrder] = useState(default_sort?.dir || 'asc');
    const [search, setSearch] = useState('');
    const [dataSubscription, _setDataSubscription] = useState(null);
    const dataSubscriptionRef = useRef(dataSubscription);
    const setDataSubscription = sdata => {
        dataSubscriptionRef.current = sdata;
        _setDataSubscription(sdata);
    };
    const [searchTypingTimeout, setSearchTypingTimeout] = useState('');
    const selectedSome = selected.length > 0 && selected.length < data.length;
    const selectedAll = selected.length === data.length;

    const searchInputRef = useRef(null);

    const { currentOrganization } = useAuth();

    const loadData = async (modelname) => {
        if (loadedModel !== model) { resetData(); return; };
        if (prefetched_data !== null) {
            setLimit(prefetched_data.length || 0)
            setTotal(prefetched_data.length || 0);
            setData(prefetched_data);
            setSelected([]);
            if (!columns || columns.length == 0) loadColumnsVisibility(getColumnsFromItem(prefetched_data[0]));
            else loadColumnsVisibility(columns);
            setLoading(false);
            return;
        }
        if (cloud_function) {
            try {
                const { results} = await runCloudFunction(cloud_function);
                //console.log(results);
                setLimit(results?.length || 0)
                setTotal(results?.length || 0);
                setData(results);
                setSelected([]);
                if (!columns || columns.length == 0) loadColumnsVisibility(getColumnsFromItem(results[0]));
                else loadColumnsVisibility(columns);  
            } catch (err) {
                console.error(err);
                setData([]);
                setTotal(0);
                setSelected([]);
                if (onError) onError(err);
            }
            setLoading(false);
            return;
        }
        //console.log("loading");
        setLoading(true);
        try {
            const { count, results, subscription } = await getItems(modelname,
                limit,
                page,
                orderBy ? (order === 'desc' ? '-' + orderBy : orderBy) : null,
                { ...filters, ...customFilters },
                search,
                cols.filter(col => col.search)?.map(col => col.name),
                include);
            if (results?.length==0 && page!=1) {handlePageChange(null, 0);return}
            //console.log("loaded", results,filters);
            setTotal(count);
            setData(results);
            setSelected([]);
            if (!cols || cols.length === 0) {
                if (columns && columns.length > 0) {
                    //console.log("loading columns");
                    loadColumnsVisibility(columns)
                } else if (count > 0) {
                    loadColumnsVisibility(getColumnsFromItem(results[0]));
                }
            }
            if (subscription) {
                //console.log("new subscription",subscription);
                if (dataSubscriptionRef.current) {
                    dataSubscriptionRef.current.unsubscribe();
                    setDataSubscription(null);
                }
                subscription.on('create', onCreateItemListener);
                subscription.on('update', onUpdateItemListener);
                setDataSubscription(subscription);
            }
        } catch (err) {
            console.error(err);
            setData([]);
            setTotal(0);
            setSelected([]);
            if (onError) onError(err);
        }
        setLoading(false);
    };

    const onCreateItemListener = (item) => {
        setData([item, ...dataRef.current]);
        //console.log("Item created",item);
    }

    const onUpdateItemListener = async (item) => {
        const updatedData = [...dataRef.current];
        let updated_index = updatedData.findIndex(d => d.id === item.id);
        if (updated_index >= 0) {
            //console.log("Need to update item",item)
            //for some reason subscription item doesn't contain all includes, so, we have to refetch them
            if (include) {
                await item.fetchWithInclude(include).catch(err => { console.log("Can't include required items", include) });
            }
            updatedData[updated_index] = item;
            setData(updatedData);
            //console.log("Item updated",item);
        }
    }

    const resetData = () => {
        setLoadedModel(model);
        if (page !== 1) setPage(1);
        if (cols.length > 0) setCols([]);
        if (search !== '') setSearch('');
        setOrderBy(default_sort?.key);
        setOrder(default_sort?.dir || 'asc');
        if (dataSubscription) {
            dataSubscription.unsubscribe();
            setDataSubscription(null);
        }
        //BUG - if we came from non-existed table to any other table, useEffect will not be fired
        console.log("reset");
    }

    const getDataForExport = async () => {
        const textify = (elem) => {
            if (typeof elem === 'object') {
                try {
                    elem = renderToString(elem);
                } catch (err) {
                    //TODO can't render Lender field for some reason...
                    //console.log("Can't textify",elem,err);
                    elem = "";
                }

                let tmp = document.createElement("DIV");
                tmp.innerHTML = elem;
                elem = tmp.textContent || tmp.innerText || "";
            }
            //return elem;
            return elem.replace("\n", "");
        }
        const max_per_page = 1000;
        const filtered_cols = cols.filter(col => (!col.hide && col.show !== false && col.name != "images"));
        let export_page = 1;
        let data_to_export = [filtered_cols.map(col => col.label)];
        if (prefetched_data !== null) {
            prefetched_data.forEach(item => {
                let str = [];
                filtered_cols.forEach((col) => {
                    str.push(textify(col.render ? col.render(item) : format_item(item, col))); //TODO change render to allow text only
                });
                data_to_export = [...data_to_export, str];
            });
            return data_to_export;
        }

        let finished = false;
        do {
            try {
                const { results } = await getItems(model,
                    max_per_page,
                    export_page,
                    orderBy ? (order === 'desc' ? '-' + orderBy : orderBy) : null,
                    { ...filters, ...customFilters },
                    search,
                    cols.filter(col => col.search)?.map(col => col.name),
                    include);

                if (results.length == 0)
                    finished = true;
                else {
                    results.forEach(item => {
                        let str = [];
                        filtered_cols.forEach((col) => {
                            str.push(textify(col.render ? col.render(item) : format_item(item, col)));
                        });
                        data_to_export = [...data_to_export, str];
                    });
                    export_page++;
                }
            } catch (err) {
                console.error(err);
                break;
            }
        } while (!finished);
        return data_to_export;
    }

    useEffect(() => {
        //console.log("useeff", filters)
        loadData(model);
    }, [model, page, limit, order, orderBy, search, filters, customFilters, ...reload_if_current_organization_changed?[currentOrganization]:[], prefetched_data]);

    useEffect(() => {
        if (editDialogBtnState && editDialogBtnState.isEditDialogOpen && !editDialogBtnState.edit) {
            onEditClick(null);
        }
        setEditDialogOpen(editDialogBtnState?.isEditDialogOpen);
    }, [editDialogBtnState]);

    useEffect(() => {
        return () => {//unmount
            //console.log("unmount")
            if (dataSubscriptionRef.current) {
                dataSubscriptionRef.current.unsubscribe();
                setDataSubscription(null);
            }
        }
    }, []);

    const handleSelectAll = (event) => {
        setSelected(event.target.checked
            ? data?.map((data) => data.id)
            : []);
    };
    const handleSelectOne = (event, id) => {
        if (!selected.includes(id)) {
            setSelected((prevSelected) => [...prevSelected, id]);
        } else {
            setSelected((prevSelected) => prevSelected.filter((_id) => _id !== id));
        }
    };

    const handlePageChange = (event, newPage) => {
        if (!modal) {
            searchParams.set("page", (newPage + 1));
            setSearchParams(searchParams);
        }
        setPage(newPage + 1);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
        localStorage.setItem("rows_per_page", event.target.value);
    };

    const handleSort = (colname) => {
        localStorage.setItem(model + "_sort", JSON.stringify({ key: colname, dir: (order === 'desc') ? 'asc' : 'desc' }));
        setOrderBy(colname);
        setOrder((order === 'desc') ? 'asc' : 'desc');
    }

    const handleSearch = (event) => {
        if (searchTypingTimeout) clearTimeout(searchTypingTimeout);
        setSearchTypingTimeout(setTimeout(() => {
            //console.log("Searching ", event.target.value);
            setSearch(event.target.value);
        }, 1000))
    }

    const clearSearch = (event) => {
        searchInputRef.current.children[0].children[1].value = '';
        setSearch('');
    }

    const toggleColumnVisibility = (index) => {
        let cs = cols;
        cs[index].hide = !cs[index].hide;
        setCols([...cs]);
        saveColumnsVisibility(cs);
    }

    const saveColumnsVisibility = (cls) => {
        let vsblty = cls.reduce((a, col) => ({ ...a, [col.name]: col.hide }), {});
        localStorage.setItem(model + "_vsblty", JSON.stringify(vsblty));
    }

    const loadColumnsVisibility = (cls) => {
        const vsblty_settings = localStorage.getItem(model + "_vsblty");
        if (vsblty_settings) {
            const vsblty = JSON.parse(vsblty_settings);
            cls.forEach(col => {
                if (vsblty.hasOwnProperty(col.name)) col.hide = vsblty[col.name];
            });
        }
        setCols([...cls]);
    }

    const onViewClick = (item) => {
        if (viewInModal) {
            if (handleViewItem) handleViewItem(item);
            else {
                setDialogData(item);
                setViewDialogOpen(true);
            }
        } else {
            navigate("/dashboard/" + model + "/" + item.id, { replace: true });
        }

    }

    const onDeleteOneClick = (item) => {
        setDialogData(item);
        setDeleteDialogOpen(true);
    }

    const onDeleteManyClick = () => {
        setDialogData({ id: "(" + selected.length + ")", ids: selected });
        setDeleteDialogOpen(true);
    }

    const handleDelete = async () => {
        try {
            if (dialogData?.ids)
                await deleteItems(model, dialogData.ids);
            else
                await deleteItem(model, dialogData);
            toast.success("Successfully deleted " + (dialogData.ids ? dialogData.ids.length + " items" : ""));
            setDeleteDialogOpen(false);
            let deleted_index = data.indexOf(dialogData);
            if (deleted_index > -1) {
                let mdata = [...data];
                mdata.splice(deleted_index, 1);
                setData(mdata);
            }
            if (afterUpdate) afterUpdate(dialogData);
        } catch (err) {
            console.error(err);
            if (onError) onError(err);
        }
    }

    const onEditClick = (item) => {
        if (!editLink && (editInModal || !item)) {
            if (handleEditItem)
                handleEditItem(item);
            else {
                setDialogData(item);
                setEditDialogOpen(true);
            }
        } else {
            navigate(editLink ? editLink(item) : ("/dashboard/" + model + "/" + item.id + '/edit', { replace: true }));
        }
    }


    const _handleEdit = async (values, actions) => {
        Object.keys(values).forEach(key => { if (values[key] === null || values[key] === "" || values[key] === "undefined" || values[key] === undefined) delete values[key] });
        if (beforeEdit) beforeEdit(values);
        //console.log(values);
        try {
            let updated;
            if (handleEdit) {
                updated = await handleEdit(values, dialogData);
            } else if (dialogData)
                updated = await updateItem(model, dialogData, values);
            else {
                updated = await createItem(model, values);
            }
            if (!dialogData && !isLiveQuery(model)) {
                let mdata = [updated, ...data];
                setData(mdata);
            }
            toast.success("Successfully " + (dialogData ? "updated" : "created"));
            setEditDialogOpen(false);
            if (afterUpdate) afterUpdate(updated);
        } catch (err) {
            console.error(err);
            if (onError) onError(err,values,dialogData,(values=>{_handleEdit(values,actions)}));
            else if (err.code == 409) {
                handleDuplicate(values, actions);
            }
        }
        actions.setSubmitting(false);
        editDialogBtnState?.setEditDialogOpen(false);
    }

    const handleDuplicate = (values, actions) => {
        setDuplicateHelper({ values, actions });
        setAllowDuplicateDialogOpen(true);
    }

    const handleDuplicateAction = () => {
        _handleEdit({ duplicate: true, ...duplicateHelper.values }, duplicateHelper.actions);
        setAllowDuplicateDialogOpen(false);
    }

    const addlActionCallback = (item) => {
        let updated_index = data.findIndex(obj => obj.id == item.id);
        let mdata = [...data];
        if (updated_index != -1) {
            mdata.splice(updated_index, 1, item);
        } else mdata.unshift(item);
        setData(mdata);
    }

    const getActions = (item) => {
        return (
            <Box sx={{ whiteSpace: 'nowrap' }}>
                {addl_actions ? addl_actions(item, addlActionCallback) : ''}
                {((typeof allowView == 'function' && allowView(item)) || (typeof allowView != 'function' && allowView)) &&
                    <IconButton
                        onClick={() => onViewClick(item)}
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                }
                {((typeof allowEdit == 'function' && allowEdit(item)) || (typeof allowEdit != 'function' && allowEdit)) &&
                    <IconButton
                        onClick={() => onEditClick(item)}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                }
                {((typeof allowDelete == 'function' && allowDelete(item)) || (typeof allowDelete != 'function' && allowDelete)) &&
                    <IconButton
                        onClick={() => onDeleteOneClick(item)}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                }
                {onChoose &&
                    <Button onClick={() => onChoose(item)} size="small" variant="outlined" >Choose</Button>
                }
            </Box>
        )
    }

    const get_item = (obj, col) => {
        if (!obj) return null;
        let res;
        let parts = col.split(".");
        if (obj instanceof Parse.Object) {
            if (parts[0] == 'id') res = obj.id;
            else res = obj.get(parts[0]);
        } else res = obj[parts[0]];
        if (parts.length == 1) return res;
        obj = parts[0];
        parts.shift();
        return get_item(res, parts.join("."));
    }

    const render_array = (arr) => {
        return (
            <>
                {arr.map(item => (
                    <>
                        {typeof item !== 'object' ? item : Object.keys(item).map(key => (
                            <p>
                                {key} : {item[key]}
                            </p>
                        ))}
                    </>
                ))}
            </>
        )
    }

    const format_item = (obj, col) => {
        if (col.render) return col.render(obj);
        let str = get_item(obj, col.name);
        if (str === null || str === '' || str === undefined || str === 'undefined') return '';
        switch (col.format || col.type) {
            case 'currency':
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0,
                });
                if (typeof str == 'string') str = parseInt(str);
                return formatter.format(str);
            case 'date':
                if (!str) return '';
                if (!(str instanceof Date)) str = parseISO(str);
                if (col.can_expire && str < new Date())
                    return <Chip color="error" label={date_format(str, 'MM/dd/yy')} />
                return date_format(str, 'MM/dd/yy');
            case 'datetime':
                if (!str) return '';
                if (!(str instanceof Date)) str = parseISO(str);
                if (col.can_expire && str < new Date())
                    return <Chip color="error" label={date_format(str, 'MM/dd/yy hh:ii:ss')} />
                return date_format(str, 'MM/dd/yy hh:ii:ss');
            case 'date_obj':
                return date_format(str, 'MM/dd/yy');
            case 'boolean':
            case 'checkbox':
                if (str) return <CheckIcon />;
                //else if (str===false) return <CheckBoxOutlineBlankIcon />
                return '';
            case 'string':
                if (col.startAdornmentText) str=col.startAdornmentText + str;
                if (col.maxLength && str.length>col.maxLength) return str.slice(0,col.maxLength)+'...';
                return str;
            case 'number':
                return str;
            case 'Pointer':
            case 'PointerChoice':
                if (!str) return '';
                if (col.field) 
                    if (col.field==='id') return str.id;
                    else return str.get(col.field) || '';
                return (str.className || '') + ":" + str.id;
            case 'Relation': //don't use it in large datasets!!
                return new Promise((resolve, reject) => {
                    obj.relation(col.name)?.query().find().then(items => {
                        let result = [];
                        if (col.field) result = items.map(item => {
                            if (Array.isArray(col.field)) return col.field.map(f => item.get(f)).join(" ");
                            else return item.get(col.field)
                        });
                        else result = items.map(item => item.id);
                        resolve(<>
                            {result?.slice(0, (col.max_length || result.length)).map((val, key) => (
                                <Chip key={key} label={val} sx={{ m: 0.1 }} color="primary" variant="outlined" size="small" />
                            ))}
                            {col.max_length && result.length > col.max_length &&
                                <Chip key={'more'} label={'+' + (result.length - col.max_length)} sx={{ m: 0.1, fontWeight: 'bold' }} color="primary" variant="outlined" size="small" />
                            }
                        </>)
                    }).catch(err => {
                        resolve('');
                    })
                });
            case 'object':
            case 'unknow':
                if (col.maxLength  && str.length>col.maxLength ) return JSON.stringify(str).slice(0,col.maxLength)+'...';
                return JSON.stringify(str);
            case 'preview':
                if (Array.isArray(str) && str[0]) str = str[0];
                if (typeof str?.url === 'function') return (
                    <Box sx={{
                        height: 30,
                        width: 60,
                        overflow: 'hidden'
                    }}>
                        <Box
                            component="img"
                            sx={{
                                width: 60
                            }}
                            alt=""
                            src={str.url()}
                        />
                    </Box>
                )
                return '';
            case 'chip':
                return <Chip label={str} color={col.colors? col.colors[str]: undefined } />
            case 'array':
                return render_array(str);
            case 'chips':
                return str?.map((label, key) => (
                    <Chip size="small" key={key} label={label} sx={{ margin: '2px' }}></Chip>
                )
                );
            case 'email':
                return <a href={'mailto:' + str}>{str}</a>
            case 'viewlink':
                if (((typeof allowView == 'function' && allowView(obj)) || (typeof allowView != 'function' && allowView)))
                    return (<Link onClick={() => onViewClick(obj)} style={{ cursor: 'pointer' }}>{str}</Link>);
                else
                    return str;
            default:
                if (col.startAdornmentText) str=col.startAdornmentText + str;
                if (col.maxLength && str.length>col.maxLength) return str.slice(0,col.maxLength)+'...';
                return str;
        }
    }

    const _setCustomFilters = (fltrs) => {
        if (!modal) {
            searchParams.set("filters", JSON.stringify(fltrs));
            setSearchParams(searchParams);
        }
        setCustomFilters(fltrs);
    }

    const deleteCustomFilter = (key) => {
        let f = { ...customFilters }
        delete f[key];
        setCustomFilters(f);
    }

    const renderRow = (row,columns,n,disable_selection=false,_getActions) => {
        const isSelected = selected.includes(row.id);
        return (
            <Fragment key={'row_' + n}>
                <TableRow >
                    {allowSelection && !disable_selection &&
                        <TableCell key={'row_checkbox'} padding="checkbox">
                            <Checkbox
                                checked={isSelected}
                                color="primary"
                                onChange={(event) => handleSelectOne(event, row.id)}
                                value={isSelected}
                            />
                        </TableCell>
                    }
                    {columns?.map((col, i) => {
                        if (col.hide || col.show == false || col.list===false) return null;
                        let val = format_item(row, col);
                        return (
                            <TableCell key={i} align={col.align}>
                                {(typeof val === 'object' && typeof val.then === 'function') ?
                                    <PromiseRenderer promise={val} />
                                    :
                                    <>{val}</>
                                }
                            </TableCell>
                        )
                    })}
                    <TableCell key={'row_actions'} align="right">
                        {_getActions?_getActions(row):getActions(row)}
                    </TableCell>
                </TableRow>
                {nested_rows && Object.keys(nested_rows)?.map(i => (
                    <NestedTable 
                        key={'nested_' + i} 
                        colspan={cols.length + 2} 
                        table={nested_rows[i]} 
                        renderRow={renderRow}
                        value={row.get(i)} />
                ))}
            </Fragment>
        )
    }

    return (
        <>
            <Scrollbar style={{ overflowX: 'scroll' }}>
                <Box sx={{ minWidth: 700 }}></Box>
                <>
                    <Box display="flex" justifyContent="space-between" >
                        <Box>
                            {allowSearch &&
                                < TextField
                                    ref={searchInputRef}
                                    variant="standard"
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>,
                                        endAdornment: search ? <InputAdornment position="end" color="info" sx={{ cursor: "pointer", opacity: "0.5" }} onClick={clearSearch}><CloseIcon size="small" /> </InputAdornment> : ''
                                    }}
                                    sx={{ pr: 4 }}
                                />
                            }

                            {Object.keys(customFilters).map(key => {
                                let conds = { "equalTo": "==", "notEqualTo": "!=", "containedIn": "==", "lessThan": "<", "greaterThan": ">" };
                                let cnd = (typeof customFilters[key] !== 'object') ? '==' : Object.keys(customFilters[key])[0];
                                let label = key + " " + (conds[cnd] || cnd) + " " + (typeof customFilters[key] !== 'object' ? customFilters[key] : ((typeof customFilters[key][cnd] !== 'object') ? customFilters[key][cnd] : customFilters[key][cnd].join(", ")));
                                return <Chip sx={{ m: 1 }} onDelete={() => deleteCustomFilter(key)} color="primary" key={key} label={label} />
                            })}

                        </Box>
                        {allowAdd && !editDialogBtnState &&
                            <Button size='small' variant="outlined" startIcon={<AddIcon />} onClick={() => onEditClick(null)}>
                                {addBtnText || 'add Item'}
                            </Button>
                        }
                    </Box>
                    {(!cols || cols.length === 0) &&
                        <>
                            {isLoading ?
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </Box>
                                :
                                <>{not_found_message}</>
                            }
                        </>
                    }
                    {cols && cols.length > 0 &&
                        <Table>
                            {showHeaders &&
                                <TableHead>
                                    <TableRow>
                                        {allowSelection &&
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={selectedAll}
                                                    color="primary"
                                                    indeterminate={selectedSome}
                                                    onChange={handleSelectAll}
                                                />
                                            </TableCell>
                                        }
                                        {selected.length === 0 && cols?.map((col, i) => {
                                            if (col.hide || col.show == false || col.list===false) return null;
                                            return (
                                                <TableCell key={i} align={col.labelAlign} sortDirection={orderBy === col.name ? order : false} >
                                                    {col.sort===false? 
                                                        <>{col.label}</>
                                                        :
                                                        <TableSortLabel
                                                        active={orderBy === col.name}
                                                        direction={orderBy === col.name ? order : 'desc'}
                                                        onClick={() => handleSort(col.name)}
                                                    >
                                                        {col.label}
                                                    </TableSortLabel>
                                                    }
                                                </TableCell>
                                            )
                                        })}
                                        {selected.length > 0 &&
                                            <TableCell colSpan={cols ? cols.filter(col => !col.hide).length : 1}>
                                                <Button size='small' variant="outlined" startIcon={<DeleteIcon />} onClick={onDeleteManyClick}>
                                                    Delete {selected.length} item{selected.length > 1 ? 's' : ''}
                                                </Button>
                                            </TableCell>
                                        }
                                        <TableCell align="right">
                                            {(!isLoading && data?.length > 0 && !prefetched_data) &&
                                                <IconButton onClick={() => { loadData(model); }} size="small" >
                                                    <CachedIcon size="small" />
                                                </IconButton>
                                            }
                                            {available_filters &&
                                                <IconButton onClick={() => setFilterDialogOpen(true)} size="small">
                                                    <FilterListIcon size="small" />
                                                </IconButton>
                                            }
                                            {allowChangeHeaders &&
                                                <ColumnSettingsPopup
                                                    model={model}
                                                    cols={cols}
                                                    setCols={setCols}
                                                    onVisibilityChange={toggleColumnVisibility}
                                                    addl_items={addl_menu_items}
                                                    addl_form_values={addl_form_values}
                                                    getDataForExport={getDataForExport}
                                                    allowBulkImport={allowBulkImport}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            }
                            <TableBody>
                                {!isLoading && (!data || data.length === 0) &&
                                    <TableRow>
                                        <TableCell colSpan={cols ? cols.filter(col => !col.hide).length + 2 : 1}>
                                            {not_found_message}
                                        </TableCell>
                                    </TableRow>
                                }
                                {isLoading && cols && cols.length > 0 && Array.from({ length: limit }, (_, n) => {
                                    return (
                                        <TableRow key={'row_' + n}>
                                            {allowSelection &&
                                                <TableCell padding="checkbox">
                                                </TableCell>
                                            }
                                            {cols?.map((col, i) => {
                                                if (col.hide || col.show===false || col.list===false) return null;
                                                return (
                                                    <TableCell key={i}>
                                                        <Skeleton></Skeleton>
                                                    </TableCell>
                                                )
                                            })}
                                            <TableCell >
                                                <Skeleton></Skeleton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                                {!isLoading && data && data?.map((row, n) => {
                                    return renderRow(row,cols,n);
                                    /*const isSelected = selected.includes(row.id);
                                    return (
                                        <>
                                            <TableRow key={'row_' + n}>
                                                {allowSelection &&
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isSelected}
                                                            color="primary"
                                                            onChange={(event) => handleSelectOne(event, row.id)}
                                                            value={isSelected}
                                                        />
                                                    </TableCell>
                                                }
                                                {cols?.map((col, i) => {
                                                    if (col.hide || col.show == false) return null;
                                                    let val = format_item(row, col);
                                                    return (
                                                        <TableCell key={i} align={col.align}>
                                                            {(typeof val === 'object' && typeof val.then === 'function') ?
                                                                <PromiseRenderer promise={val} />
                                                                :
                                                                <>{val}</>
                                                            }
                                                        </TableCell>
                                                    )
                                                })}
                                                <TableCell align="right">
                                                    {getActions(row)}
                                                </TableCell>
                                            </TableRow>
                                            {nested_rows && Object.keys(nested_rows)?.map(i => (
                                                <NestedTable key={'nested_' + i} colspan={cols.length + 2} table={nested_rows[i]} value={row.get(i)} />
                                            ))}
                                        </>
                                    )*/
                                })}
                            </TableBody>
                        </Table>
                    }
                </>
            </Scrollbar>
            {!isLoading && total > 0 && cols && cols.length > 0 && !prefetched_data &&
                <TablePagination
                    component="div"
                    count={total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page - 1}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            }
            <ConfirmDialog
                isOpen={isDeleteDialogOpen}
                setOpen={setDeleteDialogOpen}
                content={'Are you sure you want to delete item ' + (dialogData ? dialogData.id : '') + '?'}
                onConfirm={handleDelete}
            />
            <DialogForm
                title={addBtnText || (dialogData ? ('Edit item ' + dialogData.id) : 'New item')}
                fields={((typeof form_fields==='function')? form_fields(dialogData):form_fields) || cols.filter(col => ((col.edit !== false) || (col.add && !dialogData?.id)))}
                addl_values={addl_form_values}
                addl_actions={form_addl_actions}
                open={isEditDialogOpen}
                setOpen={setEditDialogOpen}
                data={dialogData}
                onSubmit={_handleEdit}
                maxWidth={dialogMaxWidth}
                hideSubmitBtn={hideSubmitFormBtn}
                hideBtns={hideFormBtns}
                onCancel={editDialogBtnState ? () => { editDialogBtnState.setEditDialogOpen(false) } : null}
            />
            <ViewItemDialog
                model={model}
                title={dialogData ? (model + ' #' + dialogData.id) : 'View'}
                fields={cols}
                data={dialogData}
                open={isViewDialogOpen}
                setOpen={setViewDialogOpen}
            />
            <FilterDialog
                isOpen={isFilterDialogOpen}
                setOpen={setFilterDialogOpen}
                available_filters={available_filters}
                customFilters={customFilters}
                setCustomFilters={_setCustomFilters}
            />
            <ConfirmDialog
                isOpen={isAllowDuplicateDialogOpen}
                setOpen={setAllowDuplicateDialogOpen}
                content={duplicateDialogText || 'The record already exists. Do you want to create another one?'}
                onConfirm={handleDuplicateAction}
            />
        </>
    )
}

export default PaginatedTable;