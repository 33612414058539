import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authAPI } from '../services/api/AuthAPI';


const initialState = {
  isAuthenticated: false,
  isVerified: false,
  isInitialized: false,
  user: null,
  currentOrganization: null,
  impersonated: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, currentOrganization, impersonated } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isVerified: user?.get("emailVerified") || false,
      isInitialized: true,
      user,
      currentOrganization,
      impersonated
    };
  },
  GET_USER_DATA: (state, action) => {
    const { user, currentOrganization } = action.payload;
    return {
      ...state,
      user,
      isVerified: user?.get("emailVerified") || false,
      currentOrganization
    };
  },
  GHANGE_ORGANIZATION: (state, action) => {
    const { currentOrganization } = action.payload;
    return {
      ...state,
      currentOrganization
    };
  },
  LOGIN: (state, action) => {
    const { user, currentOrganization } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isVerified: user?.get("emailVerified") || false,
      user,
      currentOrganization
    };
  },
  REGISTER: (state, action) => {
    const { user, currentOrganization } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isVerified: user?.get("emailVerified") || false,
      user,
      currentOrganization
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isVerified: false,
    user: null,
    currentOrganization: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getUserData: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await authAPI.getUser();
        const currentOrganization = user?.organizations[localStorage.getItem("currentOrganizationIndex") || 0] || user?.organizations[0] || null;
        //console.log("Initializing", user);
        if (user) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              currentOrganization,
              impersonated: localStorage.getItem("main_token") ? true : false
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              currentOrganization: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            currentOrganization: null
          }
        });
      }
    };

    initialize();
  }, []);

  const getUserData = async () => {
    const user = await authAPI.getUser();
    const currentOrganization = user?.organizations[localStorage.getItem("currentOrganizationIndex") || 0] || user?.organizations[0];
    //console.log("org:",currentOrganization, "of",user.organizations);
    dispatch({
      type: 'GET_USER_DATA',
      payload: {
        user,
        currentOrganization
      }
    });
  };

  const becomeUser = async (userId) => {
    localStorage.setItem("main_token", state.user.getSessionToken());
    console.log("main token", state.user.getSessionToken());
    try {
      await authAPI.becomeUser(userId);
    } catch (err) {
      localStorage.removeItem("main_token");
      throw (err);
    }
    const user = await authAPI.getUser();
    const currentOrganization = user?.organizations[0];
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user,
        currentOrganization,
        impersonated: true
      }
    });
  };

  const logoutImpersonatedUser = async () => {
    if (!localStorage.getItem("main_token")) throw new Error("Token not found");
    try {
      await authAPI.logoutImpersonatedUser(localStorage.getItem("main_token"));
      localStorage.removeItem("main_token");
    } catch (err) {
      localStorage.removeItem("main_token");
      throw (err);
    }
    const user = await authAPI.getUser();
    const currentOrganization = user.organizations[localStorage.getItem("currentOrganizationIndex") || 0] || user.organizations[0];
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user,
        currentOrganization,
        impersonated: false
      }
    });
  };


  const login = async (values) => {
    const user = await authAPI.login({ username: values.email, password: values.password });
    const currentOrganization = user.organizations[localStorage.getItem("currentOrganizationIndex") || 0] || user.organizations[0];
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        currentOrganization
      }
    });
  };

  const loginWithToken = async (token) => {
    const user = await authAPI.loginWithToken( token );
    const currentOrganization = user.organizations[localStorage.getItem("currentOrganizationIndex") || 0] || user.organizations[0];
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        currentOrganization
      }
    });
  };

  const logout = async () => {
    await authAPI.logout();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const changeOrganization = async (index) => {
    dispatch({
      type: 'GHANGE_ORGANIZATION',
      payload: {
        currentOrganization: state.user.organizations[index]
      }
    });
  };

  const register = async (values) => {
    delete values['passwordConfirmation'];
    delete values['submit'];
    values['username'] = values.email;
    let user;
    if (state.user) {
      await authAPI.finishSignUp(values);
      await login({email:values.email,password:values.password});
    } else {
      user = await authAPI.signup(values);
      const currentOrganization = user.organizations[localStorage.getItem("currentOrganizationIndex") || 0];
      dispatch({
        type: 'REGISTER',
        payload: {
          user,
          currentOrganization
        }
      });
    }
  };

  const hasAnyRole = (roles) => {
    //console.log("looking for roles",roles," in ",state.user?.roles);
    let role = state.user?.roles.find(role => roles.includes(role.get("name").replace(/__(.*)/i, "")));
    //console.log("found",role)
    return role ? true : false;

  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        loginWithToken,
        logout,
        register,
        getUserData,
        changeOrganization,
        hasAnyRole,
        becomeUser,
        logoutImpersonatedUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
