
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import EnchantedForm from './EnchantedForm';
import CloseIcon from '@mui/icons-material/Close';

const DialogForm = (props) => {
    const { title, content_text = null, maxWidth="sm",fields, data, open, setOpen, addl_actions, onSubmit,onCancel,hideBtns, hideSubmitBtn, addl_values={} } = props;


    const handleCancel =() =>{
        setOpen(false);
        if (onCancel) onCancel();
    }

    return (
        <Dialog
            open={open}
            style={{ minWidth: '320px' }}
            onClose={handleCancel}
            maxWidth={maxWidth}
        >
            <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={handleCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                ><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent>
                {content_text &&
                    <DialogContentText>
                        {content_text}
                    </DialogContentText>
                }
                <EnchantedForm 
                    fields={fields} 
                    data={data} 
                    addl_actions={addl_actions} 
                    addl_values={addl_values} 
                    onSubmit={onSubmit} 
                    hideBtns={hideBtns}
                    hideSubmitBtn={hideSubmitBtn}
                    onCancel={handleCancel} />
            </DialogContent>

        </Dialog>
    );
}
export default DialogForm;