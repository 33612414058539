import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import RegisterJWT from "./RegisterJWT";
import useAuth from "../../hooks/useAuth";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import SellerTypeDialog from "./SellerTypeDialog";

const LimitedUserPopup = () => {
    const { user,logout } = useAuth();
    const [extra, setExtras] = useState({});
    const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
    const [sellerTypeDialogOpen, setSellerTypeDialogOpen] = useState(false);

    const setSellerType = (values) => {
        setExtras(values.data);
        setRegisterDialogOpen(true);
        setSellerTypeDialogOpen(false);
    }

    const handleLogout=async ()=>{
        await logout();
    }

    return (
        <>
            <Dialog open={true}>
                <DialogTitle>Match approved</DialogTitle>
                <DialogContent>
                    <Alert severity="success">
                        Your listing was successfully submitted to one of nxtCRE’s 1031 exchange buyer.  Here at nxtCRE we call 1031 exchange buyers, Must-Buy Investors. <br />
                        In order to communicate with the Must-Buy Investor and to see whether they accepted your offer, you must set up an account.
                    </Alert>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between", p: 3 }}>
                    <Link
                        color="secondary"
                        onClick={handleLogout}
                        component="button"
                        variant="body2"
                        id="logout_link"
                    >
                        Logout
                    </Link>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={() => setSellerTypeDialogOpen(true)}
                        id="setup_account_btn"
                    >Set up account</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={registerDialogOpen} onClose={() => { setRegisterDialogOpen(false); }}>
                <DialogTitle>
                    Registration
                    <IconButton
                        aria-label="close"
                        onClick={() => { setRegisterDialogOpen(false); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <RegisterJWT
                        listing_user_info={{ extra: { email: user?.get("username"), first_name: user?.get("first_name"), last_name: user?.get("last_name") } }}
                        extra={extra}
                        show_fee_schedule_checkbox={true}
                    />
                </DialogContent>
            </Dialog>
            <SellerTypeDialog
                isOpen={sellerTypeDialogOpen}
                setOpen={setSellerTypeDialogOpen}
                onConfirm={setSellerType}
                hide_sign_in={true}
            />
        </>
    )
}

export default LimitedUserPopup;