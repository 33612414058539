import { Box } from '@mui/material';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
//import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
const PDFUrlViewer = (props) => {
    const { url } = props;
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }



    return (
                <Document file={url} onLoadSuccess={onDocumentLoadSuccess} >
                    {Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                canvasBackground="#fcfcfc"
                                renderTextLayer={false} /** Text is rendered but not selectable. If select is neccessary set to true and import TextLayer.css */
                            />
                        ),
                        )}</Document>

    )
}

export default PDFUrlViewer;