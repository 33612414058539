import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from "@mui/material";
import PromiseRenderer from "../../services/helpers/PromiseRenderer";
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewItemDialog from "../ViewItem/ViewItemDialog";
import { useState } from "react";

const useStyles = makeStyles({
    nested_table: {
        background: '#f0f0f0',
        padding: 0,
        "& td , th": {
            fontSize: '0.7rem',
            paddingTop: 0,
            paddingBottom: 0
        },
        "& .MuiChip-root": {
            fontSize: '0.7rem !important',
            height: '1.3rem !important'
        },
        "& .MuiSvgIcon-root": {
            fontSize: '0.9rem !important',
        }
    }

});

const NestedTable = (props) => {
    const { colspan, table, value, renderRow } = props;
    const [viewItemDialogOpen, setViewItemDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState();
    const classes = useStyles();

    const onViewClick = (item) => {
        setDialogData({ model: item.className, data: item });
        setViewItemDialogOpen(true);
    }

    const getActions = (item) => (
        <IconButton
            onClick={() => onViewClick(item)}
        >
            <VisibilityIcon fontSize="small" />
        </IconButton>
    )

    const _renderRow = async (item, n) => {
        if (item.className && !item.createdAt) {
            await item.fetch();
        }
        return renderRow(item, table.columns, n, true, getActions);
    }


    if (!value || value.length == 0) return null;

    return <TableRow>
        <TableCell key={'row_0'}></TableCell>
        <TableCell key={'row_1'} colSpan={colspan - 1} sx={{ padding: 0 }}>
            <>
                <Table className={classes.nested_table}>
                    <TableHead>
                        <TableRow>
                            {table?.columns?.map((col, i) => (
                                <TableCell key={'nested_th_' + i}>{col.label}</TableCell>
                            ))}
                            <TableCell key={'nested_th_actions'}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value?.map((row, n) => (
                            <PromiseRenderer
                                key={'promise_' + n}
                                promise={_renderRow(row, n)}
                                fallback={<TableRow><TableCell colSpan={table?.columns?.length + 1}><Skeleton /></TableCell></TableRow>}
                            />)
                        )}
                    </TableBody>
                </Table>
                <ViewItemDialog
                    open={viewItemDialogOpen}
                    setOpen={setViewItemDialogOpen}
                    model={dialogData?.model}
                    title={dialogData?.model + "#" + dialogData?.data?.id}
                    data={dialogData?.data}
                />
            </>
        </TableCell>
    </TableRow>
}

export default NestedTable;