import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const OrganizationTypeGuard = ({ children,type }) => {
  const { currentOrganization } = useAuth();

  if (Array.isArray(type)) {
    if (!type.includes(currentOrganization?.get("type")))
     return <Navigate to="/dashboard" />;
  }  else if (currentOrganization?.get("type")!=type) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

OrganizationTypeGuard.propTypes = {
  children: PropTypes.node
};

export default OrganizationTypeGuard;
