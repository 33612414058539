import React from 'react';
import {FormGroup,FormControlLabel,Switch} from '@mui/material';
import { useFormikContext, Field } from 'formik';

export default function SwitchLabels(props) {
  const { name: fieldName,label,placement="start" } = props;
  const { setFieldValue } = useFormikContext();

  const handleChange = (value) => {
    setFieldValue(
      fieldName, value
    );
  };

  return (
    < Field name={fieldName} >
      {({
        field, // { name, value, onChange, onBlur }
      }) => (
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={field.value==='checked'?true:field.value}
                onChange={handleChange}
                color="primary"
                {...field}
              />
            }
            label={label}
            labelPlacement={placement}
          />
        </FormGroup>
      )}
    </Field>
  );
}