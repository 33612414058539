import { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Export2CsvMenuItem from './Export2CsvMenuItem';
import BulkImportDialog from './BulkImportDialog';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ColumnSettingsPopup = (props) => {
    const { model, cols, onVisibilityChange, addl_items,addl_form_values, getDataForExport,allowBulkImport } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {cols.map((col, i) => {
                    if (col.show === false || col.list===false) return '';
                    return (
                        <MenuItem onClick={() => onVisibilityChange(i)} key={i}>
                            <ListItemIcon>
                                {!col.hide &&
                                    <VisibilityIcon fontSize="small" />
                                }
                            </ListItemIcon>
                            <ListItemText>{col.label}</ListItemText>
                        </MenuItem>
                    )
                })}
                {addl_items &&
                    (typeof addl_items === 'function' ? addl_items() : addl_items).map(item => item)
                }
                {allowBulkImport &&
                    <Divider />
                }
                {allowBulkImport &&
                    <MenuItem  onClick={()=>setImportDialogOpen(true)}>
                        <ListItemIcon>
                            <FileUploadIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Bulk import (XLS, CSV)</ListItemText>
                    </MenuItem>
                }
                <Divider  />
                <Export2CsvMenuItem getDataForExport={getDataForExport} />
            </Menu>
            {allowBulkImport &&
                <BulkImportDialog open={importDialogOpen} setOpen={setImportDialogOpen} model={model} cols={cols} addl_form_values={addl_form_values} />
            }
        </>
    )
}
export default ColumnSettingsPopup;