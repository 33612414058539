import 'react-perfect-scrollbar/dist/css/styles.css';
//import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'react-gallery-carousel/dist/index.css';

import './wdyr'; 
import React from 'react';
//import ReactDOM from 'react-dom/client'; //for react18
import { render } from "react-dom";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
//import reportWebVitals from './reportWebVitals';

const root = document.getElementById("root");
//const root = ReactDOM.createRoot(document.getElementById('root'));//for react 18
/*root.render(
  <React.StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <SettingsProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SettingsProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </React.StrictMode>
); */
render (
  <React.StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <SettingsProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SettingsProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </React.StrictMode>
,root)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
