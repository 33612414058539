import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EnchantedForm from '../components/EnchantedForm/EnchantedForm';



const ConfirmDialog = (props) => {
  const { title = 'Please, confirm',
    content = 'Are you sure?',
    okButtonText = "Confirm",
    onConfirm,
    isOpen,
    setOpen,
    inputs,
    ...rest
  } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);


  //TODO potential 'Warning: Can't perform a React state update'
  useEffect(() => {
    if (inputs) {
      let initial_values={}
      inputs.forEach(item => {
        if (item.name && item.value) initial_values[item.name]=item.value;
      });
      setData(initial_values);
    } else
      setData(null);
  }, [isOpen]);

  const handleOk = async () => {
    setSubmitting(true);
    try {
      await onConfirm(data);
    } catch (err) { }
    setSubmitting(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Dialog open={isOpen} {...rest}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={() => { setOpen(false); }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        ><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <>
          {content}
          {inputs &&
            <EnchantedForm fields={inputs} hideBtns={true} setData={setData} />
          }
        </>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk} disabled={isSubmitting}>
          {okButtonText}
          {isSubmitting &&
            <CircularProgress
              size={12}
              sx={{ ml: 1 }}
            />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;

