



import { Button, Alert, AlertTitle } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { getItems } from '../../services/api/ModelAPI';
import { acceptInvitation } from '../../services/api/OrganizationAPI';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';

const UnassociatedQuestionnaires = (props) => {

    const { currentOrganization} = useAuth();

    const loadUnassociatedQuestionnaires = async () => {
        try {
            // const data = await getItems("invitation",10,1,null,{username:user.get('username'),used:false},'',[],["organization"]);
            //setInvitations(data.results);
        } catch (err) {
            console.error(err);
        }

    };

    useEffect(() => {
        loadUnassociatedQuestionnaires();
    }, []);

    return (
        <>
            <Alert severity="info" sx={{ m: 3 }}>
                <AlertTitle>
                    TODO: show unassociated questionnaires and allow to dismiss;
                </AlertTitle>
            </Alert>
        </>
    )
}
export default UnassociatedQuestionnaires;