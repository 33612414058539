import { Dialog, DialogContent, DialogTitle,IconButton } from '@mui/material';
import ItemDetails from './ItemDetails';
import CloseIcon from '@mui/icons-material/Close';

const ViewItemDialog = (props) => {
    const { title, fields, data, open, setOpen, onSubmit, model, maxWidth='xl' } = props;

    return (
        <Dialog
            open={open}
            style={{ minWidth: '320px' }}
            maxWidth={maxWidth}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                ><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent>
                <ItemDetails fields={fields} data={data} model={model} onSubmit={onSubmit} onCancel={()=>setOpen(false)} />
            </DialogContent>

        </Dialog>
    );
}
export default ViewItemDialog;