import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  customCard: {
    border: 'none',
    borderRadius: '10px',
    background: 'linear-gradient(136.2deg, #FFFFFF 16.4%, #C8D1C8 92.26%)',
    boxShadow: '0px 0px 50px 10px rgba(0, 0, 0, 0.2)',
    maxWidth: '900px'
  }
});

